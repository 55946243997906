import type { ActionTree } from 'vuex';
import {
  ADD_ERROR_NOTIFICATION,
} from '@/components/NotificationBar/store/mutation-types';
import {
  GET_USER_PROFILE_INFO,
} from './action-types';
import {
  SET_IS_LOADING,
  SET_USER_INFO,
  SET_IS_EXTERNAL_ACCOUNT_COMPLETED,
  SET_USER_LANG,
} from './mutation-types';
import UserService from '@/common/services/UserServices';
import { type Role, RoleType } from '@/common/interfaces/user/IUser';
import type {
  IntUserProfileState,
} from './index';

const actions: ActionTree<IntUserProfileState, {}> = {
  [GET_USER_PROFILE_INFO] ({ commit }) {
    return new Promise((resolve, reject) => {
      commit(SET_IS_LOADING, true);
      UserService.getUserInfo()
        .then(res => {
          const { status, data } = res;
          if (status === 200) {
            if (data.roleDtoList !== null) {
              data.roleDtoList = data.roleDtoList
                .filter((role: Role) => role.roleType === RoleType.CLIENT_ROLE)
                .sort((a: Role, b: Role) => a.roleName.localeCompare(b.roleName));
            }
            if (data.language !== null) {
              commit(SET_USER_LANG, data.language);
            }
            commit(SET_USER_INFO, data);
          }
          resolve('success');
        })
        .catch(error => {
          if (error.response instanceof Object && error.response.status === 404) {
            commit(SET_IS_EXTERNAL_ACCOUNT_COMPLETED, false);
            reject(new Error('External user account is not completed'));
          } else if (error.response instanceof Object && error.response.status === 406) {
            reject(new Error('Organization not found'));
          } else {
            commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
            reject(new Error('error'));
          }
        })
        .finally(() => {
          commit(SET_IS_LOADING, true);
        });
    });
  },
};

export default actions;
