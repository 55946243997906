/**
 * This service is use for notification
 */

import axios from 'axios';
import Util from '../util';
import type {
  IntNotificationServiceResponse,
} from '@/common/interfaces/notifications/INotification';
const notificationService = {
  /**
  * Get all notification
  * @returns Array of notification
  */
  getUserNotifications: (): Promise<{ status: number, data: IntNotificationServiceResponse[]}> => {
    return axios.get(Util.getRestApiUrl('notifications', 'notification'));
  },

  /**
   * Set the notification status to true
   * @param notificationId
   * @returns Promise
   */
  setReadNotification: (notificationId: number) => {
    return axios.put(Util.getRestApiUrl('notifications/read', 'notification'), null, {
      params: {
        id: notificationId,
      },
    });
  },
};

export default notificationService;
