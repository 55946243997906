<template>
  <div
    class="t-help-widget-ask-support t-flex t-flex-row t-flex-align-items-center"
  >
    <div class="t-help-widget-ask-support-icon t-flex t-flex-justify-content-center t-flex-align-items-center">
      <scale-icon-communication-chat
        :size="12"
        fill="#ffffff"
      />
    </div>
    {{ $t('helpWidget.askSupport') }}
  </div>
</template>

<style scoped lang="scss">
@import '@/styles/utilities/variables';
@import '@/styles/variables/spacing';
@import '@/styles/global/utilities/mixins';
@import '@/styles/mixins/typography';

.t-help-widget-ask-support {
  @include tg-heading-5;
  box-shadow: var(--telekom-shadow-floating-standard);
  border-radius: var(--scl-radius-8);
  background: var(--telekom-color-ui-base);
  padding: $space-1 $space-2;
  gap: var(--scl-spacing-2);
  margin-right: 6px;
  position: relative;
  &-icon {
    background: var(--telekom-color-primary-standard);
    color: var(--telekom-color-text-and-icon-standard);
    border-radius: var(--telekom-radius-circle);
    width: $space-7;
    height: $space-7;
  }
  &::after {
    @include absolute-y-center;
    z-index: var(--scl-z-index-10);
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 6px;
    border-color: transparent transparent transparent #FFFFFF;
    right: -5px;
  }
}
</style>
