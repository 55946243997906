import type { GetterTree } from 'vuex';
import {
  GET_NOTIFICATIONS,
  GET_UNREAD_COUNT,
  IS_LOADING,
} from './getter-types';
import type {
  IntNotificationState,
} from './IntNotificationState';

export const getters: GetterTree<IntNotificationState, {}> = {
  [GET_NOTIFICATIONS]: state => state.notifications,
  [GET_UNREAD_COUNT]: state => state.notifications.filter(item => !item.isRead).length,
  [IS_LOADING]: state => state.isLoading,
};
