import type { GenericObject } from '../interfaces/IGeneric';
import { required, url } from '@vuelidate/validators';

export const filterOptions: GenericObject[] = [
  {
    text: 'verify.verifiableCredentials.status.all',
    value: 'all',
  },
  {
    text: 'verify.verifiableCredentials.status.valid',
    value: 'valid',
  },
  {
    text: 'verify.verifiableCredentials.status.expired',
    value: 'expired',
  },
  {
    text: 'verify.verifiableCredentials.status.revoked',
    value: 'revoked',
  },
];

export const filterTypeOptions: GenericObject[] = [
  {
    text: 'verify.verifiableCredentials.status.all',
    value: '',
  },
  {
    text: 'verify.verifiableCredentials.form.types.legalPerson',
    value: 'LEGAL_PERSON',
  },
  {
    text: 'verify.verifiableCredentials.form.types.serviceOffering',
    value: 'SERVICE_OFFERING',
  },
  {
    text: 'verify.verifiableCredentials.form.types.physicalResource',
    value: 'PHYSICAL_RESOURCE',
  },
  {
    text: 'verify.verifiableCredentials.form.types.softwareResource',
    value: 'SOFTWARE_RESOURCE',
  },
  {
    text: 'verify.verifiableCredentials.form.types.dataResource',
    value: 'DATA_RESOURCE',
  },
];

export const requestVCTypeOptions: GenericObject[] = [
  {
    text: 'verify.verifiableCredentials.form.types.legalPerson',
    value: 'LEGAL_PERSON',
  },
  {
    text: 'verify.verifiableCredentials.form.types.serviceOffering',
    value: 'SERVICE_OFFERING',
  },
  {
    text: 'verify.verifiableCredentials.form.types.resource',
    value: 'RESOURCE',
  },
];

export const requestTypeSOOptions: GenericObject[] = [
  {
    text: 'verify.serviceOfferingForm.requestTypesList.api',
    value: 'API',
  },
  {
    text: 'verify.serviceOfferingForm.requestTypesList.email',
    value: 'email',
  },
  {
    text: 'verify.serviceOfferingForm.requestTypesList.webform',
    value: 'webform',
  },
  {
    text: 'verify.serviceOfferingForm.requestTypesList.unregisteredLetter',
    value: 'unregisteredLetter',
  },
  {
    text: 'verify.serviceOfferingForm.requestTypesList.registeredLetter',
    value: 'registeredLetter',
  },
  {
    text: 'verify.serviceOfferingForm.requestTypesList.supportCenter',
    value: 'supportCenter',
  },
];

export const accessTypeSOOptions: GenericObject[] = [
  {
    text: 'verify.serviceOfferingForm.accessTypesList.digital',
    value: 'digital',
  },
  {
    text: 'verify.serviceOfferingForm.accessTypesList.physical',
    value: 'physical',
  },
];

export const resourceTypeOptions: GenericObject[] = [
  {
    text: 'verify.resourceForm.physicalResource',
    value: 'gx:PhysicalResource',
  },
  {
    text: 'verify.resourceForm.softwareResource',
    value: 'gx:SoftwareResource',
  },
  {
    text: 'verify.resourceForm.dataResource',
    value: 'gx:DataResource',
  },
];

export const VCtypes: GenericObject = {
  legalPersonVCs: 'verify.verifiableCredentials.types.legalPerson',
  serviceOfferingVCs: 'verify.verifiableCredentials.types.serviceOffering',
  physicalResourceVCs: 'verify.verifiableCredentials.types.physicalResource',
  softwareResourceVCs: 'verify.verifiableCredentials.types.softwareResource',
  dataResourceVCs: 'verify.verifiableCredentials.types.dataResource',
  MembershipVCs: 'verify.verifiableCredentials.types.membership',
};

export const pageSize = 20;
export const apiLimitUsageThreshold = 90;
export const digitalIdProduct = 'DIGITAL_ID';
export const digitalIdMyCertificatesProducts = 'CERTIFICATE';
export const digitalIdAPIUsageProduct = 'DIGITAL_ID_API_LIMIT';

export enum enumCredentialStatus {
  VALID = 'valid',
  PENDING = 'pending',
  EXPIRED = 'expired',
  REVOKED = 'revoked',
}

export enum enumRequestType {
  legalPerson = 'LEGAL_PERSON',
  serviceOffering = 'SERVICE_OFFERING',
  resource = 'RESOURCE',
  physicalResource = 'PHYSICAL_RESOURCE',
  softwareResource = 'SOFTWARE_RESOURCE',
  dataResource = 'DATA_RESOURCE',
}

export enum enumResourceType {
  physical = 'gx:PhysicalResource',
  software = 'gx:SoftwareResource',
  data = 'gx:DataResource',
}

export const partnerValidationLink = 'https://docs.dih.telekom.com/en/digitalid/business-partner-validation';
export const typeSelectHelpLink = 'https://docs.dih.telekom.com/en/digitalid';
export const gaiaXTermsLink = 'https://gx-registry.gxdch.dih.telekom.com/v1/api/termsAndConditions';
// CSV file Format Value
export const CSVFormat = 'LEGAL_NAME;COUNTRY;REGION;STREET;CITY;POSTAL_CODE;CRN;VAT_ID;EORI;LEI;DID';

export const defaultRequestType = {
  requestType: '',
};

export const requestTypeValidationRules = {
  requestType: {
    required,
  },
};

export const defaultLegalPersonForm = {
  legalName: '',
  country: '',
  region: '',
  street: '',
  city: '',
  postalCode: '',
  vatId: '',
  eori: '',
  lei: '',
  local: '',
  did: '',
  consentTC1: false,
  consentTC2: false,
  consentTC3: false,
};

export const legalPersonValidationRules = {
  legalName: {
    required,
  },
  country: {
    required,
  },
  region: {
    required,
  },
  street: {
    required,
  },
  city: {
    required,
  },
  postalCode: {
    required,
  },
  vatId: {
    required,
  },
  eori: {
    required,
  },
  lei: {
    required,
  },
  local: {
    required,
  },
  did: {
    required,
  },
  consentTC1: {
    required,
  },
  consentTC2: {
    required,
  },
  consentTC3: {
    required,
  },
};

export const defaultServiceOfferingForm = {
  nameSO: '',
  providedBySO: '',
  resourcesSD: [''],
  urlSO: '',
  hashSO: '',
  policySO: '',
  requestTypeSO: '',
  accessTypeSO: '',
  formatTypeSO: '',
  did: '',
  consentTC1: false,
  consentTC2: false,
  consentTC3: false,
};

export const serviceOfferingValidationRules = {
  nameSO: {
    required,
  },
  providedBySO: {
    required,
  },
  resourcesSD: [
    {
      required,
    },
  ],
  urlSO: {
    required,
    url,
  },
  hashSO: {
    required,
  },
  policySO: {
    required,
  },
  requestTypeSO: {
    required,
  },
  accessTypeSO: {
    required,
  },
  formatTypeSO: {
    required,
  },
  did: {
    required,
  },
  consentTC1: {
    required,
  },
  consentTC2: {
    required,
  },
  consentTC3: {
    required,
  },
};

export const defaultConsentForm = {
  consentTC1: false,
  consentTC2: false,
  consentTC3: false,
  consentTC4: false,
};

export const consentValidationRules = {
  consentTC1: {
    required,
  },
  consentTC2: {
    required,
  },
  consentTC3: {
    required,
  },
  consentTC4: {
    required,
  },
};

export const defaultDidForm = {
  did: '',
};

export const didValidationRules = {
  did: {
    required,
  },
};

export const defaultResourceForm = {
  resourceType: '',
  resourceName: '',
  resourceDescription: '',
  resourceMaintainedBy: '',
  resourceOwnedBy: '',
  resourceManufacturedBy: '',
  resourceCountry: '',
  resourceCity: '',
  resourcePostCode: '',
  resourceStreet: '',
  resourceCopyrightOwnerBy: '',
  resourceLicense: '',
  resourcePolicy: '',
  resourceProducedBy: '',
  resourceExposedThrough: '',
  did: '',
  consentTC1: false,
  consentTC2: false,
  consentTC3: false,
  consentTC4: false,
};

export const resourceValidationRules = {
  resourceType: {
    required,
  },
  resourceName: {
    required,
  },
  resourceDescription: {
    required,
  },
  resourceMaintainedBy: {
    required,
  },
  resourceOwnedBy: {
    required,
  },
  resourceManufacturedBy: {
    required,
  },
  resourceCountry: {
    required,
  },
  resourceCity: {
    required,
  },
  resourcePostCode: {
    required,
  },
  resourceStreet: {
    required,
  },
  resourceCopyrightOwnerBy: {
    required,
  },
  resourceLicense: {
    required,
  },
  resourcePolicy: '',
  resourceProducedBy: {
    required,
  },
  resourceExposedThrough: {
    required,
  },
  did: {
    required,
  },
  consentTC1: {
    required,
  },
  consentTC2: {
    required,
  },
  consentTC3: {
    required,
  },
  consentTC4: {
    required,
  },
};

export const integrationValidationRules = {
  validationClientId: {
  },
  complianceClientId: {
  },
  validationClientSecret: {
  },
  complianceClientSecret: {
  },
  validationTokenUrl: {
    url
  },
  complianceTokenUrl: {
    url
  },
  legalPersonCallbackUrl: {
    url
  },
  serviceOfferingCallbackUrl: {
    url
  },
}

export const clientIntegrationValidationRules = {
  clientId: {
    required
  }
}
