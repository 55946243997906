<template>
  <TLoadingBar
    v-show="isLoading"
    class="loading-bar"
    :is-loading="isLoading || !isPageLoaded"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { LOADING_STATE } from '@/store/modules/loading/getter-types';
import { IS_AUTHORIZED } from '@/store/modules/auth/getter-types';
import TLoadingBar from '@/components/global/TLoadingBar/TLoadingBar.vue';

export default defineComponent({
  components: {
    TLoadingBar,
  },
  data () {
    return {
      isPageLoaded: false,
    };
  },
  computed: {
    isLoading (): boolean {
      return this.$store.getters[LOADING_STATE];
    },
    isAuthenticated (): boolean {
      return this.$store.getters[IS_AUTHORIZED];
    },
  },
  created () {
    window.addEventListener('load', () => {
      this.isPageLoaded = true;
    });
  },
});

</script>

<style lang="scss" scoped>
  @import '@/styles/utilities/variables';

  .loading-bar {
    z-index: 101;
    background-color: $white;

    &.is-generic {
      .loading-overlay-bar {
        background-color: $gray-220;
      }
    }

    &.is-authenticated {
      z-index: 10;
    }
  }
</style>
