import axios from 'axios';
import type { Module } from 'vuex';
import {
  CURRENT_REALM_NAME,
  IS_AUTHORIZED,
  KEYCLOAK_REALM,
  TOKEN,
  USER_KEYCLOAK_ID,
  USERNAME,
} from './getter-types';
import actions from './actions';
import {
  SET_AUTHORIZED,
  SET_UNAUTHORIZED,
  SET_PERMISSIONS,
  UPDATE_KEYCLOAK_REALM,
  UPDATE_SESSION_EXPIRY_TIME,
  UPDATE_USERNAME,
} from './mutation-types';
import AuthService from '@/common/services/AuthServices';
import type { IKeycloakRealm, IToken } from '@/common/interfaces/auth/IToken';
import type { Permission } from '@/common/enums/Permission';
import { isNullish } from '@/utils/check-runtime-types';

export interface IAuthState {
  username: string
  registrationType: undefined
  token: IToken | null
  sessionExpiryTime: number
  keycloakRealm: IKeycloakRealm
  permissions: Permission[]
}

const authState: Module<IAuthState, {}> = {
  state: {
    keycloakRealm: {
      current: '',
      lastVisited: '',
      loginInProgress: false,
      switchRealm: false,
    },
    username: '',
    registrationType: undefined,
    token: null,
    sessionExpiryTime: AuthService.getNewSessionExpiryTime(),
    permissions: [],
  },
  actions,
  mutations: {
    [SET_AUTHORIZED] (state, value) {
      state.token = value;
      // Add global auth header for every REST request
      // Added nullish checks to resolve typescript errors related to undefined | string
      if (!isNullish(state.token) && !isNullish(state.token.accessToken)) {
        axios.defaults.headers.common.Authorization = `bearer ${state.token.accessToken}`;
      }
    },
    [SET_UNAUTHORIZED] (state) {
      state.token = null;
    },
    [SET_PERMISSIONS] (state, value) {
      state.permissions = value;
    },
    [UPDATE_USERNAME] (state, value) {
      state.username = value;
    },
    [UPDATE_SESSION_EXPIRY_TIME] (state) {
      state.sessionExpiryTime = AuthService.getNewSessionExpiryTime();
    },
    [UPDATE_KEYCLOAK_REALM] (state, keycloakRealm: IKeycloakRealm) {
      state.keycloakRealm = {
        current: keycloakRealm.current,
        lastVisited: keycloakRealm.lastVisited,
        loginInProgress: keycloakRealm.loginInProgress,
        switchRealm: keycloakRealm.switchRealm,
      };
    },
  },
  getters: {
    [KEYCLOAK_REALM]: state => state.keycloakRealm,
    [CURRENT_REALM_NAME]: state => state.keycloakRealm.current,
    [IS_AUTHORIZED]: state => state.token !== null,
    [TOKEN]: state => state.token,
    [USERNAME]: state => state.username,
    [USER_KEYCLOAK_ID]: state => state.token!.keycloakId,
  },
};

export default authState;
