/**
 * This service is use for dataspaces call
 */

import axios, { type AxiosResponse } from 'axios';
import type { IntApplicationManagement, IntConnectorResult, IntConnectorTally, IntDataspaceMember, IntInvitedParticipant, IntParticipantRequest } from '../interfaces/build/IntBuildDataspaces';
import Util from '../util';
import { InviteInput } from '@/modules/build/types/ParticipantInvite';

const buildService = {
  /**
   * Get user's owned dataspace
   */
  myOwnedDataspaces: () => {
    return axios.get(Util.getRestApiUrl('lila/dataspaces', 'dataspace'));
  },

  /**
   * Get particpant details
   */
  participantDetails: (dataspaceId: string) => {
    return axios.get(Util.getRestApiUrl(`lila/packageInstances/${dataspaceId}`, 'dataspace'));
  },

  /**
   * Get invited participant details
   */
  getInvitedParticipants: (dataspaceId: string): Promise<{ data: IntInvitedParticipant[], status: number }> => {
    return axios.get(Util.getRestApiUrl(`lila/inviteeList/${dataspaceId}`, 'dataspace'));
  },

  /**
   * Get incoming requests details
   */
  getIncomingRequests: (dataspaceId: string): Promise<{ data: IntParticipantRequest[], status: number }> => {
    return axios.get(Util.getRestApiUrl(`lila/requestConnectionToLiLaDs/${dataspaceId}`, 'dataspace'));
  },

  /**
   * Make a post call to approve or decline incoming request
   */
  updateApprovalStatusForIncomingRequest: (payload: {
    adminNotes: string
    requestStatus: string
  }, requestId: string): Promise<{ status: number }> => {
    return axios.post(Util.getRestApiUrl(`lila/requestConnectionToLiLaDs/act/${requestId}`, 'dataspace'), payload);
  },

  /**
   * Invite participant
   */
  inviteParticipant: (payload: {
    inviteeMailId: string
    dataspaceId: string
    organizationName: string
  }): Promise<{ data: string, status: number }> => {
    return axios.post(Util.getRestApiUrl('lila/inviteParticipant', 'dataspace'), payload);
  },

  /**
  * Get lila dataspace members
  */
  getDataSpaceMembers: (dataspaceId: string): Promise<{ data: IntDataspaceMember[], status: number }> => {
    return axios.get(Util.getRestApiUrl(`lila/members/${dataspaceId}`, 'dataspace'));
  },

  /**
  * Get connectors tally
  */
  getConnectorTally: (dataspaceId: string): Promise<{ data: IntConnectorTally, status: number }> => {
    return axios.get(Util.getRestApiUrl(`lila/connector-tally/${dataspaceId}`, 'dataspace'));
  },

  /**
  * Allocate connectors to Org
  */
  allocateConnectors: (payload: {
    dataspaceId: string
    organizationId?: string
    organizationName?: string
    requestConnectionToLiLaId?: string
    contactEmail?: string
    noOfConnectors: number
  }): Promise<{ status: number }> => {
    return axios.post(Util.getRestApiUrl('lila/deployConnectors', 'dataspace'), payload);
  },

  /**
   * Register new application
   */
  registerApplication: (payload: {
    dataspaceId: string
    ownerOrgId: string
    description: string
    dashboardUrl: string
    name: string
    logo?: string
  }): Promise<{ status: number }> => {
    return axios.post(Util.getRestApiUrl('lila/applications', 'dataspace'), payload);
  },

  /**
   * Get dataspace details
   */
  dataspaceDetails: (dataspaceId: string) => {
    return axios.get(Util.getRestApiUrl(`lila/dataspaceDetails/${dataspaceId}`, 'dataspace'));
  },

  /**
   * Make a put call to update dataspace details
   */
  updateDataspaceDetails: (payload: {
    description?: string
    website?: string
  }, dataspaceId: string): Promise<{ status: number }> => {
    return axios.put(Util.getRestApiUrl(`lila/${dataspaceId}`, 'dataspace'), payload);
  },

  /**
   * Get service overview details
   */
  getServiceDetails: (dataspaceId: string) => {
    return axios.get(Util.getRestApiUrl(`lila/getServicesOverview/${dataspaceId}`, 'dataspace'));
  },

  /**
   * Get application management details
   */
  async getApplicationDetails (dataspaceId: string): Promise<IntApplicationManagement[]> {
    try {
      const response: AxiosResponse = await axios.get(Util.getRestApiUrl(`lila/applications/${dataspaceId}`, 'dataspace'));
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch applications');
    }
  },

  /**
   * Toggle dataspace visibility
   */
  toggleDataspaceVisbility (dataspaceId: string): Promise<{ status: number }> {
    return axios.put(Util.getRestApiUrl(`lila/toggleDataspaceVisibility/${dataspaceId}`, 'dataspace'));
  },

  /**
   * Connector health test api
   */
  intiateConnectorTest (payload: string[]): Promise<{ status: number }> {
    return axios.post(Util.getRestApiUrl('lila/test/connectors', 'dataspace'), payload);
  },

  /**
   * Connector test history details
   */
  getConnectorResults (dataspaceId: string): Promise<{ data: IntConnectorResult[], status: number }> {
    return axios.get(Util.getRestApiUrl(`lila/test/connectors/results/${dataspaceId}`, 'dataspace'));
  },

  /**
   * Update application details
   */
  updateApplicationDetails (payload: IntApplicationManagement, applicationId: string): Promise<{ status: number }> {
    return axios.put(Util.getRestApiUrl(`lila/applications/${applicationId}`, 'dataspace'), payload);
  },

  /**
   * Delete application
   */
  deleteApplication (applicationId: string): Promise<{ status: number }> {
    return axios.delete(Util.getRestApiUrl(`lila/applications/delete/${applicationId}`, 'dataspace'));
  },

  /**
   * Top Up Connector
   */
  topUpConnectors (dataspaceId: string): Promise<{ status: number }> {
    return axios.post(Util.getRestApiUrl(`lila/top-up-connector/${dataspaceId}`, 'dataspace'));
  },

  /**
   * Delete invitation
   */
  deleteInvitation (inviteId: string): Promise<{ status: number }> {
    return axios.delete(Util.getRestApiUrl(`lila/deleteInvitation/${inviteId}`, 'dataspace'));
  },

  /**
   * Delete participant
   */
  deleteParticipant: (participantId: string, dataspaceId: string): Promise<{ status: number }> => {
    return axios.delete(Util.getRestApiUrl(`lila/${dataspaceId}/deletePackageInstance/${participantId}`, 'dataspace'));
  },

  resendInvitation (inviteId: string): Promise<{ status: number }> {
    return axios.post(Util.getRestApiUrl(`lila/re-inviteParticipant/${inviteId}`, 'dataspace'));
  },

  editParticipant: (dataspaceId: string, payload: {
    participantOrgId: String
    isDataspaceOperator: boolean
    isAnonymous: boolean
  }): Promise<{ status: number }> => {
    return axios.put(Util.getRestApiUrl(`lila/update-role/${dataspaceId}`, 'dataspace'), payload);
  },

  getDataspaceSpecifications: (dataspaceId: string) : Promise<{ status: number, data: any }> => {
    return axios.get(Util.getRestApiUrl(`lila/getContractDetails/${dataspaceId}`, 'dataspace'));
  },

  sendParticipantInvites: (invites: InviteInput[]) => {
    return axios.post(Util.getRestApiUrl('lila/invitePartner', 'dataspace'), invites);
  },
};

export default buildService;
