/* eslint-disable @typescript-eslint/no-extraneous-class */
import axios, { type AxiosError } from 'axios';
import Util from '../util';
import type { RoleUpdatePayload } from '../interfaces/user/IUser';

export interface Options {
  headers?: Record<string, unknown>
  code?: number
}

export type APIResponse = [null | Error | AxiosError, unknown, Options?];

export default class UserService {
  static getUserInfo () {
    return axios.get(Util.getRestApiUrl('user', 'org'));
  }

  static getUserPlans () {
    return axios.get(Util.getRestApiUrl('orgDevicePlan', 'plan'));
  }

  static getOrgUsers (filter: string) {
    return axios.get(Util.getRestApiUrl('users/org', 'org'), {
      params: {
        filter,
      },
    });
  }

  static getRoles () {
    return axios.get(Util.getRestApiUrl('clientRoles', 'org'));
  }

  static updateRoles (requestPayload: RoleUpdatePayload) {
    return axios.post(Util.getRestApiUrl('user/updateRole', 'org'), requestPayload);
  }

  static inviteUserToJoinOrg (emails: string[], roles: number[]) {
    return axios.post(Util.getRestApiUrl('user/invitation', 'org'), {
      emails,
      roles,
    });
  }

  static updateProfileInitialInfo (requestPayload: {
    firstName: string
    lastName: string
    password: string
  }) {
    return axios.post(Util.getRestApiUrl('user/completeProfile', 'org'), requestPayload);
  }

  static completeExternalAccount (requestPayload: {
    firstName: string
    lastName: string
    email: string
    termsAndCondition: boolean
    dataPrivacy: boolean
  }) {
    return axios.post(Util.getRestApiUrl('user/external-onboarding-request', 'org'), requestPayload);
  }

  static updateProfileWithOnlyNameInfo (requestPayload: {
    firstName: string
    lastName: string
  }) {
    return axios.post(Util.getRestApiUrl('user/completeProfileWithNameInfo', 'org'), requestPayload);
  }

  static updateLanguage (language: string) {
    return axios.patch(Util.getRestApiUrl('user/updateLanguage', 'org') + `?language=${language}`);
  }
}
