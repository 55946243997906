export enum DateTimeFormat {
  // Date Format
  MMM_DD_YYYY = 'MMM DD, YYYY',
  DD_MM_YYYY = 'DD/MM/YYYY',
  DD_MMM_YYYY = 'DD-MMM-YYYY',
  DD_MM_YYYY_DOT = 'DD.MM.YYYY',
  // Time Format
  HH_mm = 'HH:mm',
  HH_mm_AM_PM = 'hhh:mm ampm',
}
