/**
 * This service is use for get verified requests
 */

import axios from 'axios';
import Util from '../util';

const getInsightsServices = {
  fetchReportsRequest: (startDate: string, endDate: string, page: number, pageSize: number, find: string, sort: string) => {
    return axios.get(Util.getRestApiUrl(`enterprise/reports/?startDate=${startDate}&endDate=${endDate}&size=${pageSize}&sort=${sort}&page=${page} ${find === '' ? '' : `&reportName=${find}`}`, 'digital-id'), {
    });
  },

  downloadReport: (payload: string[]) => {
    return axios.post(Util.getRestApiUrl('enterprise/reports/download/', 'digital-id'), payload, {
      responseType: 'blob',
    });
  },

  fetchApiUsageLimit: () => {
    return axios.get(Util.getRestApiUrl('api-limit/usage', 'digital-id'));
  },

  fetchIntegrationList: () => {
    return axios.get(Util.getRestApiUrl('technical/tenants', 'digital-id'));
  },

  deleteIntegration: (clientId: string): Promise<{ status: number }> => {
    return axios.delete(Util.getRestApiUrl(`technical/${clientId}`, 'digital-id'));
  },

  downloadIntegration: (clientId: string): Promise<{ status: number, data: any }> => {
    return axios.get(Util.getRestApiUrl(`technical/downloadCallbackProperties/${clientId}`, 'digital-id'));
  }
};

export default getInsightsServices;
