import type { MutationTree } from 'vuex';
import {
  RESET_FORM,
  SET_ACTIVE_STEP,
  SET_COUNTRIES,
  SET_IS_REQUEST_SUCCESS,
  SET_IS_SUBMIT_INPROGRESS,
  SET_ORG_INFO,
  SET_ORG_PRE_FILL_DATA,
  SET_READONLY_ORG_INFO,
  SET_STEP_IS_VALID,
} from './mutation-types';
import { getDefaultForm } from './index';

import type { manageOnboardingInt } from './OrgOnboardingInt';

export const mutations: MutationTree<manageOnboardingInt> = {
  [SET_ORG_INFO] (state: manageOnboardingInt, payload) {
    state.organizationInfo = payload;
  },
  [SET_ACTIVE_STEP] (state: manageOnboardingInt, value: number) {
    state.activeStep = value;
  },
  [SET_STEP_IS_VALID] (state: manageOnboardingInt, payload) {
    state.steps[payload.step].isInvalid = payload?.isInvalid;
  },
  [SET_IS_SUBMIT_INPROGRESS] (state: manageOnboardingInt, payload: boolean) {
    state.isSubmitInprogress = payload;
  },
  [SET_IS_REQUEST_SUCCESS] (state: manageOnboardingInt, payload: boolean) {
    state.isReqSuccess = payload;
  },
  [SET_COUNTRIES] (state: manageOnboardingInt, payload) {
    state.countries = payload;
  },
  [SET_ORG_PRE_FILL_DATA] (state: manageOnboardingInt, payload) {
    state.organisationPreFilledData = payload;
  },
  [SET_READONLY_ORG_INFO] (state: manageOnboardingInt, payload) {
    state.readOnlyOrgInfo = payload;
  },
  [RESET_FORM] (state: manageOnboardingInt) {
    state.steps = getDefaultForm();
  },
};
