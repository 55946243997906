export enum EnumJoinViaDS {
  LANDING_PAGE = 'LANDING_PAGE',
  EMAIL = 'EMAIL',
}

export enum DataspaceTypes {
  STANDARD = 'STANDARD',
  UPCOMING = 'UPCOMING',
  SPECIAL = 'SPECIAL',
}

export enum EnumDataspaceVisibility {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export enum EnumConnectionStatus {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  ONBOARDING = 'ONBOARDING',
  WITHDRAWN = 'WITHDRAWN',
}

export interface IntDataspace {
  id?: string
  name?: string
  description?: string
  website?: string
  email?: string
  industry?: string
  ownerOrganization?: string | null
  dih?: boolean
  shortDescription?: string
  status?: string
  available?: boolean
  isLilaDsInvitee?: boolean
  livingLabDataspace?: boolean
  source?: string
  dataspaceId?: string
  joinVia?: null | EnumJoinViaDS
  logo?: null | string
  dataspaceType?: null | DataspaceTypes
  stepsToJoin?: string
  note?: string
  dataspaceVisibility?: string
  connectionStatus?: EnumConnectionStatus
}

export interface IntConnectionRequest {
  dataspaceId: string
  requestDescription: string
  companyName: string
  address: string
  contactPerson: string
  email: string
}

export interface IntConnector {
  name?: string
  dataManagementUrl?: string
  transferUrl?: string
}

export interface ConnectorTestNotification {
  type: string
  message: string
  buttonState: string
}

export interface IntDataspaceService {
  dftBackEndUrl?: string
  dftFrontEndUrl?: string
  name?: string
  controlPlaneDataEndpoint?: string
  controlPlaneEndpoint?: string
  dataPlanePublicEndpoint?: string
  edcApiKey?: string
  edcApiKeyValue?: string
  description?: string
  status?: string
  id?: string
  edcApiValue: string
  edcUrl?: string
  optionFlag?: boolean
  edcDidServerId?: string
  edcDidJsonUrl?: string
}

export interface IntMyDataspace extends IntDataspace {
  dataspaceId?: string
  certUploaded?: boolean
  connector?: IntDataspaceService[] | null
  connectors?: IntConnector[]
  packageInstances?: IntConnector[] | null
  services?: null | IntDataspaceService[]
  livingLabDataspace?: boolean
}

export enum enumDataspaceStatus {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  DEPLOYING = 'deploying',
  DEPLOYED = 'deployed',
  FAILED = 'failed',
  AVAILABLE = 'available',
  COMING_SOON = 'coming soon',
  SUCCESS = 'success',
  ACTIVE = 'active',
}

export enum enumParticpantStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  DECLINED = 'declined',
  INVITED = 'invited',
  ONBOARDING = 'onboarding',
  WITHDRAWN = 'withdrawn',
}

export enum enumConnectorStatus {
  SUCCESSFUL = 'successful',
  UNSUCCESSFUL = 'unsuccessful',
}
