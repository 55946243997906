export const SET_IS_PROGRESS = 'SET_IS_PROGRESS';
export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';
export const SET_STEP_IS_VALID = 'SET_STEP_IS_VALID';
export const SET_IS_SUBMIT_INPROGRESS = 'SET_IS_SUBMIT_INPROGRESS';
export const SET_IS_REQUEST_SUCCESS = 'SET_IS_REQUEST_SUCCESS';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_ORG_PRE_FILL_DATA = 'SET_ORG_PRE_FILL_DATA';
export const SET_ORG_INFO = 'SET_ORG_INFO';
export const RESET_FORM = 'RESET_FORM';
export const SET_READONLY_ORG_INFO = 'SET_READONLY_ORG_INFO';
