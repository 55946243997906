/**
 * This service is use for organization request
 */

import axios from 'axios';
import Util from '../util';
import type { NewOrgRequestInt } from '@/modules/onboarding/store/OrgOnboardingInt';
import type { InviteInput } from '@/modules/settings/partner/types/OrganizationInvite';

const organizationService = {
  /**
     * Create a new organization
     * @param organization
     * @returns object
     */
  registerNewOrganization: (organization: NewOrgRequestInt) => {
    return axios.post(Util.getRestApiUrl('organization/request-to-join', 'org'), organization);
  },

  /**
     * Onboardin user with plan selection with uuid
     * @returns {Promise<IConnector[]>}
     */
  checkoutPlan: (payload: unknown) => {
    const reqPayload = payload;
    return axios.post(Util.getRestApiUrl('checkout', 'plan'), reqPayload)
      .then(res => res.data);
  },

  /**
     * Get User's organization list
     * @returns Array of orgnization details object
     */
  getOrgnizationListByUser: () => {
    return axios.get(Util.getRestApiUrl('organization/profile', 'org'));
  },

  /**
     * Get Organization details by id
     * @returns organization details
     */
  getOrganizationDetailsById: (id: string) => {
    return axios.get(Util.getRestApiUrl(`organization/${id}`, 'org'));
  },

  /**
     * Request a change for the organization
     */
  organizationRequestChangeDetailsById: (id: string) => {
    return axios.post(Util.getRestApiUrl(`organization/${id}/change-request`, 'org'));
  },

  /**
     * Onboardin user with plan selection with uuid
     * @returns {Promise}
     */
  getConnectedWithDataspace: (payload: unknown) => {
    const reqPayload = payload;
    return axios.post(Util.getRestApiUrl('checkout', 'plan'), reqPayload);
  },

  /**
     * Get organization invites
     * @returns organization invites
     */
  getOrganizationInvites: (
    filter: string,
    sort: string,
    page: number,
    pageSize: number,
  ) => {
    return axios.get(Util.getRestApiUrl('organization/fetchInvitedOrg', 'org'), {
      params: {
        filter,
        sort,
        page,
        pageSize,
      },
    });
  },

  /**
     * Send organization invites
     * @param organization invite input
     * @returns object
     */
  sendOrganizationInvites: (invites: InviteInput[]) => {
    return axios.post(Util.getRestApiUrl('organization/invitation', 'org'), invites);
  },
};

export default organizationService;
