import axios from 'axios';
import Util from '../util';
import type { subscriptionPackageInterface } from '../interfaces/verify/IGetVerified';

const getVerifiedService = {
  getAllCountries: (locale: string) => {
    return axios.get(Util.getRestApiUrl(`country?${locale}`, 'org'));
  },

  getRegions: (country: string) => {
    return axios.get(Util.getRestApiUrl(`region?isoCode=${country}`, 'org'));
  },

  createVCRequest: (requestPayload: unknown) => {
    return axios.post(Util.getRestApiUrl('orchestration', 'digital-id'), requestPayload, {
      headers: {
        UI: true,
      },
    });
  },

  fetchVCRequest: (filter: string, page: number, pageSize: number, find: string, type: string) => {
    return axios.get(Util.getRestApiUrl(`orchestration/search?vcType=${type}&filter=${filter}&page=${page}&pageSize=${pageSize}&find=${find}`, 'digital-id'), {
      headers: {
        UI: true,
      },
    });
  },
  
  revokeVCRequest: (requestPayload: unknown[]) => {
    return axios.post(Util.getRestApiUrl('orchestration/liLa/revoke/status', 'digital-id'), requestPayload, {
      headers: {
        UI: true,
      },
    });
  },

  fetchSubscriptionData: (orgId: string): Promise<{ data: subscriptionPackageInterface, status: number }> => {
    return axios.get(Util.getRestApiUrl(`subscriptions/fetch/${orgId}`, 'digital-id'));
  },

  requestIntegration: (requestPayload: unknown) => {
    return axios.post(Util.getRestApiUrl('technical', 'digital-id'), requestPayload, {
      headers: {
        UI: true,
      },
    });
  },

  editIntegration: (requestPayload: unknown) => {
    return axios.post(Util.getRestApiUrl('technical/callbackProperties', 'digital-id'), requestPayload, {
      headers: {
        UI: true,
      },
    });
  },

  downloadVerifiablePresentation: (complianceId: string): Promise<{ data: any, status: number }> => {
    return axios.get(Util.getRestApiUrl(`compliance/json/${complianceId}`, 'digital-id'));
  },
};

export default getVerifiedService;
