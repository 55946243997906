const dateUtil = {
  /**
 * Format a timestamp into a custom date and time string.
 * @param {string} timestamp - A string representing a timestamp.
 * @param {string} format - A format string containing placeholders for year, month, day, hours, minutes, and seconds.
 * @returns {string} - Formatted date and time string.
 */
  formatDateTime: (timestamp: string, format: string) => {
    const date = new Date(timestamp);

    if (isNaN(date.getTime())) {
      return '-';
    }

    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const monthLetters = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
    const amPmHours = (date.getHours() % 12) !== 0 ? (date.getHours() % 12) : 12;

    format = format.replace('YYYY', year);
    format = format.replace('YY', year.slice(-2));
    format = format.replace('MMM', monthLetters);
    format = format.replace('MM', month);
    format = format.replace('DD', day);
    format = format.replace('HH', hours);
    format = format.replace('mm', minutes);
    format = format.replace('ss', seconds);
    format = format.replace('hhh', `${amPmHours}`);
    format = format.replace('ampm', ampm);

    return format;
  },
};

export default dateUtil;
