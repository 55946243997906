import axios from 'axios';
import store from '@/store';
import {
  DECREMENT_LOADING_STATE,
  INCREMENT_LOADING_STATE,
  SET_PREVENT_LOADING,
} from '@/store/modules/loading/mutation-types';
import { PREVENT_LOADING } from '@/store/modules/loading/getter-types';

axios.interceptors.request.use(config => {
  if (!store.getters[PREVENT_LOADING]) {
    store.commit(INCREMENT_LOADING_STATE, Date.now());
  }

  return config;
}, error => Promise.reject(error));

axios.interceptors.response.use(response => {
  if (!store.getters[PREVENT_LOADING]) {
    store.commit(DECREMENT_LOADING_STATE);
  }
  store.commit(SET_PREVENT_LOADING, false);

  return response;
}, error => {
  if (!store.getters[PREVENT_LOADING]) {
    store.commit(DECREMENT_LOADING_STATE);
  }
  store.commit(SET_PREVENT_LOADING, false);

  return Promise.reject(error);
});
