import type { MutationTree } from 'vuex';
import { pageSize } from '../../../common/constants/getVerifiedConstants';
import {
  SET_VC_FILTER_PARAMS,
  SET_VC_DETAILS,
  SET_FETCH_VC_DETAILS_IN_PROGRESS,
  SET_APPEND_VCS,
  SET_COUNTRIES,
  SET_REGIONS,
  SET_BULK_ISSUING_STATUSES,
  SET_VC_REQUEST_IN_PROGRESS,
  SET_REPORT_LIST_DETAILS,
  SET_RL_FILTER_PARAMS,
  SET_FETCH_REPORT_LIST_IN_PROGRESS,
  SET_API_USAGE_LIMIT_DATA,
  SET_FETCH_API_USAGE_IN_PROGRESS,
  SET_CERTIFICATE_LIST,
  SET_FETCH_CERTIFICATE_LIST_IN_PROGRESS,
} from './mutation-types';
import type { credentialsInterface, VCInterface } from './IGetVerified';
import type { certificateInterface } from './IMyCertificates';
import type { IGetVerifiedState } from './index';

function appendVCs (vcDetailsState: VCInterface, content: Record<string, credentialsInterface[]>) {
  if (content?.legalPersonVCs !== undefined && content?.legalPersonVCs.length > 0) {
    vcDetailsState.content[0].legalPersonVCs.push(...content.legalPersonVCs);
  }
  if (content?.serviceOfferingVCs !== undefined && content?.serviceOfferingVCs.length > 0) {
    vcDetailsState.content[0].serviceOfferingVCs.push(...content.serviceOfferingVCs);
  }
  if (content?.physicalResourceVCs !== undefined && content?.physicalResourceVCs.length > 0) {
    vcDetailsState.content[0].physicalResourceVCs.push(...content.physicalResourceVCs);
  }
  if (content?.softwareResourceVCs !== undefined && content?.softwareResourceVCs.length > 0) {
    vcDetailsState.content[0].softwareResourceVCs.push(...content.softwareResourceVCs);
  }
  if (content?.dataResourceVCs !== undefined && content?.dataResourceVCs.length > 0) {
    vcDetailsState.content[0].dataResourceVCs.push(...content.dataResourceVCs);
  }
}

const mutations: MutationTree<IGetVerifiedState> = {
  [SET_VC_FILTER_PARAMS] (state: IGetVerifiedState, payload) {
    state.VCFilterParams.filterParam = (payload?.filterParam !== undefined && payload?.filterParam !== 'status:ALL') ? payload?.filterParam : '';
    state.VCFilterParams.page.pageStart = payload?.pageStart !== undefined ? payload.pageStart : 0;
    state.VCFilterParams.page.pageSize = payload?.pageSize !== undefined ? payload.pageSize : pageSize;
    state.VCFilterParams.searchKeyword = payload?.searchKeyword !== undefined ? payload.searchKeyword : '';
    state.VCFilterParams.vcType = payload?.vcType !== undefined ? payload.vcType : '';
  },
  [SET_VC_DETAILS] (state: IGetVerifiedState, payload) {
    if (state.appendVCs) {
      appendVCs(state.VCDetails, payload.content[0]);
    } else {
      state.VCDetails = payload;
      if (payload.content[0]?.serviceOfferingVCs === undefined) {
        state.VCDetails.content[0].serviceOfferingVCs = [];
      }
    }
  },
  [SET_FETCH_VC_DETAILS_IN_PROGRESS] (state: IGetVerifiedState, payload: boolean) {
    state.fetchVCDetailsInProgress = payload;
  },
  [SET_VC_REQUEST_IN_PROGRESS] (state: IGetVerifiedState, payload: boolean) {
    state.vcRequestInProgress = payload;
  },
  [SET_APPEND_VCS] (state: IGetVerifiedState, payload: boolean) {
    state.appendVCs = payload;
  },
  [SET_COUNTRIES] (state: IGetVerifiedState, payload) {
    state.countries = payload;
  },
  [SET_REGIONS] (state: IGetVerifiedState, payload) {
    state.regions = payload;
  },
  [SET_BULK_ISSUING_STATUSES] (state: IGetVerifiedState, payload) {
    Array.isArray(payload) ? state.bulkIssuingStatuses = payload : state.bulkIssuingStatuses.push(payload);
  },
  [SET_RL_FILTER_PARAMS] (state: IGetVerifiedState, payload) {
    state.reportListFilterParams.sort = payload?.sort !== undefined ? payload.sort : null;
    state.reportListFilterParams.page = payload?.page !== undefined ? payload.page : 1;
    state.reportListFilterParams.pageSize = payload?.pageSize !== undefined ? payload.pageSize : pageSize;
    state.reportListFilterParams.find = payload?.find !== undefined ? payload.find : '';
    state.reportListFilterParams.startDate = payload?.startDate !== undefined ? payload.startDate : '';
    state.reportListFilterParams.endDate = payload?.endDate !== undefined ? payload.endDate : '';
  },
  [SET_REPORT_LIST_DETAILS] (state: IGetVerifiedState, payload) {
    state.reportsListDetails.reportList = payload.content;
    state.reportsListDetails.pageNumber = payload.pageNumber;
    state.reportsListDetails.totalElements = payload.totalElements;
    state.reportsListDetails.totalPages = payload.totalPages;
  },
  [SET_FETCH_REPORT_LIST_IN_PROGRESS] (state: IGetVerifiedState, payload: boolean) {
    state.fetchReportListInProgress = payload;
  },
  [SET_API_USAGE_LIMIT_DATA] (state: IGetVerifiedState, payload) {
    state.apiUsageLimitData = payload;
  },
  [SET_FETCH_API_USAGE_IN_PROGRESS] (state: IGetVerifiedState, payload: boolean) {
    state.fetchApiUsageInProgress = payload;
  },
  [SET_CERTIFICATE_LIST] (state: IGetVerifiedState, payload: certificateInterface[]) {
    state.certificateList = payload;
  },
  [SET_FETCH_CERTIFICATE_LIST_IN_PROGRESS] (state: IGetVerifiedState, payload: boolean) {
    state.fetchCertificateListInProgress = payload;
  },
};
export default mutations;
