import type { GetterTree } from 'vuex';
import {
  GET_APPLICATION_DATA,
  GET_DATASPACE_DETAILS,
  GET_OWNED_DATASPACES,
  GET_PARTICIPANT_DET,
  GET_SERVICE_OVERVIEW,
  GET_ALLOCATE_CONNECTOR_ORIGIN_DETAILS,
} from './getter-types';
import type { IntBuildDataspaceState } from '@/common/interfaces/build/IntBuildDataspaces';

export const getters: GetterTree<IntBuildDataspaceState, {}> = {
  [GET_OWNED_DATASPACES]: state => state.ownedDataspace,
  [GET_PARTICIPANT_DET]: state => state.participantDetail,
  [GET_SERVICE_OVERVIEW]: state => state.serviceOverview,
  [GET_DATASPACE_DETAILS]: state => state.dataspaceDetails,
  [GET_ALLOCATE_CONNECTOR_ORIGIN_DETAILS]: state => state.allocateConnectorOriginDetails,
  [GET_APPLICATION_DATA]: state => state.applicationData,
};
