export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const FETCH_REGIONS = 'FETCH_REGIONS';
export const SEND_VC_REQUEST = 'SEND_VC_REQUEST';
export const SEND_BULK_VC_REQUEST = 'SEND_BULK_VC_REQUEST';
export const FETCH_VC_REQUEST = 'FETCH_VC_REQUEST';
export const REVOKE_VC_REQUEST = 'REVOKE_VC_REQUEST';
export const FETCH_REPORT_LIST = 'FETCH_REPORT_LIST';
export const DOWNLOAD_REPORTS = 'DOWNLOAD_REPORTS';
export const FETCH_API_USAGE_LIMIT = 'FETCH_API_USAGE_LIMIT';
export const FETCH_SUBSCRIPTION_DATA = 'FETCH_SUBSCRIPTION_DATA';
export const DOWNLOAD_VP = 'DOWNLOAD_VP';
export const DELETE_INTEGRATION = 'DELETE_INTEGRATION';
export const DOWNLOAD_INTEGRATION = 'DOWNLOAD_INTEGRATION';
export const FETCH_CERTIFICATE_LIST = 'FETCH_CERTIFICATE_LIST';
