export enum Permission {
  ACCESS_DATASPACE_SERVICES = 'access_dataspace_services',
  BROWSE_DATASPACES = 'browse_dataspaces',
  CONSUME_APPLICATION = 'consume_application',
  DOWNLOAD_DATA = 'download_data',
  ENTER_DATASPACE = 'enter_dataspace',
  JOIN_DATASPACE = 'join_dataspace',
  MANAGE_DATASPACE_MEMBERSHIP = 'manage_dataspace_membership',
  MANAGE_EXTERNAL_CONNECTIONS = 'manage_external_connections',
  MANAGE_LIVING_LAB = 'manage_living_lab',
  MANAGE_ORGANIZATION_PROFILE = 'manage_organization_profile',
  MANAGE_ORGANIZATION_USERS = 'manage_organization_users',
  MANAGE_OWN_PROFILE = 'manage_own_profile',
  MANAGE_PARTNER_NETWORK = 'manage_partner_network',
  ORDER_DATA_PRODUCTS = 'order_data_products',
  ORDER_LIVING_LAB = 'order_living_lab',
  ORDER_PRODUCTS = 'order_products',
  REQUEST_CREDENTIALS = 'request_credentials',
  REVOKE_CREDENTIALS = 'revoke_credentials',
  TRANSFER_DATA = 'transfer_data',
  VIEW_API_USAGE_LIMIT = 'view_api_usage_limit',
  VIEW_CREDENTIALS = 'view_credentials',
  VIEW_DATA_PRODUCTS = 'view_data_products',
  VIEW_EXTERNAL_CONNECTIONS = 'view_external_connections',
  VIEW_INCOMING_CONTRACTS = 'view_incoming_contracts',
  VIEW_LIVING_LAB = 'view_living_lab',
  VIEW_MONTHLY_REPORTS = 'view_monthly_reports',
  VIEW_ORGANIZATION_PROFILE = 'view_organization_profile',
  VIEW_ORGANIZATION_USERS = 'view_organization_users',
  VIEW_OWN_PROFILE = 'view_own_profile',
  VIEW_PARTNER_NETWORK = 'view_partner_network',
  VIEW_SUBSCRIPTIONS = 'view_subscriptions',
}
