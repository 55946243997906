<template>
  <div class="notification-bar">
    <TNotificationBar v-model="notifications">
      <TNotification
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
      >
        {{ notification.variables ? $t(notification.text, notification.variables) : $t(notification.text) }}
      </TNotification>
    </TNotificationBar>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import Util from '@/common/util';
import { NOTIFICATION_LIST } from './store/getter-types';
import { UPDATE_NOTIFICATION_LIST } from './store/mutation-types';
import type { INotification } from '../notifications/INotification';
import TNotification from '../notifications/TNotification/TNotification.vue';
import TNotificationBar from '../notifications/TNotificationBar/TNotificationBar.vue';

const notifications = computed(
  Util.mapTwoWay<INotification[]>(NOTIFICATION_LIST, UPDATE_NOTIFICATION_LIST),
);
</script>
