import type { MutationTree } from 'vuex';
import {
  SET_ORG_PROFILE_INFO,
  SET_ORG_IS_PROGRESS,
  SET_ORG_REQUEST_CHANGE_INPROGRESS,
} from './mutation-types';

import type { IOrgProfileState } from './index';

const mutations: MutationTree<IOrgProfileState> = {
  [SET_ORG_PROFILE_INFO] (state: IOrgProfileState, payload) {
    state.orgProfileInfo = payload;
  },
  [SET_ORG_IS_PROGRESS] (state: IOrgProfileState, payload: boolean) {
    state.isProgress = payload;
  },
  [SET_ORG_REQUEST_CHANGE_INPROGRESS] (state: IOrgProfileState, payload: boolean) {
    state.requestChangeInProgress = payload;
  },
};
export default mutations;
