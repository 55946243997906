import type { MutationTree } from 'vuex';
import {
  SET_USER_INFO,
  SET_IS_LOADING,
  SET_PAGE_BREADCRUMBS,
  SET_ACTIVE_INVITATIONS,
  SET_IS_EXTERNAL_ACCOUNT_COMPLETED,
  SET_USER_LANG,
} from './mutation-types';
import type {
  IntUserProfileState,
  ICurrentRoute,
} from './index';
import type { IntDataspace } from '@/common/interfaces/dataspace/IntDataspaces';
import Translation, { type Locale } from '@/i18n/translation';

const mutations: MutationTree<IntUserProfileState> = {
  [SET_USER_INFO] (state, payload) {
    state.userInfo = payload;
  },
  [SET_IS_LOADING] (state, payload: boolean) {
    state.isLoading = payload;
  },
  [SET_PAGE_BREADCRUMBS] (state, payload: ICurrentRoute[]) {
    state.breadcrumbs = [];
    setTimeout(() => {
      state.breadcrumbs = payload;
    }, 100);
  },
  [SET_ACTIVE_INVITATIONS] (state, payload: IntDataspace[]) {
    state.activeInvitations = payload;
  },
  [SET_IS_EXTERNAL_ACCOUNT_COMPLETED] (state, payload: boolean) {
    state.isExternalAccountCompleted = payload;
  },
  [SET_USER_LANG] (state, payload: Locale) {
    Translation.switchLanguage(payload);
  },
};
export default mutations;
