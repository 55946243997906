const TO_STRING_START_POSITION = 8;
const getType = (val: unknown): string => Object.prototype.toString.call(val).slice(TO_STRING_START_POSITION, -1).toLowerCase();

export const isString = (val: unknown): val is string => typeof val === 'string';

export const isNumber = (val: unknown): val is number => typeof val === 'number' && !Number.isNaN(val);

const isNull = (val: unknown): val is null => val === null;
export const isUndefined = (val: unknown): val is undefined => val === undefined;
export const isNullish = (val: unknown): val is null | undefined => isNull(val) || isUndefined(val);

export const isObject = <T extends Record<string, unknown>>(val: unknown): val is T => getType(val) === 'object';

export const isDate = (val: unknown): val is Date => getType(val) === 'date';

export const isBoolean = (val: unknown): val is boolean => typeof val === 'boolean';

export const isArray = <T>(val: unknown): val is T[] => Array.isArray(val);

// eslint-disable-next-line @typescript-eslint/ban-types -- allow here
export const isFunction = (val: unknown): val is Function => typeof val === 'function';
