import type { MutationTree } from 'vuex';
import type { IntAllocateConnectorRequestOrigin, IntApplicationManagement, IntBuildDataspaceState, IntDataspaceDetails, IntOwnedDataspace, IntParticipantData, IntRequestInfo, IntServiceOverview } from '@/common/interfaces/build/IntBuildDataspaces';
import {
  SET_PARTICIPANT_DET,
  SET_OWNED_DATASPACES,
  SET_SERVICE_OVERVIEW,
  SET_DISPLAY_DETAILS,
  SET_ALLOCATE_CONNECTOR_ORIGIN_DETAILS,
  SET_APPLICATION_DATA,
} from './mutation-types';

const mutations: MutationTree<IntBuildDataspaceState> = {
  [SET_OWNED_DATASPACES] (state, payload: IntOwnedDataspace[]) {
    state.ownedDataspace = payload;
  },
  [SET_PARTICIPANT_DET] (state, payload: IntParticipantData[]) {
    state.participantDetail = payload;
  },
  [SET_SERVICE_OVERVIEW] (state, payload: IntServiceOverview) {
    state.serviceOverview = payload;
  },
  [SET_DISPLAY_DETAILS] (state, payload: IntDataspaceDetails) {
    state.dataspaceDetails = payload;
  },
  [SET_APPLICATION_DATA] (state, payload: IntApplicationManagement) {
    state.applicationData = payload;
  },
  [SET_ALLOCATE_CONNECTOR_ORIGIN_DETAILS] (state, payload: IntAllocateConnectorRequestOrigin) {
    state.allocateConnectorOriginDetails = payload;
  },
};
export default mutations;
