import type { Module } from 'vuex';
import { actions } from './actions';
import mutations from './mutations';
import { getters } from './getters';

import type { IntOrgProfileObj } from '@/common/interfaces/organization/ICreateOrganization';

export interface IOrgProfileState {
  orgProfileInfo: IntOrgProfileObj | null
  isProgress: boolean
  requestChangeInProgress: boolean
}

// initial state
const getDefaultState = () => {
  return {
    isProgress: false,
    orgProfileInfo: null,
    requestChangeInProgress: false,
  };
};
const INITIAL_STATE = getDefaultState();

// org profile store
const OrgProfileStore: Module<IOrgProfileState, {}> = {
  namespaced: true,
  state: INITIAL_STATE,
  actions,
  mutations,
  getters,
};

export default OrgProfileStore;
