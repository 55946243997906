export interface IUser {
  firstName: string
  lastName: string
  language: string
  email: string
  phone: string
  orgName: string
  roleName: string
  roleDtoList: Role[]
  createdDateTime: string
  country: string
}

export enum EnumUserStatus {
  ACTIVE = 'ACTIVE',
  INVITED = 'INVITED',
  DEACTIVATED = 'DEACTIVATED',
}

export const UserStatusColor = {
  [EnumUserStatus.ACTIVE]: 'olive',
  [EnumUserStatus.DEACTIVATED]: 'orange',
  [EnumUserStatus.INVITED]: 'violet',
};

export enum RoleType {
  CLIENT_ROLE = 'CLIENT_ROLE',
  REALM_ROLE = 'REALM_ROLE',
}

export interface IOrgUser extends IUser {
  id: string
  orgId: string
  isDihUser: boolean
  firstLogin: boolean
  userStatusInOrg?: EnumUserStatus
  hasKeycloakConflict: boolean
}

export interface Role {
  id: number
  roleDescription: string
  roleName: string
  roleType: RoleType
  status: boolean
  tooltipText: string
  createDateTime: string
  updateDateTime: string
}

export interface RoleUpdatePayload {
  email: string
  rolesToAdd: number[]
  rolesToRemove: number[]
}
