import type { ActionTree } from 'vuex';
import {
  FETCH_OWNED_DATASPACES,
  FETCH_PARTICIPANT_DET,
  FETCH_SERVICE_OVERVIEW,
  FETCH_DATASPACE_DETAILS,
  REGISTER_APPLICATION,
} from './action-types';
import {
  SET_PARTICIPANT_DET,
  SET_OWNED_DATASPACES,
  SET_SERVICE_OVERVIEW,
  SET_DISPLAY_DETAILS,
} from './mutation-types';
import {
  ADD_ERROR_NOTIFICATION,
} from '@/components/NotificationBar/store/mutation-types';
import BuildServices from '@/common/services/BuildServices';
import type { IntBuildDataspaceState } from '@/common/interfaces/build/IntBuildDataspaces';
import { SET_IS_LOADING } from '@/store/modules/user/mutation-types';

export const actions: ActionTree<IntBuildDataspaceState, {}> = {

  /**
   * Fetch user's owned dataspaces
   */
  [FETCH_OWNED_DATASPACES] ({ commit }) {
    commit(SET_IS_LOADING, true);
    commit(SET_OWNED_DATASPACES, []);
    return new Promise((resolve, reject) => {
      BuildServices.myOwnedDataspaces()
        .then(res => {
          const { status, data } = res;
          if (status === 200) {
            commit(SET_OWNED_DATASPACES, data);
            resolve(true);
          } else {
            commit(ADD_ERROR_NOTIFICATION, 'genericError');
            resolve(false);
          }
        })
        .catch(() => {
          commit(ADD_ERROR_NOTIFICATION, 'genericError');
          resolve(false);
        })
        .finally(() => {
          commit(SET_IS_LOADING, false);
        });
    });
  },

  /**
   * Fetch participant overview details
   */
  [FETCH_PARTICIPANT_DET] ({ commit }, dataspaceId) {
    commit(SET_IS_LOADING, true);
    commit(SET_PARTICIPANT_DET, []);
    return new Promise((resolve, reject) => {
      BuildServices.participantDetails(dataspaceId)
        .then(res => {
          const { status, data } = res;
          if (status === 200) {
            commit(SET_PARTICIPANT_DET, data);
            resolve(true);
          } else {
            commit(ADD_ERROR_NOTIFICATION, 'genericError');
            resolve(false);
          }
        })
        .catch(() => {
          commit(ADD_ERROR_NOTIFICATION, 'genericError');
          reject(new Error('error'));
        })
        .finally(() => {
          commit(SET_IS_LOADING, false);
        });
    });
  },

  /**
   * Send request to register new application
   */
  [REGISTER_APPLICATION] ({ commit }, payload: {
    dataspaceId: string
    ownerOrgId: string
    description: string
    dashboardUrl: string
    name: string
    logo?: string
  }) {
    return new Promise((resolve, reject) => {
      BuildServices.registerApplication(payload)
        .then(res => {
          const { status } = res;
          if (status === 202) {
            resolve('success');
          } else {
            reject(new Error('error'));
          }
        })
        .catch(({ request: { status } }) => {
          reject(new Error(status));
        });
    });
  },

  /**
   * Fetch service overview details
   */
  [FETCH_SERVICE_OVERVIEW] ({ commit }, dataspaceId) {
    commit(SET_IS_LOADING, true);
    commit(SET_SERVICE_OVERVIEW, []);
    return new Promise((resolve, reject) => {
      BuildServices.getServiceDetails(dataspaceId)
        .then(res => {
          const { status, data } = res;
          if (status === 200) {
            commit(SET_SERVICE_OVERVIEW, data);
            resolve(true);
          } else {
            commit(ADD_ERROR_NOTIFICATION, 'genericError');
            resolve(false);
          }
        })
        .catch(() => {
          commit(ADD_ERROR_NOTIFICATION, 'genericError');
          reject(new Error('error'));
        })
        .finally(() => {
          commit(SET_IS_LOADING, false);
        });
    });
  },

  /**
   * Fetch service overview details
   */
  [FETCH_DATASPACE_DETAILS] ({ commit }, dataspaceId) {
    commit(SET_IS_LOADING, true);
    commit(SET_DISPLAY_DETAILS, []);
    return new Promise((resolve, reject) => {
      BuildServices.dataspaceDetails(dataspaceId)
        .then(res => {
          const { status, data } = res;
          if (status === 200) {
            commit(SET_DISPLAY_DETAILS, data);
            resolve(true);
          } else {
            commit(ADD_ERROR_NOTIFICATION, 'genericError');
            resolve(false);
          }
        })
        .catch(() => {
          commit(ADD_ERROR_NOTIFICATION, 'genericError');
          reject(new Error('error'));
        })
        .finally(() => {
          commit(SET_IS_LOADING, false);
        });
    });
  }
};
