import type { GetterTree } from 'vuex';
import {
  GET_VC_FILTER_PARAMS,
  GET_VC_DETAILS,
  GET_FETCH_VC_DETAILS_IN_PROGRESS,
  GET_VC_REQUEST_IN_PROGRESS,
  GET_APPEND_VCS,
  GET_COUNTRIES,
  GET_REGIONS,
  GET_BULK_ISSUING_STATUSES,
  GET_REPORT_LIST_FILTER_PARAMS,
  GET_FETCH_REPORT_LIST_IN_PROGRESS,
  GET_RL_DETAILS,
  GET_REPORT_LISTS_DETAILS,
  GET_API_USAGE_LIMIT_DATA,
  GET_FETCH_API_USAGE_IN_PROGRESS,
  GET_CERTIFICATE_LIST,
  GET_FETCH_CERTIFICATE_LIST_IN_PROGRESS,
} from './getter-types';
import type {
  IGetVerifiedState,
} from './index';

export const getters: GetterTree<IGetVerifiedState, {}> = {
  [GET_VC_FILTER_PARAMS]: (state) => state.VCFilterParams,
  [GET_VC_DETAILS]: (state) => state.VCDetails.content[0],
  [GET_FETCH_VC_DETAILS_IN_PROGRESS]: (state) => state.fetchVCDetailsInProgress,
  [GET_VC_REQUEST_IN_PROGRESS]: (state) => state.vcRequestInProgress,
  [GET_APPEND_VCS]: (state) => state.appendVCs,
  [GET_COUNTRIES]: (state) => state.countries,
  [GET_REGIONS]: (state) => state.regions,
  [GET_BULK_ISSUING_STATUSES]: (state) => state.bulkIssuingStatuses,
  [GET_REPORT_LISTS_DETAILS]: (state) => state.reportsListDetails.reportList,
  [GET_REPORT_LIST_FILTER_PARAMS]: (state) => state.reportListFilterParams,
  [GET_FETCH_REPORT_LIST_IN_PROGRESS]: (state) => state.fetchReportListInProgress,
  [GET_RL_DETAILS]: (state) => state.reportsListDetails,
  [GET_API_USAGE_LIMIT_DATA]: (state) => state.apiUsageLimitData,
  [GET_FETCH_API_USAGE_IN_PROGRESS]: (state) => state.fetchApiUsageInProgress,
  [GET_CERTIFICATE_LIST]: (state) => state.certificateList,
  [GET_FETCH_CERTIFICATE_LIST_IN_PROGRESS]: (state) => state.fetchCertificateListInProgress,
};
