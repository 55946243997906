import type { Module } from 'vuex';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import type { IOrgUser } from '@/common/interfaces/user/IUser';
import type { IntDataspace } from '@/common/interfaces/dataspace/IntDataspaces';

export interface ICurrentRoute {
  title: string
  routeName: string
}
export interface IntUserProfileState {
  userInfo: IOrgUser | null
  isLoading: boolean
  breadcrumbs: ICurrentRoute[]
  activeInvitations: IntDataspace[] | []
  isExternalAccountCompleted: boolean | true
}

// initial state
export const getDefaultState = (): IntUserProfileState => {
  return {
    isLoading: false,
    userInfo: null,
    breadcrumbs: [],
    activeInvitations: [],
    isExternalAccountCompleted: true,
  };
};
const INITIAL_STATE = getDefaultState();

// org profile store
const UserProfileStore: Module<IntUserProfileState, {}> = {
  namespaced: true,
  state: INITIAL_STATE,
  actions,
  mutations,
  getters,
};

export default UserProfileStore;
