import { createI18n } from 'vue-i18n';

import en from './locales/en.json';
import de from './locales/de.json';
import es from './locales/es.json';
import fr from './locales/fr.json';

const i18n = createI18n({
  locale: import.meta.env.VITE_APP_DEFAULT_LOCALE,
  fallbackLocale: import.meta.env.VITE_APP_FALLBACK_LOCALE,
  legacy: false,
  availableLocales: import.meta.env.VITE_APP_SUPPORTED_LOCALES,
  messages: {
    en,
    de,
    es,
    fr,
  },
});

export default i18n;
