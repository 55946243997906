// defines route path and pathname declaration

export const ProtectedRoutes = {
  manage: {
    externalConnection: {
      path: 'external-connection-manager',
      pathName: 'external-connection-manager',
    },
    requestConnection: {
      path: 'request-external-connection',
      pathName: 'request-external-connection',
    },
  },
};
