import type { Module } from 'vuex';
import saveFile from 'save-as-file';
import type { IIncomingDataAgreement, IContractAsset, transferType } from '@/common/interfaces/workbench/IIncomingDataAgreement';
import {
  DOWNLOAD_ASSETS,
  FETCH_CONTRACTS,
} from './action-types';
import {
  GET_SELECTED_CONTRACT,
  GET_SELECTED_CONTRACT_LOADING,
  GET_CONTRACTS,
  GET_CONTRACTS_IS_LOADING,
  GET_TRANSFER_PROCESS_CONFIG,
} from './getters-types';
import {
  SET_CONTRACTS,
  SET_CONTRACTS_IS_LOADING,
  SET_SELECTED_CONTRACT,
  SET_SELECTED_CONTRACT_LOADING,
  UPDATE_ASSET_DOWNLOAD_STATUS,
  UPDATE_ASSET_TRANSFER_STATUS,
  UPDATE_TRANSFER_PROCESS_CONFIG,
  RESET_TRANSFER_PROCESS_CONFIG,
} from './mutation-types';
import {
  ADD_ERROR_NOTIFICATION,
  ADD_SUCCESS_NOTIFICATION,
} from '@/components/NotificationBar/store/mutation-types';
import { downloadAssetFromAgreements, getIncomingDataAgreements } from '@/common/services/DataAgreementsServices';
import i18n from '@/i18n';

export interface IntTransferProcessConfig {
  isTransferModalEnabled: boolean
  isTransferSuccessModalEnabled: boolean
  isTransferInProgress: boolean
  selectedAgreement: IIncomingDataAgreement | null
  selectedAsset: IContractAsset | null
}
export interface IContractDetailsStoreState {
  contracts: IIncomingDataAgreement[]
  isContractsLoading: boolean
  contract: null | IIncomingDataAgreement
  isContractLoading: boolean
  transferProcessConfig: IntTransferProcessConfig
}

const ContractDetailsStore: Module<IContractDetailsStoreState, {}> = {
  namespaced: true,
  state: {
    contracts: [],
    isContractsLoading: false,
    contract: null,
    isContractLoading: false,
    transferProcessConfig: {
      isTransferModalEnabled: false,
      isTransferSuccessModalEnabled: false,
      isTransferInProgress: false,
      selectedAgreement: null,
      selectedAsset: null,
    },
  },
  actions: {
    async [FETCH_CONTRACTS] ({ commit }) {
      try {
        const res = await getIncomingDataAgreements();
        commit(SET_CONTRACTS, res);
      } catch {
        throw new Error('Unable to get contracts');
      }
    },

    async [DOWNLOAD_ASSETS] ({ commit }, payload: { asset: IContractAsset, contractId: string, type: string }) {
      // update asset download status to in-progress
      commit(UPDATE_ASSET_DOWNLOAD_STATUS, {
        contractId: payload.contractId,
        asset: payload.asset,
        downloadStatusType: 'in-progress',
        type: payload.type,
      });
      try {
        const res = await downloadAssetFromAgreements({
          contractId: payload.contractId,
          assetId: payload.asset.assetId,
        });
        // open link in new to download file and file name is runtime
        saveFile(res, '');
        commit(UPDATE_ASSET_DOWNLOAD_STATUS, {
          contractId: payload.contractId,
          asset: payload.asset,
          downloadStatusType: 'success',
          type: payload.type,
        });
        commit(ADD_SUCCESS_NOTIFICATION, i18n.global.t('workbench.incomingDataAgreements.successDownload'), { root: true });
      } catch {
        commit(ADD_ERROR_NOTIFICATION, i18n.global.t('workbench.incomingDataAgreements.errorDownload', { filename: payload.asset.fileName }), { root: true });
        commit(UPDATE_ASSET_DOWNLOAD_STATUS, {
          contractId: payload.contractId,
          asset: payload.asset,
          downloadStatusType: 'error',
          type: payload.type,
        });
      }
    },
  },
  mutations: {

    [SET_CONTRACTS] (state, contracts: IIncomingDataAgreement[]): void {
      let updatedContracts = [];
      updatedContracts = contracts.map(uContract => {
        uContract.assets?.forEach(asset => {
          asset.downloadStatus = null;
          asset.transferStatus = null;
        });
        // check local contract and update assets status if found
        const index = state.contracts.findIndex((item: IIncomingDataAgreement) => item.contractId === uContract.contractId);
        if (index !== -1) {
          uContract = state.contracts[index];
        }
        return uContract;
      });
      state.contracts = updatedContracts;
    },

    [SET_CONTRACTS_IS_LOADING] (state, payload: boolean): void {
      state.isContractsLoading = payload;
    },

    [SET_SELECTED_CONTRACT] (state, contract: IIncomingDataAgreement | null): void {
      state.contract = contract;
    },

    [SET_SELECTED_CONTRACT_LOADING] (state, isLoading: boolean): void {
      state.isContractLoading = isLoading;
    },

    [UPDATE_ASSET_DOWNLOAD_STATUS] (state, payload: { contractId: string, asset: IContractAsset, downloadStatusType: string, type: string }) {
      // gets the agreement and update assets downloading status
      if (payload.type === 'details') {
        const assetIndex = state.contract?.assets?.findIndex(item => item.assetId === payload.asset.assetId);
        if (assetIndex !== -1 && assetIndex !== undefined && state.contract?.assets !== undefined) {
          state.contract.assets[assetIndex] = {
            ...state.contract.assets[assetIndex],
            downloadStatus: payload.downloadStatusType,
          };
        }
      }
      // update the file assets status in the list
      const contractIndex = state.contracts.findIndex(item => item.contractId === payload.contractId);
      state.contracts[contractIndex].assets?.forEach(assetItem => {
        if (assetItem.assetId === payload.asset.assetId) {
          assetItem.downloadStatus = payload.downloadStatusType;
        }
      });
    },

    [UPDATE_ASSET_TRANSFER_STATUS] (state, payload: { contractId: string, asset: IContractAsset, transferStatusType: transferType, type: string }) {
      if (payload.type === 'details') {
        const assetIndex = state.contract?.assets?.findIndex(item => item.assetId === payload.asset.assetId);
        if (assetIndex !== -1 && assetIndex !== undefined && state.contract?.assets !== undefined) {
          state.contract.assets[assetIndex] = {
            ...state.contract.assets[assetIndex],
            transferStatus: null,
          };
        }
      }
      const contractIndex = state.contracts.findIndex(item => item.contractId === payload.contractId);
      state.contracts[contractIndex].assets?.forEach(assetItem => {
        if (assetItem.assetId === payload.asset.assetId) {
          assetItem.transferStatus = payload.transferStatusType;
        }
      });
    },

    [UPDATE_TRANSFER_PROCESS_CONFIG] (state, payload: IntTransferProcessConfig): void {
      state.transferProcessConfig = {
        ...state.transferProcessConfig,
        ...payload,
      };
    },

    [RESET_TRANSFER_PROCESS_CONFIG] (state): void {
      state.transferProcessConfig = {
        isTransferModalEnabled: false,
        isTransferSuccessModalEnabled: false,
        isTransferInProgress: false,
        selectedAgreement: null,
        selectedAsset: null,
      };
    },
  },
  getters: {
    [GET_SELECTED_CONTRACT]: state => state.contract,
    [GET_SELECTED_CONTRACT_LOADING]: state => state.isContractLoading,
    [GET_CONTRACTS]: state => state.contracts,
    [GET_CONTRACTS_IS_LOADING]: state => state.isContractsLoading,
    [GET_TRANSFER_PROCESS_CONFIG]: state => state.transferProcessConfig,
  },
};

export default ContractDetailsStore;
