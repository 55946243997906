import { createApp } from 'vue';
import './styles/styles.scss';
import { applyPolyfills, defineCustomElements } from '@telekom/scale-components/loader';
import '@telekom/scale-components/dist/scale-components/scale-components.css';
import { OuterClick } from './directives/outerClick';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import './common/http/interceptors';

// eslint-disable-next-line @typescript-eslint/no-floating-promises
applyPolyfills().then(() => {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  defineCustomElements(window);
});
const app = createApp(App);

app.directive('outer-click', OuterClick);
app.use(router);
app.use(i18n);
app.use(store);
app.mount('#app');

export default app;
