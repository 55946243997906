import type { Module } from 'vuex';
import { pageSize } from '@/common/constants/getVerifiedConstants';
import { actions } from './actions';
import mutations from './mutations';
import { getters } from './getters';

import type { getVCParamsInterface, VCInterface, countryListInterface, regionListInterface } from './IGetVerified';
import type { ReportsFilterInterface, ReportResponseInterface } from './GetInsights';
import type { vcIssuingStatus } from '@/common/interfaces/verify/IGetVerified';
import type { ApiUsageResponseInterface } from '@/common/interfaces/verify/GetInsights';
import type { certificateInterface } from './IMyCertificates';

export interface IGetVerifiedState {
  VCFilterParams: getVCParamsInterface
  VCDetails: VCInterface
  fetchVCDetailsInProgress: boolean
  vcRequestInProgress: boolean
  appendVCs: boolean
  countries: countryListInterface[]
  regions: regionListInterface[]
  bulkIssuingStatuses: vcIssuingStatus[]
  reportListFilterParams: ReportsFilterInterface
  reportsListDetails: ReportResponseInterface
  fetchReportListInProgress: boolean
  reportListRequestInProgress: boolean
  apiUsageLimitData: ApiUsageResponseInterface
  fetchApiUsageInProgress: boolean
  certificateList: certificateInterface[],
  fetchCertificateListInProgress: boolean,
}

const vcDefaultFilterParams = () => {
  return {
    filterParam: '',
    page: { pageStart: 0, pageSize },
    searchKeyword: '',
    vcType: '',
  };
};

const reportListDefaultFilterParams = () => {
  return {
    sort: '',
    page: 1,
    pageSize: 20,
    find: '',
  };
};

// initial state
const getDefaultState = () => {
  return {
    VCFilterParams: vcDefaultFilterParams(),
    VCDetails: {
      content: [
        {
          legalPersonVCs: [],
          serviceOfferingVCs: [],
          physicalResourceVCs: [],
          softwareResourceVCs: [],
          dataResourceVCs: [],
        },
      ],
    },
    fetchVCDetailsInProgress: false,
    vcRequestInProgress: false,
    appendVCs: false,
    countries: [],
    regions: [],
    bulkIssuingStatuses: [],
    reportListFilterParams: reportListDefaultFilterParams(),
    reportsListDetails: {
      reportList: [],
      pageNumber: 0,
      totalPages: 0,
      totalElements: 0,
    },
    fetchReportListInProgress: false,
    reportListRequestInProgress: false,
    apiUsageLimitData: {},
    fetchApiUsageInProgress: false,
    certificateList: [],
    fetchCertificateListInProgress: false,
  };
};
const INITIAL_STATE = getDefaultState();

// get verified store
const GetVerifiedStore: Module<IGetVerifiedState, {}> = {
  namespaced: true,
  state: INITIAL_STATE,
  actions,
  mutations,
  getters,
};

export default GetVerifiedStore;
