import type { ActionTree } from 'vuex';
import { SEND_REQUEST_FOR_CONSULTATION } from './action-types';
import { ADD_ERROR_NOTIFICATION } from '@/components/NotificationBar/store/mutation-types';
import CommonServices from '@/common/services/CommonServices';

export const actions: ActionTree<{}, {}> = {
 [SEND_REQUEST_FOR_CONSULTATION] ({ commit }, payload: {
    product: string
    description: string
  }) {
    return new Promise((resolve, reject) => {
    CommonServices.sendConsultationRequestData(payload)
      .then(res => {
        const { status } = res;
        if (status === 200 || status === 201) {
          resolve('success');
        } else {
          commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
          reject(new Error('error'));
        }
      })
      .catch(({ response }) => {
        commit(ADD_ERROR_NOTIFICATION, response?.data || 'genericError', { root: true });
        reject(new Error('error'));
      });
    });
  },
};
