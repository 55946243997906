import { createStore } from 'vuex';

import auth from './modules/auth';
import LoadingState from './modules/loading';
import UserProfileStore from './modules/user';
import OrgProfileStore from './modules/org';
import manageOrgOnboardingState from '@/modules/onboarding/store';
import notificationsState from '@/components/NotificationBar/store';
import ConsultationStore from '@/components/AppConsultationModal/store';
import DataspaceStore from '@/modules/dataspaces/stores';
import LivingLabStore from '@/modules/build/store';
import NotificationStore from '@/modules/notifications/stores';
import GetVerifiedStore from '@/modules/verify/store';
import ContractDetailsStore from '@/modules/workbench/incoming-contracts/store';

export default createStore({
  modules: {
    auth,
    LoadingState,
    UserProfileStore,
    OrgProfileStore,
    manageOrgOnboardingState,
    notificationsState,
    ConsultationStore,
    DataspaceStore,
    LivingLabStore,
    NotificationStore,
    GetVerifiedStore,
    ContractDetailsStore,
  },
});
