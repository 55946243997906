<template>
  <div
    v-if="helpWidgetState !== 'hidden'"
    class="t-help-widget-container t-flex t-flex-row-reverse t-flex-justify-content-flex-start t-flex-align-items-flex-end"
    :class="[
      `t-help-${helpWidgetState}`,
      {'t-flex-align-items-center': helpWidgetState === 'hovered'}
    ]"
  >
    <scale-button
      variant="primary"
      icon-only
      class="t-help-widget-button t-flexShrink0"
      @click="handleClick"
      @touchend.prevent="handleClick"
      @mouseenter="handleHover"
      @mouseleave="handleHover"
    >
      <scale-icon-communication-message
        v-if="helpWidgetState.match('idle|hovered')"
        :size="24"
        :accessibility-title="$t('helpWidget.askSupport')"
        selected
      />
      <scale-icon-action-close
        v-else-if="helpWidgetState === 'opened'"
        accessibility-title="close"
        :size="24"
      />
    </scale-button>
    <AskSupport v-if="helpWidgetState === 'hovered'" />
    <Chatbox
      v-else-if="helpWidgetState === 'opened'"
      @close-chat="closeChat"
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import AskSupport from './AskSupport.vue';
import Chatbox from './Chatbox/Chatbox.vue';

const helpWidgetState = ref<'hidden' | 'idle' | 'hovered' | 'opened'>('hidden');

const handleHover = (event: MouseEvent) => {
  if (event.type === 'mouseenter' && helpWidgetState.value === 'idle') {
    helpWidgetState.value = 'hovered';
  } else if (event.type === 'mouseleave' && helpWidgetState.value === 'hovered') {
    helpWidgetState.value = 'idle';
  }
};

const openChat = () => { helpWidgetState.value = 'opened'; };

const closeChat = () => { helpWidgetState.value = 'idle'; };

const handleClick = () => {
  if (helpWidgetState.value === 'idle' || helpWidgetState.value === 'hovered') {
    openChat();
  } else if (helpWidgetState.value === 'opened') {
    closeChat();
  }
};

onMounted(() => {
  setTimeout(() => {
    helpWidgetState.value = 'idle';
  }, 5000);
});
</script>

<style scoped lang="scss">
@import '@/styles/utilities/variables';
@import '@/styles/variables/spacing';
@import '@/styles/global/utilities/mixins';

.t-help-widget-container {
  position: fixed;
  bottom: $space-10;
  right: $space-10;
  pointer-events: none;
  z-index: var(--scl-z-index-30);
  .t-help-widget-button {
    --radius: var(--telekom-radius-circle);
    --width: var(--telekom-spacing-unit-x14);
    --min-height: var(--telekom-spacing-unit-x14);
    pointer-events: auto;
  }
  &.t-help-hovered .t-help-widget-button::after {
    content: ' ';
    display: block;
    position: absolute;
    top: -3px;
    right: -3px;
    border-radius: var(--telekom-radius-circle);
    background: var(--telekom-color-primary-standard);
    width: $space-5;
    height: $space-5;
    border: $space-1 solid var(--telekom-color-text-and-icon-white-standard);
  }
}

@media screen and (max-width: 450px) {
  .t-help-widget-container {
    max-width: calc(100% - $t-sidebar-width-collapse);
    flex-direction: column-reverse;
    right: $space-5;
  }
}
</style>
