import type { GetterTree } from 'vuex';
import {
  GET_IS_PROGRESS,
  GET_FORM_STEPS,
  GET_ACTIVE_STEP,
  GET_IS_SUBMIT_INPROGRESS,
  GET_IS_REQUEST_SUCCESS,
  GET_COUNTRIES,
  GET_ORG_PRE_FILL_DATA,
  GET_ORG_INFO,
  GET_READONLY_ORG_INFO,
} from './getter-types';
import type {
  manageOnboardingInt,
} from './OrgOnboardingInt';

export const getters: GetterTree<manageOnboardingInt, {}> = {
  [GET_IS_PROGRESS]: (state) => state.isSubmitInprogress,
  [GET_FORM_STEPS]: (state) => state.steps,
  [GET_ACTIVE_STEP]: (state) => state.activeStep,
  [GET_IS_SUBMIT_INPROGRESS]: (state) => state.isSubmitInprogress,
  [GET_IS_REQUEST_SUCCESS]: (state) => state.isReqSuccess,
  [GET_COUNTRIES]: (state) => state.countries,
  [GET_ORG_PRE_FILL_DATA]: (state) => state.organisationPreFilledData,
  [GET_ORG_INFO]: (state) => state.organizationInfo,
  [GET_READONLY_ORG_INFO]: (state) => state.readOnlyOrgInfo,
};
