import type { RouteRecordRaw } from 'vue-router';
import { ProtectedRoutes } from '../IRoute';

import { Permission } from '@/common/enums/Permission';

const manageRoutes: RouteRecordRaw[] = [{
  path: '/manage',
  component: () => import('@/layout/AbstractRouteView.vue'),
  meta: {
    isProtected: true,
  },
  children: [{
    path: ProtectedRoutes.manage.externalConnection.path,
    name: ProtectedRoutes.manage.externalConnection.pathName,
    component: () => import('@/modules/manage/external-connections/page/ExternalConnections.vue'),
    meta: {
      isProtected: true,
      permission: Permission.VIEW_EXTERNAL_CONNECTIONS,
      navigation: [{
        title: 'Manage',
        translationKey: 'sideMenu.manage',
        routeName: '',
      }, {
        title: 'externalConnection',
        translationKey: 'sideMenu.externalConnectionManager',
        routeName: ProtectedRoutes.manage.externalConnection.pathName,
      }],
    },
  }, {
    path: `${ProtectedRoutes.manage.requestConnection.path}/:type?/:id?`,
    name: ProtectedRoutes.manage.requestConnection.pathName,
    component: () => import('@/modules/manage/external-connections/page/RequestConnection.vue'),
    meta: {
      isProtected: true,
      permission: Permission.MANAGE_EXTERNAL_CONNECTIONS,
      navigation: [{
        title: 'Manage',
        translationKey: 'sideMenu.manage',
        routeName: '',
      }, {
        title: 'externalConnection',
        translationKey: 'sideMenu.externalConnectionManager',
        routeName: ProtectedRoutes.manage.externalConnection.pathName,
      }],
    },
  }],
}];

export default manageRoutes;
