import type { ActionTree } from 'vuex';
import saveFile from 'save-as-file';
import i18n from '@/i18n';
import {
  FETCH_COUNTRIES,
  FETCH_REGIONS,
  SEND_VC_REQUEST,
  SEND_BULK_VC_REQUEST,
  FETCH_VC_REQUEST,
  REVOKE_VC_REQUEST,
  FETCH_REPORT_LIST,
  DOWNLOAD_REPORTS,
  FETCH_API_USAGE_LIMIT,
  FETCH_SUBSCRIPTION_DATA,
  DOWNLOAD_VP,
  DELETE_INTEGRATION,
  DOWNLOAD_INTEGRATION,
  FETCH_CERTIFICATE_LIST,
} from './action-types';
import {
  SET_COUNTRIES,
  SET_REGIONS,
  SET_VC_DETAILS,
  SET_FETCH_VC_DETAILS_IN_PROGRESS,
  SET_VC_REQUEST_IN_PROGRESS,
  SET_BULK_ISSUING_STATUSES,
  SET_REPORT_LIST_DETAILS,
  SET_FETCH_REPORT_LIST_IN_PROGRESS,
  SET_API_USAGE_LIMIT_DATA,
  SET_FETCH_API_USAGE_IN_PROGRESS,
  SET_CERTIFICATE_LIST,
  SET_FETCH_CERTIFICATE_LIST_IN_PROGRESS,
} from './mutation-types';
import GetVerifiedService from '@/common/services/GetVerifiedServices';
import GetInsightsServices from '@/common/services/GetInsightsServices';
import myCertificatesServices from '@/common/services/MyCertificatesServices';
import {
  ADD_ERROR_NOTIFICATION,
  ADD_SUCCESS_NOTIFICATION,
} from '@/components/NotificationBar/store/mutation-types';
import type { credentialsInterface } from './IGetVerified';
import type {
  IGetVerifiedState,
} from './index';
import { enumCredentialStatus } from '@/common/constants/getVerifiedConstants';
import { AxiosError } from 'axios';
import Util from '@/common/util';

function findExpStatus (credential: credentialsInterface) {
  const expirationDate = new Date(credential?.verifiableCredential?.expirationDate);
  return credential?.status?.toLowerCase() !== enumCredentialStatus.REVOKED
    ? expirationDate >= new Date()
      ? enumCredentialStatus.VALID
      : enumCredentialStatus.EXPIRED
    : enumCredentialStatus.REVOKED;
}

function updateVCExpStatus (responseData: Record<string, credentialsInterface[]>) {
  Object.keys(responseData).forEach((type) => {
    responseData[type]?.map((item) => {
      item.exp_status = findExpStatus(item);
      return item;
    });
  });
}

export function errorMessagesList (errors: { code: string, message: string }[]) {
  const errorMessages: string[] = [];
  errors.forEach((error: { code: string, message: string }) => errorMessages.push(error.message));
  return errorMessages;
}

export const actions: ActionTree<IGetVerifiedState, {}> = {
  [FETCH_COUNTRIES] ({ commit }, language: string) {
    return new Promise((resolve, reject) => {
      GetVerifiedService.getAllCountries(language)
        .then((res) => {
          const { data } = res;
          commit(SET_COUNTRIES, data);
          resolve('success');
        })
        .catch(() => {
          commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
          reject(new Error('error'));
        });
    });
  },
 
  [FETCH_REGIONS] ({ commit }, country: string) {
    return new Promise((resolve, reject) => {
      GetVerifiedService.getRegions(country)
        .then((res) => {
          const { data } = res;
          commit(SET_REGIONS, data);
          resolve('success');
        })
        .catch(() => {
          commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
          reject(new Error('error'));
        });
    });
  },

  async [SEND_VC_REQUEST] ({ commit }, payload) {
    commit(SET_VC_REQUEST_IN_PROGRESS, true);
    try {
      await GetVerifiedService.createVCRequest(payload);
      commit(ADD_SUCCESS_NOTIFICATION, 'Successfully signed VC', { root: true });
      return 'success';
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.data?.errors) {
        const errorsList = errorMessagesList(error.response.data.errors);
        commit(
          ADD_ERROR_NOTIFICATION,
          { text: errorsList.length > 1 ? i18n.global.t('common.errors') : i18n.global.t('common.error'), list: errorsList },
          { root: true }
        );
      } else {
        commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
      }
      return 'error';
    } finally {
      commit(SET_VC_REQUEST_IN_PROGRESS, false);
    }
  },

  async [FETCH_VC_REQUEST] ({ commit, state }) {
    commit(SET_FETCH_VC_DETAILS_IN_PROGRESS, true);

    const {
      VCFilterParams: { filterParam, page: { pageStart, pageSize }, searchKeyword, vcType },
    } = state;

    try {
      const response = await GetVerifiedService.fetchVCRequest(
        filterParam, pageStart, pageSize, searchKeyword, vcType,
      );
      const { status, data } = response;
      if (status === 200) {
        if (data?.content?.length > 0 && Object.keys(data.content[0]).length > 0) {
          updateVCExpStatus(data.content[0]);
          commit(SET_VC_DETAILS, data);
          return data;
        }
        commit(SET_VC_DETAILS, data);
        return 'no results';
      } else throw new Error('error');
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.data?.errors) {
        const errorsList = errorMessagesList(error.response.data.errors);
        commit(
          ADD_ERROR_NOTIFICATION,
          { text: errorsList.length > 1 ? i18n.global.t('common.errors') : i18n.global.t('common.error'), list: errorsList },
          { root: true }
        );
      } else {
        commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
      }
    } finally {
      commit(SET_FETCH_VC_DETAILS_IN_PROGRESS, false);
    }
  },

  async [REVOKE_VC_REQUEST] ({ commit, state, dispatch }, selectedVCs) {
    commit(SET_FETCH_VC_DETAILS_IN_PROGRESS, true);
    const credentialsData = state?.VCDetails?.content[0];
    const payload = Object.values(credentialsData)
      .reduce((credentials, type) => credentials.concat(type), [])
      .filter((el: credentialsInterface) => selectedVCs?.includes(el.id))
      .map((el: credentialsInterface) => {
        const credentialStatus = el?.verifiableCredential?.credentialStatus;
        credentialStatus.statusListCredential = `${Util.getRestApiUrl('credentials/status/revocation', 'digital-id-revoke')}`;
        return { credentialStatus };
      });
    await GetVerifiedService.revokeVCRequest(payload)
      .then(() => {
        commit(ADD_SUCCESS_NOTIFICATION, 'Successfully revoked VCs', { root: true });
      })
      .catch(() => {
        commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
      });
  },

  async [SEND_BULK_VC_REQUEST] ({ commit }, payload) {
    try {
      await GetVerifiedService.createVCRequest(payload);
      commit(SET_BULK_ISSUING_STATUSES, {
        legalName: payload.legalPersonVCCreationRequest.participantDetails.name,
        status: 'Success',
        errors: '',
      });
      return 'success';
    } catch (error) {
      let errorMessages;
      if (error instanceof AxiosError && error?.response?.data?.errors) {
        errorMessages = errorMessagesList(error.response.data.errors);
      } else {
        errorMessages = [i18n.global.t('genericError')];
      }
      commit(SET_BULK_ISSUING_STATUSES, {
        legalName: payload.legalPersonVCCreationRequest.participantDetails.name,
        status: 'Failed',
        errors: errorMessages,
      });
    }
  },

  async [FETCH_REPORT_LIST] ({ commit, state, dispatch }) {
    commit(SET_FETCH_REPORT_LIST_IN_PROGRESS, true);

    const {
      reportListFilterParams: { startDate, endDate, page, pageSize, find, sort },
    } = state;

    try {
      const response = await GetInsightsServices.fetchReportsRequest(
        startDate, endDate, page, pageSize, find, sort,
      );
      const { status, data } = response;

      if (status === 200) {
        if (data?.content?.length > 0) {
          commit(SET_REPORT_LIST_DETAILS, data);
          return data;
        }
        commit(SET_REPORT_LIST_DETAILS, data);
        return 'no results';
      } else throw new Error('error');
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.data?.message) {
        const errorMessages = error?.response?.data?.message;
        commit(ADD_ERROR_NOTIFICATION, { text: 'Errors', body: errorMessages }, { root: true });
      } else {
        commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
      }
    } finally {
      commit(SET_FETCH_REPORT_LIST_IN_PROGRESS, false);
    }
  },

  async [DOWNLOAD_REPORTS] ({ commit, dispatch }, payload: string[]) {
    try {
      const res = await GetInsightsServices.downloadReport(payload);
      const data = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
      if (payload.length > 1) {
        saveFile(data, 'validationReports.zip');
      } else {
        saveFile(data, payload[0]);
      }
      commit(ADD_SUCCESS_NOTIFICATION, i18n.global.t('verify.getInsights.successDownload'), { root: true });
    } catch {
      commit(ADD_ERROR_NOTIFICATION, i18n.global.t('verify.getInsights.errorDownload', { filename: payload }), { root: true });
    }
  },
  
  async [FETCH_API_USAGE_LIMIT] ({ commit, state }) {
    commit(SET_FETCH_API_USAGE_IN_PROGRESS, true);

    try {
      const response = await GetInsightsServices.fetchApiUsageLimit();
      const { data } = response;
      commit(SET_API_USAGE_LIMIT_DATA, data);
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.data?.message) {
        const errorMessages = error?.response?.data?.message;
        commit(ADD_ERROR_NOTIFICATION, { text: 'Errors', body: errorMessages }, { root: true });
      } else {
        commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
      }
    } finally {
      commit(SET_FETCH_API_USAGE_IN_PROGRESS, false);
    }
  },

  async [FETCH_SUBSCRIPTION_DATA] ({ commit }, orgId: string) {
    try {
      const response = await GetVerifiedService.fetchSubscriptionData(orgId);
      return response?.data;
    } catch (error) {
      throw new Error('error');
    }
  },

  async [DOWNLOAD_VP] ({ commit }, complianceId: string) {
    try {
      const response = await GetVerifiedService.downloadVerifiablePresentation(complianceId);
      commit(ADD_SUCCESS_NOTIFICATION, i18n.global.t('verify.downloadVCMessage'), { root: true });
      return response?.data;
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.data?.message) {
        const errorMessages = error?.response?.data?.message;
        commit(ADD_ERROR_NOTIFICATION, { text: 'Errors', body: errorMessages }, { root: true });
      } else {
        commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
      }
    }
  },

  async [DELETE_INTEGRATION] ({ commit }, clientId: string) {
    try {
      const response = await GetInsightsServices.deleteIntegration(clientId);
      commit(ADD_SUCCESS_NOTIFICATION, `${clientId} - ` + i18n.global.t('verify.addIntegration.deleteSuccess'), { root: true });
      return 'success';
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.data?.errors) {
        const errorsList = errorMessagesList(error.response.data.errors);
        commit(
          ADD_ERROR_NOTIFICATION,
          { text: errorsList.length > 1 ? i18n.global.t('common.errors') : i18n.global.t('common.error'), list: errorsList },
          { root: true }
        );
      } else {
        commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
      }
      return 'error';
    }
  },

  async [DOWNLOAD_INTEGRATION] ({ commit }, clientId: string) {
    try {
      const response = await GetInsightsServices.downloadIntegration(clientId);
      commit(ADD_SUCCESS_NOTIFICATION, `${clientId} - ` + i18n.global.t('verify.addIntegration.downloadSuccess'), { root: true });
      return response?.data;
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.data?.errors) {
        const errorsList = errorMessagesList(error.response.data.errors);
        commit(
          ADD_ERROR_NOTIFICATION,
          { text: errorsList.length > 1 ? i18n.global.t('common.errors') : i18n.global.t('common.error'), list: errorsList },
          { root: true }
        );
      } else {
        commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
      }
    }
  },

  async [FETCH_CERTIFICATE_LIST] ({ commit, state }, payload) {
    commit(SET_FETCH_CERTIFICATE_LIST_IN_PROGRESS, true);

    try {
      const response = await myCertificatesServices.fetchCertificatesList(payload);
      const { status, data } = response;
      if (status === 200) {
        if (data.certificates.length > 0) {
          commit(SET_CERTIFICATE_LIST, data.certificates);
          return data.certificates;
        } else {
          commit(SET_CERTIFICATE_LIST, []);
          return [];
        }
      } 
    } catch (error) {
      if (error instanceof AxiosError && error?.response?.data?.errors) {
        const errorsList = errorMessagesList(error.response.data.errors);
        commit(
          ADD_ERROR_NOTIFICATION,
          { text: errorsList.length > 1 ? i18n.global.t('common.errors') : i18n.global.t('common.error'), list: errorsList },
          { root: true }
        );
      } else {
        commit(ADD_ERROR_NOTIFICATION, 'genericError', { root: true });
      }
      return 'error';
    } finally {
      commit(SET_FETCH_CERTIFICATE_LIST_IN_PROGRESS, false);
    }
  },
};
