<template>
  <transition-group
    tag="div"
    name="t-notification"
    class="t-notification-bar"
    @dismiss="removeNotificationItem"
  >
    <div key="t-notification-bar">
      <slot />
    </div>
  </transition-group>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { INotification } from '../INotification';

const DEFAULT_LIST_LIMIT = 3;

export default defineComponent({
  props: {
    modelValue: {
      type: Array as () => INotification[] | any,
      required: false,
    },
    limit: {
      type: Number,
      default: DEFAULT_LIST_LIMIT,
    },
  },
  emits: ['update:modelValue'],
  watch: {
    value () {
      this.checkLimit();
    },
  },
  methods: {
    removeNotificationItem (notification: INotification) {
      this.updateNotifications(this.modelValue?.filter((item: any) => item !== notification));
    },
    checkLimit () {
      if (this.modelValue.length > this.limit) {
        this.updateNotifications(this.modelValue.slice(0, this.limit));
      }
    },
    updateNotifications (notifications: INotification[]) {
      this.$emit('update:modelValue', notifications);
    },
  },
});
</script>

<style lang="scss" scoped>
  // $list-transition-duration: $transition-default;
  $notification-bar-margin-top: 60px !default; // navbar height + 14px;
  .t-notification-bar {
    position: fixed;
    z-index: 500;
    top: $notification-bar-margin-top;
    right: var(--scl-spacing-24);

    > div {
      display: flex;
      flex-direction: column;
      gap: var(--scl-spacing-12);
    }

    &:deep() {
      .notification-toast {
        top: auto;
      }
    }
  }
</style>
