import Keycloak, {
  type KeycloakLoginOptions,
  type KeycloakPromise,
} from 'keycloak-js';
import { DEFAULT_KEYCLOAK_REALM, KEYCLOAK_LOCAL_STORAGE_KEY } from '@/common/constants';
import type { IKeycloakRealm, IToken } from '@/common/interfaces/auth/IToken';
import type Permission from '@/common/enums/Permission';
import type { IKeycloakProfile } from '@/common/interfaces/auth/IKeycloakProfile';
import Translation from '@/i18n/translation';

const KEYCLOAK_CLIENT_ID = import.meta.env.VITE_APP_KEYCLOAK_CLIENT_ID;
const MINIMAL_TOKEN_VALIDITY = 10; // min token validity in seconds
const TOKEN_EXPIRY_SECONDS = 300;
const SESSION_EXPIRY_SECONDS = 2700;

type CallbackOneParam<T1 = void, T2 = void> = (param1: T1) => T2;

interface KeycloakReturnUser {
  email: string
  firstName: string
  lastName: string
}

export default class AuthService {
  static keycloakAuth = AuthService.getKeycloakInstance();

  static callLogin (onAuthenticatedCallback: CallbackOneParam): void {
    AuthService.keycloakInit().then(function (authenticated) {
      authenticated ? onAuthenticatedCallback() : alert('non authenticated');
    })
      .catch((e) => {
        console.dir(e);
        console.log('keycloak init exception: ', e);
      });
  }

  /**
   * get keycloak instance
   * @param {string} realm name
   * @returns {Keycloak.KeycloakInstance} object with realm settings
   */
  static getKeycloakInstance (realmName?: string): Keycloak {
    return new Keycloak({
      url: import.meta.env.VITE_APP_KEYCLOAK_URL,
      realm: DEFAULT_KEYCLOAK_REALM ?? realmName!,
      clientId: KEYCLOAK_CLIENT_ID as string,
    });
  }

  /**
   * get keycloak ream from local storage
   * @returns {IKeycloakRealm} object with realm settings
   */
  static getKeycloakRealm (): IKeycloakRealm {
    let keycloakRealm: IKeycloakRealm = {};
    try {
      keycloakRealm = JSON.parse(localStorage.getItem(KEYCLOAK_LOCAL_STORAGE_KEY)!);
    } catch (_e) {
      return keycloakRealm;
    }
    return keycloakRealm instanceof Object ? keycloakRealm : {};
  }

  /**
   * set keycloak ream to local storage
   * @param {IKeycloakRealm} url image url part
   */
  static setKeycloakRealm (keycloakRealm: IKeycloakRealm) {
    localStorage.setItem(KEYCLOAK_LOCAL_STORAGE_KEY, JSON.stringify(keycloakRealm));
  }

  /**
   * Keycloak Init
   * will authenticate the client if the user is logged-in to Keycloak or display the login page if not
   */
  static keycloakInit (): KeycloakPromise<boolean, object> {
    return AuthService.keycloakAuth.init({
      onLoad: 'login-required',
      locale: Translation.currentLocale,
    });
  }

  /**
   * Keycloak login
   * Redirects to login page
   */
  static keycloakLogin (options: KeycloakLoginOptions = {}): KeycloakPromise<void, void> {
    return AuthService.keycloakAuth.login(options);
  }

  /**
   * Gets user keycloak account
   */
  static getKeycloakProfile () {
    // need to extend KeycloakProfile model since it doesn't include `attributes` field
    return AuthService.keycloakAuth.loadUserProfile() as KeycloakPromise<IKeycloakProfile, void>;
  }

  /**
   * Gets realm access roles of user
   */
  static getRealmUserRoles (): string[] {
    return (AuthService.keycloakAuth.realmAccess != null) ? AuthService.keycloakAuth.realmAccess.roles : [];
  }

  /**
   * Gets resource access roles of user
   */
  static getResourceUserRoles (): Permission[] {
    const { resourceAccess } = AuthService.keycloakAuth;
    if (resourceAccess == null) {
      return [];
    }
    const roleGroupNames = Object.keys(resourceAccess);
    return roleGroupNames.reduce<Permission[]>((rolesArray, groupName) => (
      [...rolesArray, ...resourceAccess[groupName].roles as Permission[]]
    ), []);
  }

  /**
   * Keycloak Logout
   */
  static keycloakLogout (): void {
    void AuthService.keycloakAuth.logout();
  }

  /**
   * Keycloak Refresh
   */
  static keycloakRefreshToken () {
    return AuthService.keycloakAuth.updateToken(MINIMAL_TOKEN_VALIDITY);
  }

  /**
   * Set onTokenExpired callback
   */
  static setKeycloakOnTokenExpired (onTokenExpired: () => void) {
    AuthService.keycloakAuth.onTokenExpired = onTokenExpired;
  }

  /**
   * Get new session expiry time
   */
  static getNewSessionExpiryTime () {
    return Date.now() + (SESSION_EXPIRY_SECONDS * 1000);
  }

  /**
   * Provide token info
   */
  static getToken (): IToken {
    return {
      expiresIn: TOKEN_EXPIRY_SECONDS,
      accessToken: this.keycloakAuth.token,
      refreshToken: this.keycloakAuth.refreshToken,
      idToken: this.keycloakAuth.idToken,
      keycloakId: this.keycloakAuth.subject,
      keycloakLocale: this.keycloakAuth.tokenParsed?.locale,
    };
  }

  static extractKeycloakUser (): KeycloakReturnUser {
    return {
      email: AuthService.keycloakAuth.tokenParsed.email,
      firstName: AuthService.keycloakAuth.tokenParsed.given_name,
      lastName: AuthService.keycloakAuth.tokenParsed.family_name,
    };
  }
}
