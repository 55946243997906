import axios, { type AxiosResponse } from 'axios';
import Util from '../util';
import type { IIncomingDataAgreement } from '../interfaces/workbench/IIncomingDataAgreement';
/**
 * Get incoming data agreements
 * @returns { Promise<IIncomingDataAgreement> }
 */
export async function getIncomingDataAgreements (): Promise<IIncomingDataAgreement[]> {
  try {
    const response: AxiosResponse = await axios.get(Util.getRestApiUrl('contract/incoming', 'dataspace'));
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch incoming data agreements');
  }
}

/**
* Download agreement's assets
* Returns URL for the assets to download
* @return { Promise<string> }
*/
export async function downloadAssetFromAgreements (payload: { contractId: string, assetId: string }): Promise<string> {
  try {
    const response: AxiosResponse = await axios.get(Util.getRestApiUrl('download/presigned', 'dataspace'), {
      params: {
        assetId: payload.assetId,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch incoming data agreements');
  }
}
