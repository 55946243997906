/**
 * This service is use for dataspaces call
 */

import axios, { type AxiosResponse } from 'axios';
import type { IntApplicationManagement, IntInitiateHealthTest, IntParticipantData } from '../interfaces/build/IntBuildDataspaces';
import type { IntConnectionRequest, IntDataspace } from '../interfaces/dataspace/IntDataspaces';
import Util from '../util';

const dataspaceService = {
  /**
   * Get all dataspaces
   * @returns Array
   */
  getAllDataspaces: () => {
    return axios.get(Util.getRestApiUrl('dataspace', 'dataspace'));
  },

  /**
   * Get all active invitations to dataspace
   */
  getActiveInvitations: (): Promise<{ status: number, data: IntDataspace[] }> => {
    return axios.get(Util.getRestApiUrl('dataspace/activeInvitations', 'dataspace'));
  },

  /**
   * Post decision for invitations
   */
  postInviteDecision: (payload: {
    dataspaceId: string
    status?: string
    isDismissed?: boolean
  }) => {
    return axios.post(Util.getRestApiUrl('lila/invitationDecision', 'dataspace'), payload);
  },

  /**
   * Join Dataspace
   * @params: Object
   */
  joinDataspace: (requestPayload: {
    // Changed the type from string[] to string as implementation has defined string type
    id: string
    payload: {
      message: string
    }
  }) => {
    const { payload, id } = requestPayload;
    return axios.post(Util.getRestApiUrl(`dataspace/${id}/apply`, 'dataspace'), payload);
  },

  withdrawRequestToJoinDataspace: (dataspaceId: string): Promise<{ status: number }> => {
    return axios.post(Util.getRestApiUrl(`lila/withdraw-invitation-request/${dataspaceId}`, 'dataspace'));
  },

  /**
   * Update request to join dataspace
   */
  updateRequestToJoinDataspace: (dataspaceId: string, payload: { requestDescription: string, isConsentGiven: boolean }): Promise<{ status: number }> => {
    return axios.put(Util.getRestApiUrl(`lila/dataspace-invitation-request/${dataspaceId}`, 'dataspace'), payload);
  },

  /**
   * Get connection request details
   */
  getConnectionRequestDetails: (dataspaceId: string): Promise<{ status: number, data: IntParticipantData }> => {
    return axios.get(Util.getRestApiUrl(`lila/dataspace-invitation-request/${dataspaceId}`, 'dataspace'));
  },

  /**
   * Get user's joined dataspace
   */

  myDataspaces: () => {
    return axios.get(Util.getRestApiUrl('my/dataspaces', 'dataspace'));
  },

  /**
   * Configure dataspace with connector certificate
   */
  configureDataspace: (requestPayload: {
    // Changed the type from string[] to string as implementation has defined string type
    id: string
    payload: {
      publicKey: {
        file: File
      }
      privateKey: {
        file: File
      }
    }
  }) => {
    const { id, payload } = requestPayload;
    const uploadData = new FormData();
    uploadData.append('publicKey', payload.publicKey.file);
    uploadData.append('privateKey', payload.privateKey.file);

    return axios.post(Util.getRestApiUrl(`my/dataspaces/${id}/uploadCertificate`, 'dataspace'), uploadData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  /**
   * Configure dataspace service
   */
  configureDataspaceService: (requestPayload: {
    myDataspaceId: string
    serviceId: string
    certificate: File
  }) => {
    const uploadData = new FormData();
    uploadData.append('certificate', requestPayload.certificate);
    return axios.post(Util.getRestApiUrl(`my/dataspaces/${requestPayload.myDataspaceId}/uploadCertificate`, 'dataspace'), uploadData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        serviceId: requestPayload.serviceId,
      },
    });
  },

  /**
   *
   * @param id Get the detail of dataspace
   * @returns Object
   */
  // Changed the type from string[] to string as implementation has defined string type
  getDataspaceDetails: (id: string) => {
    return axios.get(Util.getRestApiUrl(`dataspace/${id}`, 'dataspace'));
  },

  /**
   * Get the details of user dataspace
   * @return Object
   */
  // Changed the type from string[] to string as implementation has defined string type
  getMyDataspaceDetails: (id: string) => {
    return axios.get(Util.getRestApiUrl(`my/dataspaces/${id}`, 'dataspace'));
  },

  /**
   *  Check the status of deploying dataspace
   *  @params: Array of my dataspace ids
   */
  checkDSStatus: (payload: string[]) => {
    const reqPayload = {
      myDataspaceIds: payload,
    };
    return axios.post(Util.getRestApiUrl('my/dataspaces/status', 'dataspace'), reqPayload);
  },

  /**
   *  Initiate connector health test for dataspace
   *  @params: IntInitiateHealthTest, client
   */
  initiateHealthTest: (payload: IntInitiateHealthTest, client: string, signal: AbortSignal): Promise<AxiosResponse> => {
    return axios.post(Util.getRestApiUrl(client, 'dataspace-test-connector'), payload, { signal });
  },

  /**
   * Send invitation to join private dataspace
   * @param: Object
   */
  sendInvitationToJoinPrivateDataspace: (payload: {
    privateDataspaceId: string
    password: string
  }) => {
    return axios.post(Util.getRestApiUrl('dataspace/private/add', 'dataspace'), payload);
  },

  /**
   * Request connection for public lila dataspace
   * @param payload
   */
  sendRequestConnection: async (payload: IntConnectionRequest): Promise<AxiosResponse> => {
    const reqPayload = {
      requestDescription: payload.requestDescription,
      companyName: payload.companyName,
      address: payload.address,
      contactPerson: payload.contactPerson,
      email: payload.email,
    };
    try {
      const response: AxiosResponse = await axios.post(Util.getRestApiUrl(`lila/requestConnectionToLiLaDs/${payload.dataspaceId}`, 'dataspace'), reqPayload);
      return response;
    } catch (error) {
      throw new Error('Failed to send request');
    }
  },

  /*
  * Get list of application available in joined dataspaces
  * @returns { Promise<IntApplicationManagement[]> }
  */
  async getApplications (): Promise<IntApplicationManagement[]> {
    try {
      const response: AxiosResponse = await axios.get(Util.getRestApiUrl('myDataspaces/registeredApplications', 'dataspace'));
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch applications');
    }
  },
};

export default dataspaceService;
