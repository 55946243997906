import { useStore } from 'vuex';

import type { Permission } from '@/common/enums/Permission';

export function usePermission () {
  const store = useStore();

  const hasPermission = (permission: Permission): boolean => {
    return store.state.auth.permissions.includes(permission);
  };

  const checkPermissions = (permission: Permission | Permission[]): boolean => {
    if (Array.isArray(permission)) {
      return permission.some(perm => hasPermission(perm));
    }
    return hasPermission(permission);
  };

  return { hasPermission, checkPermissions };
}
