{
  "ariaLabel": {
    "viewDetails": "Ver detalles",
    "downloadDataAssets": "Descargar recursos de datos"
  },
  "topMenu": {
    "dihTitle": "Data Intelligence Hub",
    "DIHOrgRegistration": "Data Intelligence Hub: registro de la organización",
    "dihSubTitle": "Extraer valor de los datos de forma segura",
    "logout": "Cerrar sesión",
    "myAccount": "Mi cuenta",
    "myOrganization": "Mi organización"
  },
  "userLogoutAlert": {
    "title": "¿Seguro que quiere cerrar sesión?",
    "back": "Volver atrás",
    "logout": "Cerrar sesión",
    "myOrganization": "Mi organización"
  },
  "sideMenu": {
    "dashboard": "Cuadro de mandos",
    "connectors": "Conectores",
    "myConnectors": "Mis conectores",
    "dataspaces": "Espacios de datos",
    "discover": "Descubrir",
    "manage": "Gestionar",
    "marketplace": "Mercado",
    "myDataspaces": "Mis suscripciones",
    "browseDataspaces": "Explorar espacios de datos",
    "organizations": "Organizaciones",
    "build": "Crear",
    "ownedDataspaces": "Mis espacios de datos",
    "collapse": "Ocultar barra lateral",
    "participate": "Participar",
    "getVerified": "Ser verificado",
    "workbench": "Mesa de trabajo",
    "dataIncoming": "Datos recibidos",
    "applications": "Aplicaciones",
    "verify": "Verificar",
    "digitalIDDashboard": "Obtener información",
    "mySignatures": "Mis firmas",
    "externalConnectionManager": "Administrador de conexiones externas"
  },
  "dropdown": {
    "selectAll": "Seleccionar todo",
    "noOption": "No hay opciones disponibles",
    "noSuchOption": "No existe tal opción..."
  },
  "common": {
    "start": "Iniciar",
    "finish": "Finalizar",
    "firstName": "Nombre",
    "lastName": "Apellidos",
    "email": "Dirección de correo electrónico",
    "phoneNumber": "Número de teléfono",
    "contactPerson": "Persona de contacto",
    "welcome": "Bienvenida",
    "street": "Calle",
    "city": "Ciudad",
    "country": "País",
    "postCode": "Código postal",
    "number": "Número",
    "next": "Siguiente",
    "prev": "Volver atrás",
    "alreadyAccount": "¿Ya tiene una cuenta?",
    "login": "Iniciar sesión",
    "loginNow": "Iniciar sesión ahora",
    "register": "Registrarse",
    "home": "Inicio",
    "submit": "Enviar",
    "attention": "Atención",
    "desc": "Descripción",
    "website": "Página web",
    "contact": "Contacto",
    "details": "Detalles",
    "plan": "Plan",
    "listView": "Vista de lista",
    "gridView": "Vista de cuadrícula",
    "firstPage": "Ir a la primera página",
    "previousPage": "Ir a la página anterior",
    "nextPage": "Ir a la página siguiente",
    "lastPage": "Ir a la última página",
    "download": "Descargar",
    "delete": "Eliminar",
    "edit": "Editar",
    "information": "Información",
    "organization": "Organización",
    "cancel": "Cancelar",
    "save": "Guardar",
    "upload": "Cargar",
    "link": "Enlace",
    "view": "Ver",
    "search": "Buscar",
    "searchTerm": "Buscar",
    "selectOption": "Seleccionar una opción",
    "sortBy": "Ordenar por",
    "dataspace": "Espacio de datos",
    "select": "Seleccionar",
    "date": "Fecha",
    "title": "Título",
    "successful": "Éxito",
    "unsuccessful": "Sin éxito",
    "statuses": {
      "connected": "conectado",
      "disconnected": "desconectado",
      "deploying": "desplegando",
      "deployed": "desplegado",
      "failed": "fallido",
      "available": "disponible",
      "success": "éxito",
      "active": "activo",
      "pending": "pendiente",
      "declined": "rechazado",
      "invited": "invitado",
      "onboarding": "incorporación",
      "withdrawn": "retirado",
      "successful": "exitoso",
      "unsuccessful": "sin éxito",
      "valid": "válido",
      "expired": "expirado",
      "revoked": "revocado"
    },
    "andMore": "y {count} más",
    "confirm": "Confirmar",
    "no": "No",
    "links": {
      "dihWiki": "https://docs.dih.telekom.com/en/home"
    },
    "formError": "Algo anda mal.",
    "question": {
      "interested": "¿Le interesa?"
    },
    "moreInfo": "Si necesita más información:",
    "goToWebsite": "Ir a nuestra página web",
    "provider": "Proveedor",
    "required": "Obligatorio",
    "errors": "Errores",
    "error": "Error"
  },
  "genericError": "Se ha producido un error. Por favor, vuelva a intentarlo.",
  "validation": {
    "fieldRequired": "El campo {field} es obligatorio.",
    "fieldNumeric": "El campo sólo puede contener números.",
    "fieldAlpha": "El campo sólo puede contener letras, _ y -.",
    "fieldAlphanumeric": "El campo sólo puede contener letras, números, _ y -.",
    "fieldNumericDash": "El campo sólo puede contener números y -.",
    "fieldHouseNumber": "El campo {field} puede contener solo números, letras y los caracteres - y /.",
    "fieldPhoneNumber": "El campo sólo puede contener números y -. Debe comenzar con + o 00.",
    "fieldAlphaDash": "El campo sólo puede contener letras y -.",
    "exceptHTMLTagBrackets": "El campo no puede contener los siguientes caracteres: ^<>",
    "isMaxLength": "supera la longitud máxima de 10 caracteres.",
    "emailFormat": "La dirección de correo electrónico tiene un formato incorrecto",
    "fileSizeError": "El tamaño del archivo es superior a {size} MB.",
    "csvFileSizeError": "Este archivo es demasiado grande. Puede cargar archivos de hasta {size} MB.",
    "csvFileExtensionError": "Solo se permiten archivos con la extensión csv.",
    "csvFileIsEmptyError": "Este archivo está vacío. Debe cargar al menos {size} KB archivo.",
    "csvFileFormatError": "El archivo no coincide con el formato esperado. Utilice la plantilla proporcionada e inténtelo de nuevo.",
    "uploadFails": "Error de carga",
    "fileExtensionError": "La extensión del archivo no es apta",
    "fileIsMalwareInfectedError": "Este archivo parece contener malware. Por razones de seguridad, este archivo no se puede cargar. Por favor, vuelva a revisar su archivo",
    "customValidation": "El campo {field} tiene un formato incorrecto.",
    "selectCountryFirst": "Por favor, seleccione primero el nombre del país",
    "validationPostCodeCaseDE": "El campo tiene un formato incorrecto. Debe contener 5 dígitos; ej., 53113",
    "validationAlphanumericCase": "El campo sólo puede contener caracteres alfanuméricos, guiones y guiones bajos. El valor debe comenzar con un carácter alfanumérico.",
    "validationNumericDashCase": "El campo solo puede contener números y -.",
    "url": "Por favor, introduzca una URL válida",
    "maxInvitationSize": "Puedes invitar hasta {size} participantes a la vez",
    "password": {
      "passwordStrength": "Seguridad de la contraseña",
      "weak": "No segura",
      "average": "Intermedia",
      "strong": "Segura",
      "passwordValidationMoreChar": "{count} o más caracteres",
      "passwordUpperAndLowerChar": "Mayúsculas y minúsculas",
      "passwordAtLeastNumber": "Al menos un número",
      "passwordAtLeastSpecialChar": "Al menos un carácter especial (excepto <>&\"`[])",
      "passwordDiffFromPrev": "Difiere de sus contraseñas anteriores"
    }
  },
  "footer": {
    "tagLine": "Potenciemos un mayor rendimiento",
    "imprint": "Nota legal",
    "imprintShort": "Nota legal",
    "imprintLink": "https://dih.telekom.com/en/imprint",
    "contact": "Contacto",
    "contactLink": "https://dih.telekom.com/en/contact",
    "termsAndConditions": "Términos y condiciones",
    "privacy": "Política de privacidad",
    "privacyShort": "Política de priv.",
    "telekom": "T-Systems International GmbH. Todos los derechos reservados",
    "telekomShort": "T-Systems International GmbH",
    "note": "Telekom Data Intelligence Hub â€“ sólo para clientes empresariales",
    "brandedNote": "Con la tecnología de Telekom Data Intelligence Hub",
    "help": "Ayuda",
    "cookieSettings": "Ajustes de cookies",
    "cookieSettingsShort": "Cookies",
    "life": "La vida es para compartir"
  },
  "onboarding": {
    "welcome": "Le damos la bienvenida a Data Intelligence Hub",
    "welcomeDIH": "Le damos la bienvenida a Data Intelligence Hub",
    "informationForOrganization": "Información sobre su organización",
    "orgNameLabel": "Nombre de la organización",
    "orgUnitNameLabel": "Nombre de la unidad organizativa",
    "emailAddressLabel": "Dirección de correo electrónico",
    "countryNameLabel": "País",
    "bpnNumberLabel": "Número de socio comercial",
    "contactPersonInfo": "Persona de contacto",
    "fillInfoBelow": "Por favor, rellene la información que aparece a continuación.",
    "orgRegNameLabel": "Nombre registrado de la organización/unidad",
    "legalNameHint": "Por favor introduzca el nombre legal de su organización",
    "addressHeading": "Dirección de la sede principal de la organización",
    "PLZCode": "Código postal",
    "verifyEmail": "Verificar correo electrónico",
    "verifyEmailMessage": "Gracias por unirse a Data Intelligence Hub - su organización ya está registrada con nosotros. Por favor, verifique su correo electrónico",
    "taxId": "Número de IVA",
    "commerceRegisterNumber": "Número de registro comercial",
    "orgRepresentative": {
      "title": "Configure la cuenta del representante de su organización",
      "orgContactPerson": "Persona de contacto de la organización",
      "whatDefinesYou": "¿Qué le define mejor?",
      "agreeText": "Confirmo que he leído y que me atendré a {1} de Data Intelligence Hub",
      "confirmText": "Doy mi consentimiento para que mis datos personales sean almacenados y procesados de acuerdo con <a href='#'>la declaración de privacidad de datos</a>"
    },
    "success": "Organización registrada correctamente",
    "underReview": "Su organización está siendo verificada",
    "verified": "Ha verificado correctamente su organización",
    "steps": {
      "counter": {
        "step": "Paso",
        "of": "de"
      },
      "stepWizard": {
        "orgInfo": "Información sobre la organización",
        "selectConnection": "Seleccione su conexión",
        "configureConnection": "Configure su conexión",
        "selectPackage": "Seleccione su plan",
        "checkout": "Pagar"
      },
      "actionButton": {
        "back": "Volver atrás",
        "next": "Siguiente",
        "order": "Pedido con obligación de pago",
        "checkout": "Pagar",
        "selectMyPlan": "Seleccionar mi plan"
      },
      "orgStep": {
        "fields": {
          "orgInfoLabel": "Detalles de la organización",
          "dataSpaceAttributes": "Atributos adicionales",
          "orgName": "Nombre legal de la organización",
          "departmentName": "Nombre del departamento",
          "crnNumber": "Número de registro comercial",
          "taxId": "Número de IVA",
          "bpnNo": "Número de socio comercial",
          "addressInfo": "Dirección de facturación",
          "countryName": "País",
          "plzCode": "Código postal",
          "number": "Número",
          "city": "Ciudad",
          "street": "Calle",
          "contactPersonLabel": "Persona de contacto",
          "fullName": "Nombre completo",
          "firstName": "Nombre",
          "firstNamePlaceholder": "Introduzca su nombre",
          "lastName": "Apellidos",
          "lastNamePlaceholder": "Introduzca sus apellidos",
          "contactPerson": "Persona de contacto",
          "phoneNo": "Número de teléfono",
          "emailAdd": "Dirección de correo electrónico",
          "dataPrivacyCheck": "Doy mi consentimiento para que mis datos personales se almacenen y procesen de acuerdo con la {dataprivacystatement} y las {supplementaryDataPrivacyConditions}",
          "termsAndConditionsCheck": "Confirmo que he leído y cumpliré los {termsandcondition} de Data Intelligence Hub",
          "dataprivacystatement": "Declaración de privacidad de datos",
          "supplementaryDataPrivacyConditions": "Condiciones de Privacidad de Datos complementarias",
          "termsandcondition": "Términos y condiciones",
          "agreeToEnterContract": "Acepto firmar un contrato con T-Systems International GmbH basado en la {serviceDescription} incluida más arriba",
          "serviceDescription": "descripción de los servicios"
        }
      },
      "connection": {
        "sectionHeading": "Seleccione su conexión",
        "sectionSubHeading": "Por favor seleccione el tipo de conexión para {dsName}",
        "textNote": "Sólo pueden seleccionarse las opciones compatibles con Catena-X",
        "recommendedConfiguration": "Configuración recomendada",
        "cloudAndDataPlan": "Plan de nube y datos"
      },
      "packages": {
        "sectionHeading": "Seleccione su plan",
        "sectionSubHeading": "Variaciones del plan de datos",
        "basedOnYourSelection": "En función de su selección, le recomendamos lo siguiente",
        "dataPackage": "Paquete de datos",
        "outgoingTraffic": "Tráfico saliente",
        "storage": "Almacenamiento",
        "selectedDataPlan": "Seleccionar plan de datos",
        "dataPlanDetails": "Detalles del plan de datos",
        "dataPlanLabelForVolume": "Planes con volumen incluido",
        "dataPlanLabelFlexible": "Pago por uso",
        "packageAvailable": "Este paquete sólo está disponible con este plan",
        "chargesApply": "Se aplican cargos si el uso supera el límite de volumen",
        "additionalStorage": "Almacenamiento adicional",
        "additionalTraffic": "Tráfico saliente adicional"
      },
      "cloudProviders": {
        "cloudInfrastructure": "Seleccionar proveedor de servicios en la nube",
        "cloudAvailable": "Este paquete sólo está disponible con este proveedor de servicios en la nube"
      },
      "checkout": {
        "sectionHeading": "Su paquete de conexión a {orgName}",
        "setupCost": "Gastos de instalación",
        "monthlyCost": "Costes mensuales",
        "payg": "Pago por uso",
        "pipSheet": "Hoja de información del producto",
        "totalCost": "Coste total",
        "totalPayable": "Total a pagar",
        "dataPrivacyCheck": "Acepto pagar los cargos asociados a los servicios solicitados más arriba",
        "termsAndConditionsCheck": "Acepto la duración del contrato y las condiciones de cancelación",
        "termsandcondition": "Términos y condiciones",
        "EOMAmountPolicy": "Acepto que el importe total a pagar se calcule a final de mes",
        "generalAgreement": "Acepto firmar un contrato con T-Systems International GmbH basado en la descripción de los servicios incluida más arriba",
        "DPAAgreement": "Doy mi consentimiento al {dataprocessingagreement} (DPA)",
        "dataprocessingagreement": "Acuerdo de procesamiento de datos",
        "dataPackage": "Paquete de datos",
        "dataPlan": "Plan de datos",
        "outgoingTraffic": "Tráfico saliente incluido",
        "storagePrice": "Precio del almacenamiento",
        "perGigabyteForStorage": "/GB para almacenamiento",
        "perGigabyteForOutgoingTraffic": "/GB para tráfico saliente",
        "outgoingTrafficPrice": "Precio del tráfico saliente",
        "contractDuration": "Duración del contrato",
        "defaultContractDuration": "6 meses",
        "price": "Precio",
        "perGigabyte": "por GB",
        "monthlyCancelable": "cancelable mensualmente",
        "storage": "Almacenamiento incluido",
        "linksText": {
          "information": "Hoja de información",
          "service": "Descripción del servicio"
        },
        "plusCharges": "{plus} cargos por su consumo",
        "plus": "más",
        "basedOnConsumption": "basado en el consumo"
      },
      "cancelModal": {
        "confirmQuestion": "¿Está seguro de que quiere cancelar la conexión seleccionada?",
        "confirmYes": "Sí, cancelar",
        "confirmNo": "No, continuar"
      }
    },
    "statuses": {
      "complete": {
        "title": "Ya se ha registrado",
        "description": "El enlace de registro no se puede volver a utilizar.",
        "buttonText": "Ir a inicio"
      },
      "error": {
        "title": "Se ha producido un error",
        "description": "Por favor, reinicie el proceso",
        "buttonText": "Reiniciar"
      },
      "expired": {
        "title": "Lamentablemente su enlace ha caducado",
        "description": "Por favor reinicie el proceso desde el portal de Catena-X.",
        "buttonText": "Volver a Catena-X"
      },
      "success": {
        "title": "Gracias por registrarse",
        "description": "Estamos revisando los detalles de su organización. Recibirá más información por correo electrónico",
        "buttonText": "Ir a nuestro sitio web"
      },
      "invalid": {
        "title": "No autorizado",
        "description": "No tiene autorización para acceder a esta página.",
        "buttonText": "Ir a nuestro sitio web"
      },
      "dih-success": {
        "title": "Gracias por su registro",
        "description": "Estamos revisando los detalles sobre su organización. Recibirá más información por correo electrónico",
        "buttonText": "Ir a nuestro sitio web"
      },
      "orgNotFound": {
        "title": "No hemos podido encontrar su organización",
        "descriptionLine1": "Lo sentimos. Parece que su organización no está incorporada o que aún no ha solicitado una suscripción a los servicios de T-Systems.",
        "descriptionLine2": "Por favor, solicite una suscripción a los servicios de habilitación certificados y personalizados de T-Systems para Catena-X a través del {marketplace}.",
        "marketplace": "marketplace",
        "descriptionLine3": "Si cree que se trata de un error, por favor {contactOurSalesTeam} y estaremos encantados de ayudarle.",
        "contactOurSalesTeam": "Contacte con nuestro equipo de ventas",
        "exploreTheKnowledgeBase": "Explore la base de conocimientos",
        "readOurBlog": "Lea nuestro blog"
      }
    }
  },
  "dashboard": {
    "pageTitle": "Inicio",
    "pageSubtitle": "Este es su centro de control para todo dentro de Data Intelligence Hub"
  },
  "services": {
    "finishConfig": "Terminar configuración",
    "open": "Abrir",
    "viewDetails": "Ver detalles",
    "configure": {
      "uploadCertification": "Por favor cargue el certificado que recibió del operador del espacio de datos.",
      "dragAndDropCertificate": "Arrastre y suelte aquí su archivo del certificado.",
      "didntReceive": "¿No ha recibido uno o no sabe cómo conseguir uno? Siga los pasos que se indican aquí",
      "uploadSuccessMessage": "Certificado cargado correctamente"
    },
    "table": {
      "attribute": "Atributo",
      "value": "Valor",
      "apiKey": "Clave API",
      "apiKeyValue": "Valor de la clave de API",
      "controlPlaneUrl": "URL del plano de control",
      "idsURL": "Punto de conexión de la API de DSP",
      "connectorUrl": "URL del conector",
      "dataManagementUrl": "Punto de conexión de la API de administración de datos",
      "dataDashboard": "Panel de datos",
      "participantDid": "ID DID del participante"
    }
  },
  "dataspaces": {
    "dataspaces": "Espacios de datos",
    "viewAllDataspaces": "Ver todos los espacios de datos",
    "connectorTestDescription": "Aplicación para probar si tu conexión está funcionando como se espera y tu conexión al espacio de datos está activa.",
    "configure": {
      "pageTitle": "Configurar la conexión de los dataspaces",
      "basicInfo": "Información básica",
      "certificate": "Certificado",
      "instructionCertrificate": "Por favor cargue el certificado que recibió del operador del espacio de datos."
    },
    "connectorHealthCheck": {
      "title": "Comprobación de salud del conector",
      "copiedToClipboard": "Copiado al portapapeles",
      "description": "Con la ayuda de esta aplicación, se realiza una autoevaluación para comprobar si el conector que has seleccionado está configurado y funcionando como se espera. Esto no cambiará ninguna configuración o almacenamiento del conector seleccionado.",
      "connectorid": "ID del conector",
      "model": {
        "title": "Comprobación de salud del conector en progreso...",
        "body": "No puedes salir mientras la comprobación de salud esté en progreso. Si deseas salir, la comprobación de salud se terminará y tendrás que reiniciarla.",
        "toLeave": "No, quiero salir",
        "toFinish": "Terminar la comprobación de salud"
      },
      "banner": {
        "initiate": "La comprobación de salud puede tardar unos minutos en ejecutarse. Se te notificará tan pronto como el resultado esté disponible.",
        "success": "¡Tu autoevaluación de Connect fue exitosa! Para ver los resultados de pruebas anteriores, haz clic en Resultados arriba.",
        "failed": "¡Lo sentimos! Tu autoevaluación de Connect no fue exitosa. Por favor, contacta con {email} y estaremos encantados de ayudarte.",
        "email": "dih_support{'@'}telekom.de"
      },
      "buttonState": {
        "initiate": "Iniciar",
        "success": "Abrir Connect",
        "failed": "Contactar con soporte",
        "back": "Atrás"
      }
    },
    "browse": {
      "browseDataspace": "Explorar espacios de datos",
      "availableDS": "Espacios de datos disponibles",
      "howToConnect": "Cómo conectarse",
      "getConnected": "Conectarse",
      "requestConnection": "Solicitar conexión",
      "viewRequest": "Ver solicitud",
      "viewDetails": "Ver detalles",
      "highlightNote": "Si desea gestionar su suscripción a {selectedDataspaceName}, póngase en contacto con nuestro equipo de asistencia:",
      "uncoverByInvitation": "Mostrar por invitación",
      "dataspaceDetails": "Detalles del espacio de datos",
      "participantOverview": "Visión general de los participantes",
      "services": "Servicios",
      "joinRequestSuccessTitle": "Tu solicitud para unirte a {dataspaceName} ha sido enviada",
      "joinRequestSuccessBody": "Recibirás más información una vez que tu solicitud sea procesada",
      "uncover": {
        "title": "Mostrar un espacio de datos por invitación",
        "subtitle": "¿Qué espacio de datos está buscando?",
        "fieldLabels": "Introduzca los detalles proporcionados por el operador del espacio de datos",
        "sendRequest": "Enviar solicitud",
        "back": "Volver atrás",
        "dataspaceId": "ID del espacio de datos",
        "password": "Contraseña"
      },
      "requestDetailsModal": {
        "withdraw": "Retirar",
        "cancel": "Cancelar",
        "update": "Actualizar",
        "withdrawConfirmationHeading": "Retirar solicitud de unión al espacio de datos",
        "withdrawConfirmationBody": "Vuelva a confirmar que tiene la intención de retirar su solicitud para unirse al espacio de datos {dataspaceName}.",
        "notification": {
          "withdrawSuccessfulTitle": "Se ha retirado la solicitud de unión al espacio de datos",
          "withdrawSuccessfulBody": "Su solicitud para unirse a {dataspaceName} ha sido retirada.",
          "updateSuccessfulTitle": "Solicitud de unión al espacio de datos actualizada",
          "updateSuccessfulBody": "Tu solicitud para unirte a {dataspaceName} ha sido actualizada."
        }
      }
    },
    "user": {
      "myMemberships": "Mis suscripciones",
      "enterDS": "Entrar en el espacio de datos",
      "registeredDS": "Tus espacios de datos",
      "noMembership": {
        "title": "Todavía no tiene ninguna suscripción",
        "description": "Explorar espacios de datos para unirse a uno",
        "actionButtonText": "Conectarse"
      }
    },
    "statuses": {
      "attentionRequired": "Atención necesaria",
      "comingSoon": "Próximamente",
      "connected": "Conectado",
      "disconnected": "Desconectado",
      "deploying": "Implementando",
      "deployed": "Implementado",
      "failed": "Error",
      "available": "Disponible",
      "success": "Hecho"
    }
  },
  "verify": {
    "orgProfile": "Perfil de la organización",
    "legalName": "Nombre legal",
    "address": "Dirección",
    "contact": "Contacto",
    "vatID": "Número de IVA",
    "requestChange": "Solicitar un cambio",
    "downloadVCMessage": "VC descargado correctamente",
    "verifiableCredentials": {
      "getVerified": "Obtener verificación",
      "credentials": "Credenciales",
      "requestCredential": "Solicitar credencial",
      "whichTypeToSelect": "¿Qué tipo debo seleccionar?",
      "noCredentialsMessage": "Todavía no tiene ninguna credencial",
      "credentialInfoMessage": "Añadir credenciales aumentará su nivel de confianza entre los participantes de un ecosistema.",
      "credentialModalMessage": "Sus credenciales son las cualificaciones o información que establecen la identidad, competencia o legitimidad de una empresa.",
      "learnMore": "Más información",
      "status": {
        "all": "Todos",
        "valid": "Válido",
        "expired": "Expirado",
        "revoked": "Revocado"
      },
      "types": {
        "legalPerson": "Identificación de la empresa",
        "serviceOffering": "Cumplimiento y certificación",
        "physicalResource": "Recursos físicos",
        "dataResource": "Recursos de datos",
        "softwareResource": "Recursos de software",
        "membership": "Afiliaciones"
      },
      "form": {
        "type": {
          "placeholder": "Seleccione su tipo de credencial",
          "label": "Tipo de credencial",
          "error": "Se requiere el tipo de credencial"
        },
        "types": {
          "legalPerson": "Persona jurídica",
          "serviceOffering": "Oferta de servicios",
          "physicalResource": "Recurso físico",
          "resource": "Recurso",
          "dataResource": "Recurso de datos",
          "softwareResource": "Recurso de software"
        },
        "companyInformationSectionTitle": "Información sobre la empresa",
        "companyInformationSectionSubtitle": "Por favor, utilice el nombre legal de la organización",
        "legalName": {
          "label": "Nombre legal de la organización",
          "helpText": "Por favor utilice el nombre legalmente vinculante de la organización"
        },
        "country": {
          "placeholder": "Seleccionar país",
          "label": "País",
          "error": "Se requiere el país"
        },
        "region": {
          "placeholder": "Seleccione región",
          "label": "Región",
          "error": "Se requiere región"
        },
        "street": {
          "label": "Calle y número"
        },
        "city": {
          "label": "Ciudad"
        },
        "postalCode": {
          "label": "Código postal"
        },
        "commercialIdentificationSectionTitle": "Identificación comercial",
        "commercialIdentificationSectionSubtitle": "Introduzca al menos uno de los siguientes ID únicos para continuar*",
        "CRN": {
          "label": "Número de registro comercial",
          "helpText": "Ejemplo: HRB 55933",
          "tooltipText": "El número de registro comercial es un identificador único asignado a las empresas para su registro comercial legal. Se utiliza para validar y verificar la autenticidad de los identificadores de registro y transacciones financieras de una empresa con fuentes de datos de confianza."
        },
        "vatId": {
          "label": "Número de IVA",
          "helpText": "Ejemplo: DE 118 645 675",
          "tooltipText": "El número de IVA (Impuesto sobre el Valor Añadido) es un identificador único que se asigna a las empresas para fines fiscales."
        },
        "eori": {
          "label": "EORI",
          "helpText": "Ejemplo: DE 103 280 493 572",
          "tooltipText": "El número EORI (número de Identificación y Registro de Operadores Económicos) es un identificador único asignado a los operadores económicos que participan en el comercio internacional dentro de la Unión Europea (UE)."
        },
        "lei": {
          "label": "LEI",
          "helpText": "Ejemplo: 5299009D9BIL4D4UHT93",
          "tooltipText": "El LEI (Identificador de Entidad Jurídica) es un código de identificación único asignado a las entidades jurídicas que realizan operaciones financieras en los mercados financieros globales."
        },
        "didSectionTitle": "Identificador descentralizado",
        "didSubSectionTitle": "Si no tienes un identificador descentralizado, deja este campo vacío, se generará automáticamente.",
        "signatureSectionTitle": "Firma",
        "signatureSubSectionTitle": "Hemos detectado un certificado de firma SSL EV asociado a su organización. Se utilizará para firmar las Credenciales.",
        "did": {
          "label": "Sujeto de la credencial",
          "helpText": "did:example123456789abcd",
          "tooltipText": "Un DID es un identificador único que se puede resolver a un Documento DID. Los DIDs son los componentes fundamentales de la identidad digital descentralizada."
        },
        "request": "Solicitar",
        "requesting": "Solicitando...",
        "consentPersonalData": "Por la presente, acepto y confirmo que todas las informaciones y datos personales (\"datos personales\") arriba indicados son correctos.",
        "consentProcessing": "Por la presente, doy mi consentimiento y autorizo a Gaia-X a tratar cualquiera de mis datos (incluidos datos personales confidenciales)."
      },
      "vcRequestSuccess": "Credencial firmada correctamente",
      "selectAllButton": "Seleccionar todo",
      "deselectAllButton": "Deseleccionar todo",
      "revokeButton": "Revocar",
      "searchboxPlaceholder": "Introducir al menos 3 caracteres",
      "credentialTile": {
        "revokeButton": "Revocar",
        "issuedBy": "Emitida por",
        "issuedOn": "Emitida el",
        "expiresOn": "Caduca el",
        "expiredOn": "Caducada el",
        "revokedOn": "Revocada el",
        "viewDetailsButton": "Ver detalles"
      },
      "revokeModal": {
        "heading": "Confirmación de revocación",
        "question": "¿Está seguro de que quiere revocar la credencial?",
        "note": "NOTA:",
        "message": "Tras revocar una credencial, ésta deja de ser válida y ya no puede utilizarse. Para obtener una credencial válida, deberá solicitar una nueva.",
        "primaryButton": "Revocar",
        "secondaryButton": "Cancelar"
      },
      "credentialsDetailsModal": {
        "heading": "Detalles de la credencial",
        "selfDescriptionCredential": "Credenciales de autodescripción",
        "type": "tipo",
        "id": "id",
        "issuer": "emisor",
        "issuanceDate": "fecha de emisión",
        "expirationDate": "fecha de caducidad",
        "credentialSubject": "objecto de la credencial",
        "bpn": "ctxsd:bpn",
        "participantName": "gx-participant:name",
        "participantNameLegalName": "gx-participant:legalName",
        "legalRegistrationNumber": "gx:participant:legalRegistrationNumber",
        "EUID": "gx:EUID",
        "leiCode": "gx:leiCode",
        "vatID": "gx:vatID",
        "registrationNumberType": "gx:registrationNumberType",
        "registrationNumberNumber": "gx:registrationNumberNumber",
        "headquarterAddress": "gx:participant:headquarterAddress",
        "addressCountryCode": "gx:addressCountryCode",
        "countrySubdivisionCode": "gx:countrySubdivisionCode",
        "locality": "gx:locality",
        "streetAddress": "gx:streetAddress",
        "legalAddress": "gx:participant:legalAddress",
        "termsAndConditions": "gx-participant:termsAndConditions",
        "serviceOfferingName": "gx:serviceOfferingName",
        "serviceOfferingDescription": "gx:serviceOfferingDescription",
        "providedBy": "gx:providedBy",
        "aggregationOf": "gx:aggregationOf",
        "aggregationOfId": "gx:id",
        "policy": "gx:policy",
        "serviceOfferingTermsAndConditions": "gx:termsAndConditions",
        "url": "gx:URL",
        "hash": "gx:hash",
        "dataAccountExport": "gx:dataAccountExport",
        "requestType": "gx:requestType",
        "accessType": "gx:accessType",
        "formatType": "gx:formatType",
        "proof": "Prueba",
        "created": "creado",
        "proofPurpose": "proofPurpose",
        "verificationMethod": "verificationMethod",
        "jws": "jws",
        "status": "Estado",
        "primaryButton": "Descargar",
        "secondaryButton": "Cancelar",
        "resourceName": "gx:name",
        "resourceDescription": "gx:description",
        "resourceCopyrightOwnedBy": "gx:copyrightOwnedBy",
        "resourceExposedThrough": "gx:exposedThrough",
        "resourceProducedBy": "gx:producedBy",
        "resourcePolicy": "gx:policy",
        "resourceLicense": "gx:license",
        "resourceContainsPII": "gx:containsPII",
        "resourceMaintainedBy": "gx:maintainedBy",
        "resourceOwnedBy": "gx:ownedBy",
        "resourceManufacturedBy": "gx:manufacturedBy",
        "resourceCountryCode": "Código de país",
        "resourceLocality": "Localidad",
        "resourcePostalCode": "Código postal",
        "resourceStreetAddress": "Dirección de la calle"
      },
      "notificationText": "Lo sentimos, no hemos encontrado ningún resultado que coincida con sus criterios de búsqueda. Por favor, refine su búsqueda e inténtelo de nuevo.",
      "bulkUpload": {
        "title": "Valide a sus socios",
        "helpText": "Valide a sus socias comerciales de una sola vez rellenando la plantilla proporcionada",
        "uploadFileTitle": "Cargar archivo",
        "requestCredentials": "Solicitar credenciales",
        "uploadFormHelpHeading": "Para validar varias empresas, haga lo siguiente:",
        "uploadFormHelpText1": "Descargue el archivo de plantilla desde el siguiente enlace",
        "uploadFormHelpText2": "Rellene el archivo proporcionado con los detalles de sus socios comerciales",
        "uploadFormHelpText3": "Suelte el archivo en el área de carga que aparece a continuación",
        "downloadTemplateBtn": "Descargar plantilla",
        "partnerValidationHelpText": "Si deseas obtener más información sobre la validación de socios, por favor",
        "partnerValidationHelpLink": "haga click aquí",
        "uploadFile": {
          "uploadFileHeading": "Seleccione un archivo CSV para cargar"
        },
        "vcProcessingTitle": "Procesamiento de registros",
        "bulkVcContinueBtn": "Continuar",
        "issuingProgressMessage": "El proceso de emisión de credenciales está en curso...",
        "doNotCloseWarning": "Por favor, no cierre el formulario hasta que termine el procesamiento",
        "bulkVcDownloadReportBtn": "Descargar informe",
        "successMessage": "¡Completado con éxito!",
        "failedErrorMessage": "Errores en {result} entradas",
        "processAbortTitle": "Procesando",
        "processAbortDescription": "¿Está seguro de que deseas detener el procesamiento?",
        "stopProcessingBtn": "Detener procesamiento"
      }
    },
    "serviceOfferingForm": {
      "generalInformationHeading": "Información general",
      "labelSOName": "Nombre de la oferta de servicios",
      "labelProvidedBy": "Proporcionado por",
      "labelSODescription": "Descripción de la oferta de servicios",
      "hintSOName": "Nombre legible de la Oferta de Servicios",
      "hintProvidedBy": "Enlace reutilizable en forma DID a la autodescripción del participante que presta el servicio",
      "hintSODescription": "Lista de políticas expresadas mediante DSL (por ejemplo, Rego o ODRL, control de acceso, limitación, uso, retención, ...)",
      "resourcesHeading": "Recursos",
      "labelSOResources": "Autodescripción de recursos",
      "hintSOResources": "Enlace reutilizable en forma de autodescripción de recurso DID, que está relacionado con el servicio y puede existir independientemente de él",
      "termsAndConditionsHeading": "Términos y condiciones",
      "labelUrlSO": "URL",
      "labelHashSO": "Hash",
      "labelSOPolicy": "Política",
      "policyTooltip": "Enlace resoluble a la Política aplicable a la Oferta de Servicios",
      "termsAndConditionsTooltip": "Enlace reutilizable a los términos y condiciones que se aplican a la oferta de servicios",
      "personalDEHeading": "Exportación de datos personales",
      "personalDETooltip": "Permite a los clientes del servicio exportar datos personales y no personales fuera del servicio.",
      "labelRequestTypeSO": "Tipo de solicitud",
      "labelAccessTypeSO": "Tipo de acceso",
      "placeholderRequestTypeSO": "Selecciona el tipo de solicitud",
      "placeholderAccessTypeSO": "Selecciona el tipo de acceso",
      "labelFormatTypeSO": "Tipo de formato",
      "hintRequestTypeSO": "El medio para solicitar la recuperación de datos: API, correo electrónico, formulario web, carta no registrada, carta registrada, centro de soporte",
      "hintAccessTypeSO": "Tipo de soporte de datos: digital, físico",
      "hintFormatTypeSO": "Tipo de tipos de medios (tipos MIME) definidos por",
      "consentTC1": "Por la presente acepto y confirmo que todos los datos ingresados anteriormente son precisos",
      "consentTC2": "Por la presente doy mi consentimiento y autorizo a T-Systems a procesar mis datos (incluidos los datos personales)",
      "consentTC3": "Por la presente acepto los",
      "gaiaXTermsText": "Términos y Condiciones de Gaia-X",
      "requestTypesList": {
        "api": "API",
        "email": "Correo electrónico",
        "webform": "Formulario web",
        "unregisteredLetter": "Carta no registrada",
        "registeredLetter": "Carta registrada",
        "supportCenter": "Centro de asistencia"
      },
      "requestTypesError": "Se requiere el tipo de solicitud",
      "accessTypesList": {
        "digital": "Digital",
        "physical": "Físico"
      },
      "accessTypesError": "Se requiere el tipo de acceso.",
      "helpSOName": "Introduzca un nombre legible para el ser humano de su servicio",
      "helpProvidedBy": "Identificador Descentralizado del Participante que presta el servicio",
      "helpSOResources": "Identificadores descentralizados de los Recursos que se exponen a través de este servicio",
      "helpUrlSO": "Introduzca el enlace a su documento de T&C (por ejemplo, https://service.com/terms)",
      "helpHashSO": "Ingrese un hash sha256 de su documento de T&C",
      "helpPolicySO": "Introduzca una política expresada mediante un DSL (por ejemplo, Rego u ODRL)",
      "helpRequestTypeSO": "Seleccione un tipo de solicitud de la lista desplegable",
      "helpAccessTypeSO": "Seleccione un tipo de acceso de la lista desplegable",
      "helpFormatTypeSO": "Introduzca el tipo MIME (por ejemplo, application/json)"
    },
    "resourceForm": {
      "generalInformationHeading": "Información general",
      "generalInformationSubHeading": "Ingrese la información general de su recurso",
      "labelResourceType": "Tipo de recurso",
      "placeholderResourceType": "Seleccionar tipo de recurso",
      "errorResourceType": "El tipo de recurso es obligatorio",
      "labelResourceName": "Nombre del recurso",
      "labelResourceDescription": "Descripción del recurso",
      "hintResourceType": "Tipo de recurso que se describe",
      "hintResourceName": "Nombre legible del recurso",
      "hintResourceDescription": "Descripción legible del recurso",
      "physicalResource": "Recurso físico",
      "softwareResource": "Recurso de software",
      "dataResource": "Recurso de datos",
      "additionalInformationHeading": "Información adicional",
      "additionalInformationSubHeading": "Por favor, introduzca la información adicional de su recurso",
      "labelMaintainedBy": "Mantenido por",
      "hintMaintainedBy": "Enlace reutilizable a Gaia-X Credencial del participante manteniendo el recurso en condiciones operativas y teniendo así acceso físico al mismo",
      "labelOwnedBy": "Propiedad de",
      "hintOwnedBy": "Enlace reutilizable a Gaia-X Credencial del participante propietario del recurso",
      "labelManufacturedBy": "Fabricado por",
      "hintManufacturedBy": "Enlace reutilizable a Gaia-X Credencial del participante que fabrica el recurso",
      "labelCountry": "País",
      "hintCountry": "País en el que se encuentra el recurso",
      "labelCity": "Ciudad",
      "hintCity": "Ciudad / pueblo en el que se encuentra el recurso",
      "labelPostCode": "Código postal",
      "hintPostCode": "Código postal en el que se encuentra el recurso",
      "labelStreet": "Calle",
      "hintStreet": "Calle y número de casa en el que se encuentra el recurso",
      "labelCopyrightOwnerBy": "Propietario de los derechos de autor por",
      "hintCopyrightOwnerBy": "Un propietario de derechos de autor es una persona u organización que tiene derecho a explotar el recurso. El propietario de los derechos de autor no se refiere necesariamente al autor del recurso, que es una persona física y puede diferir del propietario de los derechos de autor. Expresado como texto libre o como un enlace resoluble a la credencial Gaia-X del propietario de los derechos de autor.",
      "labelLicense": "Licencia",
      "hintLicense": "URL del documento de licencia o de una de las licencias de la",
      "labelPolicy": "Política",
      "hintPolicy": "Una lista de políticas expresadas mediante un DSL (por ejemplo, Rego u ODRL) (control de acceso, limitación, uso, retención, ...). Si se omite la restricción de directiva, se supone que el uso está permitido a todos los participantes",
      "labelProducedBy": "Producido por",
      "hintProducedBy": "Enlace reutilizable a la credencial Gaia-X del participante que habilita legalmente el uso de los datos.",
      "labelExposedThrough": "Expuesto a través de",
      "hintExposedThrough": "Vínculo reutilizable al componente de intercambio de datos que expuso el recurso de datos",
      "locationHeading": "Detalles de la ubicación",
      "locationSubHeading": "Por favor, introduzca los detalles de la ubicación de su recurso",
      "licenseTooltipText": "Lista de licencias de SPDX",
      "licenseTooltipLink": "https://spdx.org/licenses/",
      "consentPII": "Por la presente confirmo que el recurso de datos no contiene información de identificación personal (PII)."
    },
    "verifyLanding": {
      "heading": "Bienvenido a \"Get verified\"",
      "subHeading": "¡Aumente la confianza de tu ecosistema digital!",
      "description1": "¿Desea verificar y validar a sus socios comerciales, o quiere mostrar sus propias identificaciones y verificaciones? Entonces debería solicitar sus primeras Credenciales Verificables - nuestras",
      "description2": "Las Credenciales Verificables pueden contener la misma información que tienen tus identificaciones físicas (por ejemplo, tarjeta de identificación, licencia de conducir) e incluso más atributos. La incorporación de tecnologías, como las firmas digitales, hace que las credenciales verificables sean más seguras y fiables que sus contrapartes físicas.",
      "description3": "T-Systems opera como uno de los primeros nodos de Clearing House Digital de Gaia-X y emite tus pruebas digitales de acuerdo con las especificaciones del marco de confianza de Gaia-X. Además, añadimos nuestra firma digital conforme a eIDAS de nuestro socio Telekom Security.",
      "requestButton": "Solicitar Digital.ID",
      "digitalID": "producto Digital.ID.",
      "consultation" : {
        "heading": "Solicite sus credenciales o valide a sus socios",
        "placeholder": "¿Alguna experiencia previa con credenciales verificables? ¿Está interesado en sus primeras credenciales?"
      },
      "sendAnotherRequest": "Enviar otra solicitud",
      "lastRequest": "Su última solicitud se envió el"
    },
    "getInsights": {
      "tab1": "Informes",
      "tab2": "Límites de uso de la API",
      "tab3": "Integración técnica",
      "monthsByOptions": {
        "option1": "3 Meses",
        "option2": "6 Meses",
        "option3": "1 Mes"
      },
      "table": {
        "heading2": "Período de referencia",
        "heading3": "Creado en",
        "heading4": "Acciones"
      },
      "successDownload": "Informe(s) descargado con éxito",
      "errorDownload": "Su descarga de archivos para {filename} ha abortado con error, por favor inténtelo de nuevo.",
      "apiUsageLimit": {
        "requestNameTitle": "Nombre de la solicitud",
        "dailyTitle": "Solicitudes diarias de API",
        "monthlyTitle": "Solicitudes mensuales de API",
        "limitexhaustedWarning": "Quedan menos del 10% de las solicitudes",
        "limitExtentionHeading": "Solicitar extensión de límite",
        "limitExtentionPlaceholder": "¿Está interesado en ampliar los límites de uso de su API?",
        "supportBtn": "Soporte",
        "limitExtentionText": "¿A punto de alcanzar el límite? Póngase en contacto con nosotros y lo solucionaremos."
      },
      "addIntegration": "Agregar integración",
      "integrationData": {
        "clientId": "ID de cliente",
        "createdAt": "Creado en",
        "actions": "Acciones"
      }
    },
    "addIntegration": {
      "generalInformationHeading": "Información general",
      "addAnIntegration": "Agregar una integración",
      "clientName": "Nombre del cliente",
      "clientId": "ID de cliente",
      "clientSecret": "Secreto de cliente",
      "tokenUrl": "URL del token",
      "pcCallbackUrl": "URL de devolución de llamada de credenciales de participante",
      "soCallbackUrl": "URL de devolución de llamada de credenciales de oferta de servicio",
      "digitalIdAuthenticateInfo1": "¿Cómo autenticará la Validación de Digital.ID contra su sistema?",
      "digitalIdAuthenticateInfo2": "¿Cómo autenticará el Cumplimiento de Digital.ID contra su sistema?",
      "seeResults": "¿Dónde debemos enviar los resultados?",
      "requestSuccess": "añadido con éxito",
      "callbackSuccess": "Callback añadido con éxito",
      "step1Name": "Detalles de autenticación de validación",
      "step2Name": "Detalles de autenticación de cumplimiento",
      "step3Name": "Información de devolución de llamada",
      "deleteSuccess": "Eliminado con éxito",
      "downloadSuccess": "Descargado con éxito",
      "editCallbackHeading": "Agregar devolución de llamada",
      "deleteModal": {
        "heading": "Eliminar confirmación",
        "deleteContent": "¿Estás seguro de que quieres eliminar el cliente?",
        "deleteNote": "Después de eliminar un cliente, desaparecerá de la lista y ya no se podrá usar. Para restaurarlo, tendrás que recrearlo desde cero."
      }
    },
    "mySignatures": {
      "certificatesOrdering": {
        "heading": "Bienvenidos a \"Mis firmas\"",
        "subHeading": "¿Desea solicitar un certificado digital que le permita firmar sus solicitudes?",
        "description1": "Con nuestro producto Digital.ID, puede solicitar fácilmente su primer certificado y comenzar a disfrutar de los beneficios de las colaboraciones digitales seguras.",
        "description2": "Las credenciales verificables pueden contener la misma información que sus identificadores físicos, como documentos de identidad y licencias de conducir, e incluso más. Durante el proceso de emisión, confirmará la exactitud de su información firmándola con su propio certificado de confianza.",
        "description3": "Puede solicitar y mantener de forma segura sus certificados digitales para todas sus necesidades de firma. Nos asociamos exclusivamente con proveedores de certificados digitales certificados, lo que garantiza que sus certificados digitales sean seguros y confiables.",
        "requestButton" : "Solicitar Certificado",
        "consultation" : {
          "heading": "Solicitar certificado",
          "placeholder": "Por favor, introduzca los datos necesarios sobre su solicitud"
        }
      },
      "certificateOverview": {
        "heading": "Mis firmas",
        "requestCertificateCTA": "Solicitar certificado",
        "placeholderCertificateType": "Tipo de certificado",
        "placeholderCertificateStatus": "Estado del certificado",
        "EVSSL": "EV SSL",
        "EIDAS": "EIDAS",
        "headingEVSSL": "Certificados EV SSL",
        "headingEIDAS": "Certificados EIDAS",
        "certificateDetailsHeading": "Detalles del certificado",
        "certificateName": "Nombre",
        "certificateId": "ID",
        "certificateType": "Tipo",
        "provider": "Proveedor",
        "organizationId": "ID de la organización",
        "UUID": "UUID",
        "certificate": "Certificado",
        "createdAt": "Creado el",
        "updatedAt": "actualizado el",
        "certificateExpiry": "Caduca el",
        "certificateVerificationMethod": "Método de verificación",
        "certificateDidJson": "DID JSON",
        "status": "Estado",
        "certificateDownloadSuccess": "Certificado descargado correctamente"
      }
    }
  },
  "build": {
    "ownedDataspaces": "Mis espacios de datos",
    "checkConsent": "Doy mi consentimiento para que se proporcionen mis datos de contacto a un equipo de ventas de T-Systems y para que se me contacten.",
    "welcomeOwnedDataspace": "Le damos la bienvenida a \"Espacios de datos propios\"",
    "resendInvitation": "Reenviar invitación",
    "resendInvitationInfo": "Puedes enviar la notificación tan pronto como hayan pasado 24 horas desde el último correo de invitación.",
    "requestSuccessTitle": "¡Solicitud exitosa!",
    "requestSuccessBody": "Su solicitud ha sido enviada... Recibirás una respuesta en unos días hábiles",
    "inviteParticipant": {
      "buttonLabel": "Invitar participante",
      "successNotificationTitle": "¡Invitación exitosa!",
      "successNotificationContent": "Tu invitación se ha enviado con éxito",
      "alreadySentNotificationTitle": "Ya se ha enviado una invitación a esta dirección de correo electrónico.",
      "alreadySentNotificationContent": "Por favor, espera 24 horas antes de enviar la invitación nuevamente.",
      "resendInviteNotificationTitle": "¡La reinscripción ha sido exitosa!",
      "resendInviteNotificationContent": "Tu reinvitación ha sido enviada exitosamente.",
      "modal": {
        "title": "Invitar participante",
        "informationTitle": "Información",
        "informationText": "La organización invitada puede intercambiar datos con otros participantes y publicar nuevos activos de datos en el dataspace. Además, la organización puede consumir aplicaciones y servicios que forman parte de tu dataspace.",
        "formTitle": "Por favor, ingresa los detalles del nuevo participante",
        "organizationName": "Nombre de la organización",
        "proceed": "Continuar",
        "cancel": "Cancelar"
      },
      "deleteInvitation": {
        "modal": {
          "title": "Eliminar invitación",
          "informationText": "Por favor, vuelve a confirmar que deseas eliminar esta invitación al dataspace. Esta acción no se puede deshacer.",
          "successNotificationTitle": "Eliminación exitosa",
          "successNotificationBody": "Invitación eliminada con éxito",
          "cancel": "Cancelar",
          "delete": "Eliminar"
        }
      }
    },
    "editParticipant": {
      "modal": {
        "title": "Editar participante",
        "cancel": "Cancelar",
        "save": "Guardar",
        "roleSection": {
          "name": "Rol",
          "helpText": "¿Qué rol debo seleccionar?",
          "helpUrl": "https://docs.dih.telekom.com/en/space/living-lab/manage-your-livinglab",
          "operatorLabel": "Operador del dataspace",
          "operatorTooltip": "Participante que puede gestionar el kit de herramientas del operador e incorporar aplicaciones",
          "operatorCheckboxTooltip": "Eres el propietario del dataspace. Por lo tanto, no puedes deseleccionar Operador.",
          "participantLabel": "Participante del dataspace",
          "participantTooltip": "Participante que puede actuar como proveedor y consumidor de datos",
          "participantCheckboxTooltip": "El participante del dataspace no puede ser desactivado. Este es el estado predeterminado de un usuario en un dataspace."
        },
        "visibilitySection": {
          "name": "Visibilidad",
          "helpText": "¿Qué visibilidad debo seleccionar?",
          "helpUrl": "https://docs.dih.telekom.com/en/space/living-lab/manage-your-livinglab",
          "anonymousLabel": "Anónimo",
          "anonymousLabelTitle": "Participante anónimo",
          "anonymousTooltip": "Participante oculto para otros participantes y no puede ver a otros participantes"
        }
      },
      "updateSuccessfulTitle": "¡Actualización exitosa!",
      "updateSuccessfulBody": "El nuevo rol y visibilidad se han adoptado"
    },
    "applicationManagement": {
      "buttonLabel": "Registrar aplicación",
      "registerForm": {
        "pageTitle": "Registro de aplicación",
        "step1Name": "Detalles de la aplicación",
        "step2Name": "Acceso a la aplicación",
        "displayName": "Nombre visible",
        "providedBy": "Provisto por",
        "providedByPlaceholder": "Nombre de la organización",
        "description": "Descripción",
        "descriptionPlaceholder": "Texto de descripción",
        "link": "Enlace",
        "linkPlaceholder": "https://www.example.org/enlace-de-aplicación/",
        "agreementCheckboxLabel": "Por la presente, acepto que soy totalmente responsable y responsable de la aplicación que estoy registrando y que no es ningún tipo de malware. Además, me he asegurado de que no haya ninguna violación de licencia. Entiendo que soy totalmente responsable de la gestión del acceso de la aplicación y que T-Systems International GmbH no tiene responsabilidad en este sentido. T-System International GmbH no es responsable ni está obligado a responder por la aplicación y sus posibles consecuencias de ninguna manera."
      },
      "deleteApplication": {
        "modal": {
          "title": "Eliminar aplicación",
          "informationText1": "Por favor, vuelve a confirmar que estás seguro de que deseas eliminar este enlace de aplicación de tu dataspace. Esta aplicación no estará disponible para ningún participante dentro de tu dataspace.",
          "informationText2": "¡Ten en cuenta que la aplicación real permanecerá sin cambios!",
          "successNotificationTitle": "Eliminación exitosa",
          "successNotificationBody": "Aplicación eliminada con éxito",
          "cancel": "Cancelar",
          "delete": "Eliminar"
        }
      },
      "editForm": {
        "pageTitle": "Editar aplicación",
        "step1Name": "Detalles de la aplicación",
        "step2Name": "Acceso a la aplicación",
        "displayName": "Nombre visible",
        "providedBy": "Provisto por",
        "providedByPlaceholder": "Nombre de la organización",
        "description": "Descripción",
        "descriptionPlaceholder": "Texto de descripción",
        "link": "Enlace",
        "successNotificationTitle": "Edición exitosa",
        "successNotificationBody": "Aplicación editada con éxito",
        "linkPlaceholder": "https://www.example.org/enlace-de-aplicación/",
        "agreementCheckboxLabel": "Por la presente, acepto que soy totalmente responsable y responsable de la aplicación que estoy registrando y que no es ningún tipo de malware. Además, me he asegurado de que no haya ninguna violación de licencia. Entiendo que soy totalmente responsable de la gestión del acceso de la aplicación y que T-Systems International GmbH no tiene responsabilidad en este sentido. T-System International GmbH no es responsable ni está obligado a responder por la aplicación y sus posibles consecuencias de ninguna manera.",
        "cancel": "Cancelar",
        "save": "Guardar cambios"
      }
    },
    "invite": {
      "inviteLabel": "Invitar participante"
    },
    "allocateConnectors": {
      "pageTitle": "Asignación de conectores",
      "allocateConnectorLabel": "Asignar conectores",
      "topUpConnectorsButtonLabel": "Recargar conectores",
      "topUpConnectorTitle": "Tu solicitud se ha enviado correctamente.",
      "topUpConnectorBody": "Nuestro equipo de soporte se pondrá en contacto contigo lo antes posible para discutir más detalles.",
      "tooltipMessage": "Póngase en contacto con el soporte de DIH (dih_support{'@'}telekom.de) para asignar nuevos conectores a los participantes existentes o para invitar a un nuevo participante al espacio de datos",
      "allocationForm": {
        "step1Name": "Organización",
        "step2Name": "Conectores",
        "organizationNameDescription": "Selecciona la empresa a la que se deben asignar nuevos conectores",
        "organizationNameLabel": "Nombre de la organización",
        "organizationPlaceholder": "Selecciona la organización",
        "inviteParticipantDescription": "O haz clic en el botón de invitar participante para invitar a una nueva empresa a colaborar en el dataspace",
        "inviteParticipantButton": "Invitar participante",
        "numberOfConnectorsDescription": "Elige el número de conexiones",
        "numberOfConnectorsLabel": "Número de conectores",
        "numberOfConnectorsPlaceholder": "Cantidad",
        "progressBarDescription": "Cuota de conectores",
        "progressBarInfo": "{numberOfConnectors} conectores disponibles",
        "allocateButtonLabel": "Asignar",
        "cancelButtonLabel": "Cancelar",
        "noConnectorRemaining": "Todos los conectores están en uso. Por favor, haz clic en 'Recargar conectores' para discutir la extensión de los conectores con nuestro equipo."
      },
      "successRequestTest": "Solicitud exitosa",
      "successRequestBody": "La asignación de conectores se ha iniciado con éxito",
      "decisionModal": {
        "title": "Has sido invitado a unirte a {dataspaceName}",
        "accept": "Aceptar",
        "decline": "Rechazar",
        "acceptSuccessNotificationTitle": "¡Aceptación exitosa!",
        "acceptSuccessNotificationBody": "Has aceptado con éxito la invitación para unirte a {dataspaceName}.",
        "declineSuccessNotificationTitle": "¡Invitación rechazada!",
        "declineSuccessNotificationBody": "Has rechazado la invitación para unirte a {dataspaceName}."
      }
    },
    "request": {
      "requestTitle": "Dé el siguiente paso en el intercambio de datos y cree su propio espacio de datos!",
      "requestDescription1": "¿Quiere no sólo compartir sus propios datos, sino también permitir que sus socios comerciales compartan datos entre sí? En ese caso, plantéese crear un espacio de datos, que es una comunidad de confianza para compartir o monetizar datos.",
      "requestDescription2": "T-Systems configurará y pondrá en funcionamiento su espacio de datos en nuestra infraestructura de confianza.",
      "requestDescription3": "También ofrecemos configuraciones de prueba limitadas para que pueda familiarizarse con los espacios de datos („LivingLab“).",
      "request": "Solicitar",
      "sendAnotherRequest": "Enviar otra solicitud",
      "lastRequest": "Su última solicitud se envió el",
      "back": "Volver atrás",
      "sendRequest": "Enviar solicitud",
      "requestedTextPlaceholder": "¿Está buscando algo en concreto o desea hacer una consulta general? ¿Tiene experiencia con espacios de datos? ¿Está interesado en una configuración de prueba?"
    },
    "create": {
      "createTitle": "Cree su propio espacio de datos",
      "createDesc": "Puede enviar una solicitud a nuestro equipo de expertos y nos pondremos en contacto con usted.",
      "tellUsMore": "¿Podría decirnos algo más sobre su solicitud?"
    },
    "details": {
      "approve": "Aprobar",
      "decline": "Rechazar",
      "approval": "Aprobación",
      "requesterNotes": "Notas del solicitante",
      "adminNotes": "Notas del administrador",
      "decisionTakenTooltip": "La aceptación o el rechazo ya han ocurrido. La provisión de los conectores se está preparando actualmente. Se te informará tan pronto como se hayan completado.",
      "decisionInputPlaceholder": "Notas para la aprobación o denegación ...",
      "activeParticipants": "Participantes activos",
      "incomingRequests": "Solicitudes entrantes",
      "invitations": "Invitaciones",
      "manage": "Gestionar",
      "enter": "Introducir",
      "providedBy": "Provisto por",
      "registered": "Registrado",
      "operatorToolkit": "Kit de herramientas del operador",
      "dataspaceDetails": "Detalles del espacio de datos",
      "contactDetails": "Detalles de contacto",
      "visibilityTitle": "Ocultar tu dataspace de la lista de dataspace navegables",
      "manageParticipants": "Gestionar participantes",
      "applicationManagement": "Gestión de aplicaciones",
      "noApplications": {
        "title": "Todavía no tienes aplicaciones",
        "actionButtonText": "Registrar aplicación"
      },
      "contactUs": "Si desea actualizar algún dato, póngase en contacto con nosotros directamente",
      "noData": "Todavía no tienen datos",
      "basic": "Básico",
      "companyName": "Nombre de la empresa",
      "participationMode": "Modalidad de participación",
      "connectorName": "Nombre del conector",
      "contact": "Contacto",
      "address": "Dirección",
      "street": "Calle",
      "number": "Número",
      "postalCode": "Código postal",
      "city": "Ciudad",
      "country": "País",
      "phoneNo": "Número de teléfono",
      "contactPerson": "Persona de contacto",
      "email": "Correo electrónico",
      "dateOfJoining": "Fecha de incorporación",
      "dateOfInvitation": "Fecha de invitación",
      "dateOfRequest": "Fecha de solicitud",
      "url": "URL",
      "status": "Estado",
      "bpn": "Número de socio comercial",
      "name": "Nombre",
      "description": "Descripción",
      "website": "Página web",
      "dataspaceId": "ID del espacio de datos",
      "password": "Contraseña",
      "digitalId": "Este espacio de datos está habilitado para Digital.ID",
      "disableDigitalId": "Digital.ID no está habilitado para este espacio de datos",
      "company": "Empresa | Empresas",
      "connector": "Conector | Conectores",
      "application": "Aplicación | Aplicaciones",
      "dataoffer": "Oferta de datos | Ofertas de datos",
      "visibility": "Su espacio de datos es ahora {access}",
      "discoverable": "Descubrible",
      "private": "Privado",
      "deleteParticipant": {
        "modal": {
          "title": "Eliminar participante",
          "informationText": "El conector perteneciente al participante se desplegará al confirmar. Esta acción no se puede deshacer.",
          "successNotificationTitle": "Solicitud de Eliminación",
          "successNotificationBody": "Se ha solicitado la eliminación de un participante",
          "cancel": "Cancelar",
          "delete": "Eliminar"
        }
      },
      "services": {
        "applicationOrchestration": "Orquestación de aplicaciones",
        "applicationOrchestrationDesc": "Implementa y gestiona tus aplicaciones empresariales",
        "identityAccessManagement": "Gestión de identidades y accesos",
        "identityAccessManagementDesc": "Gestione sus usuarios y su acceso a los recursos",
        "continuousDelivery": "Entrega continua",
        "continuousDeliveryDesc": "Adopte las mejores prácticas de implementación y operaciones",
        "secretCredentialManagement": "Gestión de secretos y credenciales",
        "secretCredentialManagementDesc": "Proteja, almacene y controle el acceso a tokens, contraseñas y certificados",
        "monitoring": "Seguimiento",
        "monitoringDesc": "Consulte, visualice, reciba alertas y comprenda sus métricas y KPIs",
        "connectorTest": "Comprobación de salud del conector",
        "connectorTestDesc": "Servicio para ejecutar una comprobación de salud para los conectores.",
        "catenaXSandbox": "Catena-X Sandbox",
        "catenaXSandboxDesc": "Inicie su sandbox Catena-X dentro de su LivingLab para desarrollar y probar sus nuevas aplicaciones empresariales en el entorno Catena-X."
      }
    },
    "connectorHealthCheck": {
      "title": "Comprobación de salud del conector",
      "viewResults": "Ver resultados",
      "close": "Cerrar",
      "description": "Elige uno o más conectores para ejecutar una comprobación de salud. Las operaciones estándar del dataspace no se verán afectadas por la comprobación de salud.",
      "connectors": "conectores",
      "placeholder": "Elige conectores",
      "searchInputLabel": "Buscar conectores...",
      "banner": "La comprobación de salud puede tardar unos minutos en ejecutarse. Se te notificará tan pronto como el resultado esté disponible.",
      "action": "Iniciar",
      "history": {
        "connectorName": "Nombre del conector",
        "initiated": "Iniciado",
        "completed": "Completado",
        "result": "Resultado",
        "successMessage": "{connectorName} está funcionando como se espera",
        "unsuccessfulMessage": "{connectorName} no está funcionando como se espera. Por favor, contacta a "
      },
      "successNotificationTitle": "Comprobación de salud del conector iniciada.",
      "successNotificationContent": "Se te notificará cuando el resultado esté disponible.",
      "noTest": "Todavía no tienes pruebas",
      "initiateTest": "Iniciar prueba"
    },
    "specifications": {
      "table": {
        "attribute": "Atributo",
        "value": "Valor",
        "cloudProvider": "Proveedor de nube",
        "contractStart": "Inicio del contrato",
        "contractDuration": "Duración del contrato",
        "cpu": "CPU",
        "ram": "RAM",
        "storage": "Almacenamiento",
        "maximumConnectorsBookable": "Conectores máximos reservables",
        "subscribedConnectors": "Conectores suscritos"
      },
      "dataspaceSpecifications": "Especificaciones del espacio de datos",
      "livinglabCore": "Núcleo de LivingLab",
      "livinglabAppLayer": "Capa de aplicación de LivingLab"
    }
  },
  "profile": {
    "profile": "Perfil",
    "userProfile": "Perfil de usuario",
    "personalInfo": "Información personal",
    "personalDetails": "Datos personales y configuración",
    "changeDetailsMessage": "Si desea cambiar algún dato, por favor, póngase en contacto con nosotros",
    "changeDetailsLinkText": "Asistencia",
    "memberships": "Suscripciones",
    "security": "Seguridad",
    "setUp2FA": "Configurar 2FA",
    "accountInformation": "Información sobre la cuenta",
    "deleteAccount": "Eliminar cuenta de usuario",
    "deleteAccountDescription": "Con esta acción, se eliminarán todos los datos y los archivos de datos asociados a su cuenta personal.",
    "accountSecurity": "Seguridad de la cuenta",
    "yourInfomration": "Su información",
    "fullName": "Nombre completo",
    "contact": "Contacto",
    "language": "Idioma",
    "changePassword": "Cambiar contraseña",
    "please": "Por favor",
    "contactSupport": "Contactar con el equipo de asistencia",
    "toDownload": "Para descargar esta información",
    "toDelete": "Para eliminar su perfil",
    "roles": "Roles",
    "memberSince": "Miembro desde",
    "orgLegalName": "Nombre legal de la organización",
    "bpnNumber": "Número de socio comercial",
    "commRegNumber": "Número de registro comercial",
    "vatIdNumber": "Número de IVA",
    "hqAddress": "Dirección de la sede principal",
    "legalIdentifier": "Identificador legal",
    "additionalAttributes": "Atributos adicionales"
  },
  "plansBenefits": {
    "communicationIntegrity": "Integridad de la comunicación",
    "protectedConnection": "Conexión protegida",
    "mutualAuth": "Autenticación mutua",
    "stateArtOfCrypto": "Criptografía de última generación",
    "remoteAttestation": "Atestación a distancia",
    "platforInt": "Integridad de la plataforma",
    "configureAndApp": "Configuración e integración de la aplicación",
    "dataUsageControl": "Control del uso de datos",
    "definitionOfUsagePolicy": "Definición de la política de uso",
    "from": "desde",
    "month": "mes",
    "connect": "Conectar"
  },
  "cookieSettings": {
    "collapsedTitle": "Configuración de privacidad",
    "collapsedText": "Este sitio web utiliza cookies y tecnologías similares. Son pequeños archivos de texto que se almacenan y leen en su computadora. Al hacer clic en '{0}', acepta el procesamiento, la creación de perfiles de usuario individuales en sitios web y socios, y la transferencia de sus datos a terceros, algunos de los cuales procesan sus datos en países fuera de la Unión Europea (RGPD Art. 49). Los detalles se pueden encontrar en la sección 2 de la {1}. Los datos se utilizan para análisis, retargeting y para reproducir contenido publicitario y publicidad personalizados en sitios de Telekom y sitios de terceros. Puede encontrar más información, incluida información sobre el procesamiento de datos por parte de proveedores de terceros y la posibilidad de revocación, en la configuración y en nuestra información de privacidad. Aquí puede {2} solo con las herramientas necesarias.",
    "extendedTitle": "Configuración de privacidad",
    "extendedText": "Para proporcionarle una experiencia óptima en el sitio web, utilizamos cookies. Estas incluyen cookies para el funcionamiento y la optimización del sitio, así como para servicios como chat de texto o video y para publicidad basada en su comportamiento de uso en línea. Esto nos permite, por ejemplo, detectar si visita nuestras páginas repetidamente desde el mismo dispositivo. Nos gustaría darle la opción de qué cookies permite:",
    "onlyRequired": "Solo es necesario",
    "policyLinkHref": "https://dih.telekom.com/en/privacy-policy",
    "policyLinkCookieHref": "https://dih.telekom.com/en/privacy-policy",
    "policyLinkTextShort": "información sobre privacidad",
    "policyLinkText": "Información sobre privacidad",
    "rejectLinkText": "continuar",
    "acceptButton": "Aceptar todo",
    "saveButton": "Guardar configuración",
    "settingsButton": "Cambiar configuración",
    "showMore": "Mostrar más",
    "showLess": "Mostrar menos",
    "categoryTitleEssential": "Cookies necesarias",
    "categoryTitleAnalytics": "Cookies analíticas",
    "categoryTitleMarketing": "Cookies de marketing",
    "categoryTitleThirdParty": "Servicios proporcionados por otras empresas (proveedores externos independientes)",
    "categoryTextEssential": "Estas cookies son necesarias para permitirle navegar por los sitios web y utilizar funciones clave. Apoyan funciones básicas, como el procesamiento de pedidos en la tienda en línea y el acceso a áreas seguras de la página web. También sirven para realizar un análisis anónimo de los patrones de usuario, que utilizamos para desarrollar y mejorar continuamente nuestras páginas web para usted.",
    "categoryTextAnalytics": "Estas cookies nos ayudan a mejorar nuestra comprensión del comportamiento del usuario. Las cookies de análisis permiten la recopilación de datos de uso e identificación por parte del proveedor original o proveedores de terceros en perfiles de uso seudónimos. Usamos cookies de análisis, por ejemplo, para determinar el número de visitantes individuales a una página web o un servicio, para recopilar datos estadísticos sobre el rendimiento de nuestros productos y para analizar los patrones de uso y las interacciones de los visitantes en función de información anónima y seudónima. Esta información no se puede rastrear hasta una persona.",
    "categoryTextMarketing": "Estas cookies y tecnologías similares se utilizan para permitir la visualización de contenido de marketing personalizados y, por tanto, relevantes. Las cookies de marketing se utilizan para mostrar contenido publicitario interesante y para medir la eficacia de nuestras campañas. Esto ocurre no sólo en las páginas web de Telekom, sino también en los de otros socios publicitarios (proveedores externos). Esto también se denomina \"retargeting\". Se utiliza para crear perfiles de contenido o anuncios seudónimos, mostrar publicidad relevante en otras páginas web y obtener información sobre los públicos objetivos que han visto los anuncios y contenido. A partir de estos análisis no es posible extraer crelacionar la información con ninguna persona. Las cookies de marketing y las herramientas de retargeting nos ayudan a ofrecerle contenidos publicitarios potencialmente relevantes para usteed. Si desactiva las cookies de marketing, seguirá viendo el mismo número de anuncios, pero éstos pueden ser menos relevantes para usted.",
    "categoryTextThirdParty": "Las páginas web de Telekom incluyen enlaces a proveedores de servicio externos, que prestan sus servicios bajo su propia responsabilidad. Cuando visita páginas web de Telekom, se recopilan datos mediante cookies u otras tecnologías similares y se transmiten a los proveedores externos correspondientes, en parte para fines propios de Telekom. El alcance, finalidad y base jurídica sobre la que se lleva a cabo el tratamiento posterior para los propios fines del tercero pueden consultarse en la política de privacidad de los proveedores externos. La información sobre proveedores externos independientes puede encontrarse en la información sobre privacidad de datos."
  },
  "consultation": {
    "createDescription": "Puede enviar una solicitud a nuestro equipo de expertos y nos comunicaremos con usted.",
    "tellUsMore": "¿Puede contarnos más sobre su solicitud?",
    "checkConsent": "Doy mi consentimiento para que mis datos de contacto se proporcionen a un equipo de ventas de T-Systems y para que me contacten.",
    "back": "Atrás",
    "sendRequest": "Enviar petición",
    "successMsgtitle": "¡Solicitud exitosa!",
    "successMsgBody": "Tu solicitud ha sido enviada... recibirás respuesta en unos días laborables"
  },
  "notifications": {
    "notifications": "Notificaciones",
    "inbox": "Bandeja de entrada",
    "clearAll": "Borrar todo",
    "blankState": {
      "heading": "Aún no hay notificaciones",
      "description": "Cuando reciba notificaciones, aparecerán aquí",
      "actionButtonText": "Actualizar"
    },
    "actionMessage": {
      "seeRequest": "Ver la solicitud",
      "here": "aquí",
      "checkResult": "Verificar el resultado "
    },
    "goToMyAccount": "Por favor, consulte la información sobre su(s) nuevo(s) rol(es) en {myAccountLink}."
  },
  "statusTile": {
    "noMembership": {
      "title": "Todavía no tiene ninguna suscripción",
      "subTitle": "Explorar espacios de datos para unirse a uno",
      "getConnected": "Conectarse"
    }
  },
  "settings": {
    "organization": {
      "pageTitle": "Mi organización",
      "orgDetails": "Detalles de la organización",
      "teamMembers": "Miembros del equipo"
    },
    "teamMembers": {
      "title": "Gestión de usuarios",
      "description": "Esta sección le ayuda a gestionar los usuarios de su organización"
    },
    "inviteUser": {
      "inviteUsers": "Invitar usuarios",
      "newEmailAddress": "Dirección de correo electrónico de nuevos miembros del equipo",
      "chooseRole": "Elegir rol",
      "whichRoleToSelect": "¿Qué rol debo seleccionar?",
      "roleNote": "El rol seleccionado se asignará a todos los usuarios invitados",
      "roleError": "Por favor, seleccione al menos 1 rol de las opciones disponibles",
      "roleErrorShort": "Por favor, seleccione al menos 1 rol",
      "cancel": "Cancelar",
      "sendInvite": "Enviar invitación",
      "fullName": "Nombre completo",
      "status": "Estado",
      "role": "Rol",
      "memberSince": "Miembro desde"
    },
    "inviteSuccess": {
      "title": "Resumen de la invitación",
      "totalInvitation": "{count} invitación enviada | {count} invitaciones enviadas",
      "description": "{count} la invitación no se envió porque la dirección de correo electrónico ya existe entre los usuarios de su organización | {count} las invitaciones no se enviaron porque las direcciones de correo electrónico ya existen entre los usuarios de su organización",
      "actionContinue": "Continuar"
    },
    "roleChange": {
      "areYouSure": "¿Estás seguro de que quieres hacer estos cambios de rol?",
      "yeahSure": "Sí, estoy seguro"
    }
  },
  "completeInviteProfile": {
    "title": "Complete su cuenta",
    "description": "Ha sido invitado a Telekom Data Intelligence Hub. Para completar su cuenta, por favor, rellene la información necesaria.",
    "firstName": "Nombre",
    "firstNamePlaceholder": "Introduzca su nombre",
    "lastName": "Apellidos",
    "lastNamePlaceholder": "Introduzca sus apellidos",
    "email": "Correo electrónico",
    "emailPlaceholder": "Introduzca su correo electrónico",
    "organisation": "Organización",
    "organisationPlaceholder": "Introduzca su organización",
    "enterPassword": "Introduzca su contraseña",
    "dataPrivacyCheck": "Apruebo que mis datos personales se almacenen y procesen de acuerdo con los fines indicados en la {dataAndPrivacyStatement}.* ",
    "termsAndConditionsCheck": "Confirmo que he leído y cumpliré los {termsandcondition} de Data Intelligence Hub.",
    "termsandcondition": "Términos y condiciones",
    "dataAndPrivacyStatement": "Declaración de privacidad de datos",
    "cancel": "Cancelar",
    "submit": "Enviar",
    "success": {
      "title": "Su cuenta ha sido completada",
      "description": "Se le redirigirá a la página de inicio de sesión",
      "actionContinue": "Continuar"
    }
  },
  "workbench": {
    "incomingDataAgreements": {
      "title": "Acuerdos sobre datos entrantes",
      "addedOn": "Añadido el",
      "expiresOn": "Expira el",
      "providedBy": "Proporcionado por",
      "dateAddedOn": "Fecha de incorporación",
      "noFilesAttached": "No hay archivos adjuntos actualmente a este acuerdo contractual",
      "noAgreementsMessage": "No tiene acuerdos de datos entrantes",
      "receivingData": "Recibiendo datos",
      "dataReceived": "Descargado",
      "error": "Error ocurrido",
      "transferInitiated": "Transferencia iniciada",
      "transferCompleted": "¡Transferencia completada!",
      "view": "Ver",
      "successDownload": "El activo de datos se recibió correctamente",
      "errorDownload": "Tu descarga de archivos para {filename} se abortó con un error, por favor inténtalo de nuevo",
      "viewAggreement": "Ver acuerdo",
      "agreeText": "Al descargar los archivos, aceptas cumplir con los {contractTerms} de este acuerdo",
      "contractTerms": "Términos del contrato",
      "includedFile": "Archivo incluido",
      "createdOn": "Creado el",
      "fileType": "Tipo de archivo",
      "downloadError": "Error al recuperar acuerdos de datos entrantes",
      "downloadSuccess": "El activo de datos se recibió correctamente"
    },
    "contractDetails": {
      "termsAndPolicies": "Términos y políticas",
      "offerBy": "Ofrecido por",
      "addedOn": "Agregado el",
      "expiresOn": "Caduca el",
      "product": "Producto",
      "totalFiles": "Total de archivos",
      "description": "Descripción",
      "contractID": "ID de contrato",
      "contractTerms": "Términos del contrato",
      "files": "Archivos",
      "parameters": "Parámetros",
      "referencePeriod": "Período de referencia",
      "location": "Ubicación",
      "additionalLinks": "Enlaces adicionales",
      "dihMainPage": "Página principal de DIH",
      "download": "Descargar",
      "search": "Buscar",
      "title": "Título",
      "createdOn": "Creado el",
      "kpiName": "Nombre del KPI",
      "fileType": "Tipo de archivo",
      "schema": "Esquema",
      "dashboard": "Tablero",
      "selectAll": "Seleccionar todo",
      "deselectAll": "Deseleccionar todo",
      "transfer": "Transferencia",
      "downloadSelected": "Descargar seleccionado",
      "noTermsPolicy": "En este momento, el Proveedor de Datos no ha especificado ninguna regla para el uso de datos dentro de este contrato.",
      "youCan": "Puedes",
      "youCant": "No puedes",
      "youMust": "Debes",
      "respectContent": "Respetar el contenido del Acuerdo de Licencia",
      "licenceAgreement": "Acuerdo de Licencia",
      "noAttachment": "Aún no hay adjuntos",
      "termsandcondition": "Términos y condiciones",
      "resellData": "Revender los datos",
      "youCanUseDataCommercial": "Usar los datos con fines comerciales",
      "youCanProcessData": "Procesar los datos en infraestructura fuera de la Unión Europea",
      "youCanEmpty": "En este momento, el Proveedor de Datos no ha especificado ningún permiso para el uso de datos dentro de este contrato",
      "youCantEmpty": "En este momento, el Proveedor de Datos no ha especificado ninguna prohibición para el uso de datos dentro de este contrato",
      "youMustEmpty": "En este momento, el Proveedor de Datos no ha especificado ninguna obligación para el uso de datos dentro de este contrato"
    }
  },
  "messages": {
    "noDataAvailable": "No hay datos disponibles",
    "noMatchFound": "No se ha encontrado ninguna coincidencia",
    "maximumFileSize": "Tamaño máximo de archivo",
    "suitableFileFormats": "Formatos de archivo válidos",
    "loading": "Cargando...",
    "dragDropText": "o arrástralo aquí"
  },
  "pageNotFound": {
    "title": "¡Oops! Parece que la página que estás buscando aún no existe.",
    "subtitle": "¡Mientras tanto, echa un vistazo a nuestros otros recursos, que podrían ser útiles para ti!"
  },
  "unauthorized": {
    "pageNotAccessible": "¡Vaya! Parece que no se puede acceder a la página que está buscando con sus permisos.",
    "reachOutToAdministrator": "Póngase en contacto con su administrador para obtener más información sobre su acceso.",
    "checkOtherResources": "Mientras tanto, consulte nuestros otros recursos."
  },
  "helpResources": {
    "needHelp": "¿Necesita ayuda para encontrar el plan adecuado, más información, preguntas técnicas?",
    "FAQ": "Preguntas frecuentes",
    "FAQDescription": "Encuentra respuestas comunes desde facturación hasta privacidad",
    "browse": "Navegar",
    "demos": "Demos",
    "demosDescription": "Encuentra casos de uso y demos relevantes para tu sector industrial",
    "glossary": "Glosario",
    "glossaryDescription": "Encuentra los términos y definiciones relacionados con Data Intelligence Hub, IDSA, Catena-X, Gaia-X y más."
  },
  "feedbackCard": {
    "giveUsFeedback": "¿Dinos qué nos falta?",
    "contactUs": "Contáctanos"
  },
  "requestDataspaceJoin": {
    "title": "Solicitar conexión a {dsName}",
    "basic": "Básico",
    "orgName": "Nombre de la organización:",
    "contact": "Contacto",
    "address": "Dirección:",
    "name": "Nombre:",
    "email": "Correo electrónico:",
    "joinReasonTitle": "Por favor, indica por qué quieres unirte al dataspacio?",
    "joinConsent": "Consiento compartir los detalles anteriores con el propietario del dataspacio",
    "sendRequest": "Enviar solicitud"
  },
  "applications": {
    "myApplication": "Mis Aplicaciones"
  },
  "externalConnection": {
    "title": "Administrador de Conexiones Externas",
    "noConnectionConfigureTitle": {
      "title": "Todavía no has configurado ninguna conexión",
      "newConnection": "Nueva Conexión"
    },
    "request": {
      "connectionDetails": "Detalles de la conexión",
      "preferConnection": "Selecciona el modo preferido de conexión al servidor",
      "selectConnection": "Seleccionar Conexión",
      "ageeTerms": "Por la presente, acepto y confirmo que toda mi información personal y datos indicados anteriormente son precisos",
      "grantConsent": "Doy mi consentimiento a Telekom Data Intelligence Hub para el procesamiento y almacenamiento de estos datos para facilitar transferencias de activos a través de la conexión proporcionada",
      "back": "Atrás",
      "addConnection": "Agregar Conexión",
      "save": "Guardar",
      "verifyConnectivity": "Verificar Conectividad",
      "checkingConnection": "Verificando conexión",
      "connectionVerified": "Conexión verificada",
      "error": "Error",
      "requestSuccessTitle": "¡Agregado exitosamente!",
      "requestSuccessText": "Has agregado exitosamente los detalles de tu conexión.",
      "requestUpdatedTitle": "¡Actualizado exitosamente!",
      "requestUpdatedText": "Has actualizado exitosamente los detalles de tu conexión.",
      "maxConnectionReached": "Has alcanzado el número máximo de conexiones gratuitas."
    },
    "delete": {
      "title": "Eliminación de conexión",
      "description": "¿Estás seguro de que quieres eliminar esta conexión?",
      "cancel": "Cancelar",
      "delete": "Eliminar",
      "requestDeleteSuccessTitle": "¡Eliminado con éxito!",
      "requestDeleteSuccessText": "Has eliminado exitosamente tu conexión."
    },
    "transfer": {
      "title": "Administrador de conexiones",
      "connectionDetails": "Detalles de la conexión",
      "type": "Tipo",
      "hostName": "Nombre de host",
      "rootPath": "Ruta raíz",
      "authType": "Tipo de autenticación",
      "username": "Nombre de usuario",
      "verificationStatus": "Estado de verificación",
      "disclaimer": "Renuncia",
      "disclaimerText": "Al confirmar su selección, reconoce que la conexión elegida está fuera del control de Telekom Data Intelligence Hub. En consecuencia, usted asume la responsabilidad de cualquier problema en la transferencia de datos que surja de factores externos, incluidos, entre otros, almacenamiento insuficiente, conectividad de red, restricciones de acceso y autenticación, y otras consideraciones relevantes.",
      "confirmSelection": "Confirmar selección",
      "newConnection": "Nueva conexión",
      "noConnectionModalTitle": "Todavía no tienes conexiones",
      "noConnectionModalDesc": "Puede crear una nueva conexión haciendo clic en el botón de abajo.",
      "noConnectionAddNewButton": "Agregar nueva conexión",
      "successModalTitle": "La transferencia tardará más de lo habitual",
      "successModalDesc": "Enviaremos una notificación una vez que se complete la transferencia de archivos.",
      "successModalAction": "Ir a Entrada de datos"
    }
  },
  "error": {
    "pageNotFound": "Página no encontrada (404)",
    "unauthorized": "Acceso no autorizado (403)"
  },
  "helpWidget": {
    "askSupport": "Solicitar asistencia",
    "chatboxTitle": "Chat de ayuda",
    "chatboxSubtitle": "Soporte de Telekom Data Intelligence Hub",
    "defaultMessage": {
      "body": "Hola, bienvenido al canal de soporte de Telekom Data Intelligence Hub. Por favor, selecciona una opción a continuación."
    },
    "whatCanWeHelpWith": "¿En qué podemos ayudarte hoy?",
    "suggestedAnswers": {
      "knowledgeBase": "Base de conocimientos, documentación y preguntas frecuentes",
      "contactSupport": "Contactar soporte",
      "contactSales": "Contactar ventas"
    }
  },
  "partnerNetworkManagement": {
    "title": "Gestión de la red de socios",
    "hint": "Esta página permitirá a los usuarios invitar y supervisar el estado de la invitación de sus organizaciones asociadas a Telekom Data Intelligence Hub",
    "noInvitations": {
      "title": "Aún no ha invitado a ninguna organización",
      "actionButtonText": "Invitar a una organización"
    },
    "overview": {
      "title": "Resumen de invitaciones de socios",
      "actionButtonText": "Invitar organizaciones",
      "dateOfInvitation": "Fecha de la invitación",
      "resendInvitation": "Reenviar invitación",
      "deleteInvitation": "Eliminar invitación",
      "resendReminderInfo": "Puedes reenviar la invitación en cuanto hayan pasado 24 horas desde el último correo electrónico de invitación.",
      "invitedBy": "Invitado por"
    },
    "information": {
      "title": "Información importante",
      "description": "La persona de contacto asumirá el rol de \"Administrador\" dentro del Telekom Data Intelligence Hub. Como administrador, tendrá autoridad para añadir o eliminar usuarios de su propia organización, supervisar roles y tomar decisiones comerciales sobre los servicios que ofrecemos. Transmitiremos los siguientes pasos para la incorporación de la organización a la persona de contacto por correo electrónico, y podrá monitorear el progreso en la página \"Resumen de invitaciones de socios\"."
    },
    "invite": {
      "additionalText": "Por favor, considere si desea invitar a organizaciones una por una, o en bloque, y haga su selección a continuación.",
      "bulkInvite": "Invitaciones en bloque",
      "bulkInviteHint": "Si desea realizar una invitación en bloque (>5 invitaciones), por favor selecciona esta opción.",
      "limitationHint": "Límite: Máximo 5 organizaciones. Para agregar más, selecciona \"Invitaciones en bloque\".",
      "singleInvite": "Invitación individual",
      "singleInviteHint": "Para invitar a 5 organizaciones o menos, por favor selecciona esta opción.",
      "orgNameLabel": "Ingrese nombre de la organización asociada",
      "emailLabel": "Ingrese correo del contacto"
    },
    "inviteStatus": {
      "INVITED": "Invitado",
      "COMPLETED": "Aceptado",
      "FAILED": "Rechazado"
    },
    "upload": {
      "hint": "Para invitar a múltiples organizaciones, siga los siguientes pasos:",
      "step1": "Descargue el archivo de plantilla desde el enlace de abajo",
      "step2": "Rellene el archivo con los datos del invitado(s).",
      "step3": "Suba el archivo en el área proporcionada",
      "downloadTemplate": "Descargar plantilla",
      "templateHeader": {
        "organizationName": "nombre_de_la_organizacion",
        "email": "correo_electronico"
      },
      "templateFileName": "plantilla_de_invitaciones_masivas.csv",
      "learnMore": "Si desea más información sobre la invitación en bloque, por favor haz {clickHere}.",
      "clickHere": "clic aquí"
    },
    "report": {
      "invitationsInProgress": "Invitaciones de organización en curso",
      "invitationsSent": "Invitaciones enviadas",
      "error": {
        "errorsInInvitations": "Errores en {result} invitaciones enviadas",
        "single": {
          "invalidEmail": "La dirección de correo electrónico no es válida",
          "pleaseEnterValidEmail": "Por favor, introduzca una dirección de correo electrónico válida",
          "pleaseEnterValidOrganization": "Por favor, introduzca un nombre de organización válido",
          "emailUnique": "El correo electrónico debe ser único",
          "pleaseEnterValidName": "Por favor, ingrese un nombre válido"
        },
        "bulk": {
          "allowedFileExtension": "Solo se permiten archivos con la extensión csv",
          "fileIsTooBig": "El archivo es demasiado grande. Puede subir archivos de hasta 1 MB",
          "formatMismatch": "El archivo no coincide con el formato esperado. Utilice la plantilla proporcionada e inténtelo de nuevo",
          "duplicateEmail": "El archivo tiene {number} {duplicate}",
          "duplicate": "duplicado | duplicados",
          "fileHasNoEntries": "El archivo no tiene entradas",
          "invalidEmailAddresses": "{number} direcciones de correo electrónico no son válidas.",
          "invalidOrganizationNames": "{number} organizaciones tienen nombres inválidos.",
          "organizationAlreadyInvited": "{number} organizaciones ya han sido invitadas por su organización.",
          "kindlyDownloadReport": "Por favor, descargue el informe para ver más detalles"
        },
        "processing": {
          "DUPLICATE_EMAILS": "Correos electrónicos duplicados detectados.",
          "EMAIL_ALREADY_EXIST": "El correo electrónico ya existe.",
          "SOMETHING_WENT_WRONG": "Algo salió mal.",
          "INVALID_EMAIL_FORMAT": "Formato de correo electrónico inválido."
        }
      },
      "template": {
        "fileName": "informe_de_invitaciones_masivas.csv"
      }
    },
    "abort": {
      "title": "Cancelar invitación",
      "confirmText": "¿Está seguro de que desea cerrar este proceso?",
      "informationText": "Sus invitaciones no serán enviadas."
    },
    "switchForm": {
      "title": "Descartar cambios",
      "confirmText": "¿Está seguro de que desea cambiar de formulario?",
      "informationText": "Sus cambios no se conservarán."
    }
  },
  "discover": {
    "welcome": {
      "title": "Descubra las ofertas de datos de nuestros socios y proveedores de confianza",
      "description1": "Toma decisiones basadas en datos.",
      "description2": "Los datos desempeñan un papel fundamental en las operaciones comerciales, ya que cumplen varias funciones esenciales, como la columna vertebral para la toma de decisiones, la segmentación del mercado y la generación de clientes potenciales. Los datos pueden hacer o deshacer el desarrollo de sus productos, optimizar la logística de su cadena de suministro o alimentar a su IA con la información que necesita para sacar las conclusiones que necesita para avanzar en sus objetivos.",
      "description3": "En el Data Intelligence Hub de Telekom ofrecemos una plataforma unificada para los proveedores de datos de toda Alemania. Explora tus opciones y sumérgete en ellas: es posible que encuentres una excelente opción para tus necesidades de datos. Ampliamos constantemente nuestra red de proveedores de datos, por lo que incluso si sus necesidades exactas no están cubiertas por nuestra oferta de datos actual, no sea un extraño: su ajuste perfecto de datos puede estar a días de distancia.",
      "buttonLabel": "Explorar productos de datos"
    },
    "dataOffers": {
      "title": "Ofertas de Datos",
      "filter": {
        "subjectAreas": "Áreas temáticas",
        "dataOfferingType": "Tipo de oferta de datos",
        "dataProvider": "Proveedor de datos"
      },
      "tiles": {
        "productType": "Producto de datos configurable",
        "dataspaceName": "Espacio de Datos del Data Intelligence Hub",
        "subjectArea": "Área temática",
        "massmovementAnalytics": "Análisis de movimiento masivo",
        "termsOfUse": "Términos de uso",
        "licensedRestricted": "Licenciado, restringido",
        "viewOffer": "Ver oferta",
        "product1": {
            "name": "Traffic Analytics por Motion Data",
            "summary": "Estadísticas detalladas del comportamiento de movimiento en Alemania, incluyendo dirección del movimiento, origen, destino, permitiendo análisis en diferentes niveles espaciales en cualquier región."
        },
        "product2": {
            "name": "Traffic Analytics PRO por Motion Data",
            "summary": "Mediciones detalladas del movimiento en Alemania, incluyendo dirección del movimiento, origen, destino, permitiendo análisis y comparaciones en y entre diferentes niveles espaciales en cualquier momento y en cualquier región."
        },
        "product3": {
            "name": "Visitor Analytics PRO por Motion Data",
            "summary": "Analiza información sobre el comportamiento de las visitas debido a los patrones de movimiento público, incluyendo el conteo total de usuarios a diario y por horas, conocimientos sociodemográficos y detalles de origen de los visitantes en cualquier región de Alemania. Siguiente nivel de opciones de análisis para el comportamiento de visitas en el sitio, combinando las ventajas de uno de los tamaños de muestra más grandes y una precisión espacial sin precedentes."
        }
      }
    }
  },
  "osp-onboarding": {
    "help": "help",
    "welcome": {
      "title": "Welcome to Catena-X - The Automotive Network",
      "card": {
        "note": "Please finish the following application form to complete the registration of your company for Catena-X",
        "point-1": "The registration form consists of 3 steps and will take approximately 5-10 minutes of your time",
        "point-2": "Since your organization is already registered on Telekom Data Intelligence Hub, we will prefill the form with your available data to make the completion process as smooth and easy as possible.",
        "point-3": "You can view our help guide at our knowledge base anytime to better understand this registration process. Please click {hereLink} to learn more.",
        "here": "here",
        "letStarted": "Let's get started"
      }
    },
    "title": "Registration to Catena-X ecosystem",
    "description": "Register to Catena-X by entering all information requested in the following 3 steps.",
    "description2": "Please finish the company onboarding by finishing the following tasks to actively participate and use all functions in the Catena-X Automotive Network Portal.",
    "note": "Please enter all the required information before proceeding. More information in our {help} section.",
    "companyDataStepTitle": "Company data",
    "companyRoleStepTitle": "Company role",
    "verifyAndSubmitStepTitle": "Verify & Submit",
    "companyData": {
      "title": "Add your company data",
      "description": "Please add your legal entity company data below. Mandatory fields are highlighted with (*) and form is pre-filled with information you have provided with us already during onboarding.",
      "organizationName": "Organization name",
      "organizationNameTooltip": "Registered organization name",
      "enterYourData": "Please enter your data",
      "bpn": "Business Partner Number",
      "bpnTooltip": "The Business Partner Number (BPN) is a unique identifier that is assigned to each business partner under Catena-X ecosystem.",
      "legalEntityName": "Legal entity name",
      "registeredName": "Registered name",
      "orgAddress": "Organization address",
      "streetName": "Street name",
      "houseNumber": "House number",
      "postCode": "Post Code",
      "city": "City",
      "countryCode": "Country code",
      "region": "Region",
      "countryIdentifier": "Country Identifier",
      "identifierNumber": "Identifier number",
      "contactInfo": "Contact Information",
      "emailAddress": "Email address",
      "userRole": "User role",
      "CRN": {
        "label": "Commercial register number",
        "helpText": "Example: HRB 55933",
        "tooltipText": "The Commercial Register Number (CRN) is a unique identifier assigned to companies for their legal commercial registration. It is used to validate and verify the authenticity of a company's registration and financial transaction identifiers against trusted data sources."
      },
      "vatId": {
        "label": "VAT number",
        "helpText": "Example: DE 118 645 675",
        "tooltipText": "A VAT number (Value-Added Tax Identification number) is a unique identifier assigned to businesses for tax purposes."
      }
    },
    "companyRole": {
      "title": "The role of your company",
      "description": "Please select one or several company roles with which you want to act/participate in the Catena-X network. The company role can always get updated/changed later. Depending on the selected role, the offered portal services might differ.",
      "activeParticipanyNote": "Tailor and optimize your Catena-X participation with T-Systems Connect & Integrate for easy and automated connection to Catena-X. Order the Connect & Integrate from the Cofinity App marketplace for the leading Catena-X use-cases and start exchanging data immediately! Starts from 49EUR/month and available for all industry sizes.",
      "agreeTermsCheckbox": "Yes, I agree to the {link} of the selected role",
      "activeParticipantNote": {
        "para1": "Tailor and optimize your Catena-X participation with {link} for easy and automated connection to Catena-X. ",
        "tSystemsConnectAndIntegrate": "T-Systems Connect & Integrate",
        "para2": "Order the Connect & Integrate from the Cofinity App marketplace for the leading Catena-X use-cases and start exchanging data immediately!",
        "para3": "Starts from {price}/month and available for all industry sizes."
      }
    },
    "verifyAndSubmit": {
      "title": "Verify your registration data",
      "description": "Verify your company registration data before submitting the registration. Please ensure that all information are correctly filled. As soon as the verification is finished, click the Submit button below to submit the registration for approval.",
      "companyData": "Company Data",
      "activeRole": "Active role",
      "consentText": "I consent to T-Systems International GmbH submitting my registration request to Cofinity-X GmbH and processing the relevant personal data for the purpose of completing the onboarding procedure and related purposes. I acknowledge that my data will be processed in accordance with applicable data protection laws and regulations, including but not limited to the General Data Protection Regulation (GDPR). I understand that my personal data will only be stored for as long as necessary to fulfill these purposes. I am aware that this consent is required for registration and onboarding with Cofinity-X GmbH, and that I can withdraw my consent at any time with effect for the future."
    },
    "actions": {
      "back": "Back",
      "next": "Next",
      "submit": "Submit"
    },
    "validation": {
      "DE_COMMERCIAL_REG_NUMBER": "Please enter a valid number. Hint: Alphanumeric with hyphen and space with exact 9 digits.",
      "DE_VAT_ID": "Please enter a valid number. Hint: Starting with DE and followed by 9 digit numbers",
      "Worldwide_COMMERCIAL_REG_NUMBER": "Please enter a valid number. Hint: Alphanumeric with hyphen with 6 to 21 digits.",
      "Worldwide_VAT_ID": "Please enter a valid number. Hint: Alphanumeric with hyphen and space with 8 to 15 digits.",
      "invalid_bpn": "Invalid BPN number. The BPN number must start with BPNL followed by 12 alphanumeric characters."
    },
    "error": {
      "title": "Validation error",
      "para": "Either your organization already exists within the Cofinity-X network, or there are technical issues that are currently being addressed. Please let us know more about your experience so that we can help you further.",
      "buttonText": "Contact us"
    },
    "success": {
      "title": "Thank you for submitting your request",
      "para": "Your details have been sent to Cofinity-X, you will receive information about the next steps via email",
      "buttonText": "Go to homepage"
    }
  }
}