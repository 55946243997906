import type { GetterTree } from 'vuex';
import {
  GET_ORG_PROFILE_INFO,
  GET_ORG_IS_PROGRESS,
  GET_ORG_REQUEST_CHANGE_INPROGRESS,
} from './getter-types';
import type {
  IOrgProfileState,
} from './index';

export const getters: GetterTree<IOrgProfileState, {}> = {
  [GET_ORG_PROFILE_INFO]: state => state.orgProfileInfo,
  [GET_ORG_IS_PROGRESS]: state => state.isProgress,
  [GET_ORG_REQUEST_CHANGE_INPROGRESS]: state => state.requestChangeInProgress,
};
