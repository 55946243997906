export const GET_VC_FILTER_PARAMS = 'GET_VC_FILTER_PARAMS';
export const GET_VC_DETAILS = 'GET_VC_DETAILS';
export const GET_FETCH_VC_DETAILS_IN_PROGRESS = 'GET_FETCH_VC_DETAILS_IN_PROGRESS';
export const GET_VC_REQUEST_IN_PROGRESS = 'GET_VC_REQUEST_IN_PROGRESS';
export const GET_APPEND_VCS = 'GET_APPEND_VCS';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_REGIONS = 'GET_REGIONS';
export const GET_BULK_ISSUING_STATUSES = 'GET_BULK_ISSUING_STATUSES';
export const GET_REPORT_LIST_FILTER_PARAMS = 'GET_REPORT_LIST_FILTER_PARAMS';
export const GET_FETCH_REPORT_LIST_IN_PROGRESS = 'GET_FETCH_REPORT_LIST_IN_PROGRESS';
export const GET_RL_DETAILS = 'GET_RL_DETAILS';
export const GET_REPORT_LISTS_DETAILS = 'GET_REPORT_LISTS_DETAILS';
export const GET_API_USAGE_LIMIT_DATA = 'GET_API_USAGE_LIMIT_DATA';
export const GET_FETCH_API_USAGE_IN_PROGRESS = 'GET_FETCH_API_USAGE_IN_PROGRESS';
export const GET_CERTIFICATE_LIST = 'GET_CERTIFICATE_LIST';
export const GET_FETCH_CERTIFICATE_LIST_IN_PROGRESS = 'GET_FETCH_CERTIFICATE_LIST_IN_PROGRESS';
